import CustomInput from 'components/customInput/CustomInput'
import styles from './RenameFolderModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useState } from 'react'
import { messages } from 'constants/messages'
import { useDispatch, useSelector } from 'react-redux'
import { imagesActions, imagesSelector } from 'redux/images/slice'
import { modalSelector } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { api } from 'redux/rtkQuery'
import { IGetImagesResponse } from 'types/api'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'

const { wrapper, title } = styles

interface IProps {
  onClose?: () => void
}

const RenameFolderModalContent = ({ onClose }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const imagesStored = useSelector(imagesSelector)
  const [updateImage, { isLoading: isUpdateImageLoading }] = api.useUpdateImageMutation()
  const [folderName, setFolderName] = useState(modalContext.category)

  const handleSetFolderName = (value: string) => {
    setFolderName(value)
  }

  const handleRenameFolder = async () => {
    dispatch(imagesActions.renameFolder({ newFolderName: folderName, oldFolderName: modalContext.category }))

    const imagesOfCategory = imagesStored.images.filter(
      (image: IGetImagesResponse) => image.category === modalContext.category
    )

    if (imagesOfCategory.length > 0) {
      try {
        const promises = imagesOfCategory.map((image: IGetImagesResponse) => {
          return updateImage({
            botId: bot.id,
            imageId: image.id,
            body: {
              category: folderName,
            },
          })
        })

        await Promise.all(promises)
      } catch (error) {
        console.error('Error updating images:', error)
      }
    }

    if (onClose) onClose()
    toastFuncNotification(images.renameFolderSuccess)
  }

  return (
    <div className={wrapper}>
      <p className={title}>{images.renameFolderModalTitle}</p>
      <div>
        <CustomInput placeholder="Folder Name" value={folderName} onChangeValue={handleSetFolderName} />
      </div>
      <ActionButton
        label={images.buttons.renameFolder}
        loading={isUpdateImageLoading}
        buttonType={ActionButtonType.PRIMARY}
        size={ActionButtonSize.MEDIUM}
        onPress={handleRenameFolder}
      />
      <ActionButton
        label={images.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default RenameFolderModalContent
