import { PROGRESS_STATUS } from 'types/api'

export interface ICustomFile extends File {
  preview: string
  progressStatus?: PROGRESS_STATUS
  id?: string
}

export const generateURL = (files: File[]): ICustomFile[] => {
  const result = files.map((file: File) =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
    })
  )

  return result
}
