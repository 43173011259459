// TODO: Add logger implementation for production
// NOTE: I propose to use winston as production logger

const isDev = process.env.NODE_ENV === 'development';

export const logger = {
  log: (fn: string, error: any, ...optionalParams: any[]) => {
    if (isDev) {
      console.log(fn, error, optionalParams);
      return;
    }
  },
  error: (fn: string, error: any, ...optionalParams: any[]) => {
    if (isDev) {
      console.error(fn, error, optionalParams);
      return;
    }
  },
  warn: (fn: string, error: any, ...optionalParams: any[]) => {
    if (isDev) {
      console.warn(fn, error, optionalParams);
      return;
    }
  },
};
