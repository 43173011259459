import { ChangeEvent, useEffect, useState } from 'react'
import styles from './Files.module.scss'
import IconList from 'assets/icons/iconList'
import IconFolders from 'assets/icons/iconFolders'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconEditFolder from 'assets/icons/iconEditFolder'
import DataTable from './dataTable/DataTable'

import { api } from 'redux/rtkQuery'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import CustomBreadcrumbs from 'components/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { FolderView } from './FoldersView'
import DropzoneBox from 'components/dropzoneBox/DropzoneBox'
import { Box } from '@mui/material'
import IconAdd from 'assets/icons/iconAdd'

export type Breadcrumbs = { folderName: string; path: string }[]

const { imagesContainer, controlPanel, controlPanelTitle, selectionGroup, option, groupWrapper, noNameButton } = styles

const enum TABS {
  LIST = 'List',
  FOLDERS = 'Folders',
}

const Files = () => {
  const { files } = messages
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState<TABS>(TABS.LIST)
  const { breadcrumbs, pathOfBreadcrumbs, handleSetBreadcrumbs } = useBreadcrumbs()
  const [page, setPage] = useState(1)
  const bot = useSelector(botSelector)

  const { data, isFetching } = api.useGetFilesQuery(
    {
      botId: bot.id,
      page,
      folderPath: pathOfBreadcrumbs,
    },
    { skip: !bot.id, refetchOnMountOrArgChange: true }
  )

  const handleChangePage = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleSetIsUploadFileModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.UPLOAD_FILE,
        path: pathOfBreadcrumbs,
      })
    )
  }

  const handleSetIsNewFileFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.NEW_FILE_FOLDER,
        path: pathOfBreadcrumbs,
      })
    )
  }

  const handleSetSelectedOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as TABS)
  }

  useEffect(() => {
    if (data && !data.files?.length && !data.folders?.length && page > 1) {
      setPage(page - 1)
    }
  }, [data, page])

  return (
    <div className={imagesContainer}>
      <div className={controlPanel}>
        <div className={groupWrapper}>
          <h3 className={controlPanelTitle}>
            {files.allFiles} ({data?.totalFiles})
          </h3>

          <CustomBreadcrumbs options={breadcrumbs} handleSetBreadcrumbs={handleSetBreadcrumbs} />
        </div>
        <div className={groupWrapper} style={{ gap: '8px' }}>
          <div className={selectionGroup}>
            <label className={option}>
              <input
                type="radio"
                name="selection"
                value="List"
                checked={selectedOption === TABS.LIST}
                onChange={handleSetSelectedOption}
              />
              <span>
                <IconList active={selectedOption === TABS.LIST} />
                {files.list}
              </span>
            </label>
            <label className={option}>
              <input
                type="radio"
                name="selection"
                value="Folders"
                checked={selectedOption === TABS.FOLDERS}
                onChange={handleSetSelectedOption}
              />
              <span>
                <IconFolders active={selectedOption === TABS.FOLDERS} />
                {files.folders}
              </span>
            </label>
          </div>
          <button className={noNameButton} onClick={handleSetIsNewFileFolderModalOpen}>
            <IconEditFolder />
          </button>
          <ActionButton
            label={files.buttons.uploadFiles}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            onPress={handleSetIsUploadFileModalOpen}
            icon={<IconAdd fillColor="#ffffff" />}
          />
        </div>
      </div>

      <Box
        sx={{
          height: '100%',
        }}
      >
        <DropzoneBox currentPath={pathOfBreadcrumbs}>
          {selectedOption === TABS.LIST ? (
            <DataTable
              data={data}
              onChangePage={handleChangePage}
              setBreadcrumbs={handleSetBreadcrumbs}
              breadcrumbs={breadcrumbs}
              pathOfBreadcrumbs={pathOfBreadcrumbs}
              handleSetIsUploadFileModalOpen={handleSetIsUploadFileModalOpen}
              isFetching={isFetching}
            />
          ) : (
            <FolderView
              data={data}
              onChangePage={handleChangePage}
              setBreadcrumbs={handleSetBreadcrumbs}
              breadcrumbs={breadcrumbs}
              pathOfBreadcrumbs={pathOfBreadcrumbs}
              handleSetIsUploadFileModalOpen={handleSetIsUploadFileModalOpen}
              isFetching={isFetching}
            />
          )}
        </DropzoneBox>
      </Box>
    </div>
  )
}

export default Files
