import { FC } from 'react'
import styles from './iconLoader.module.scss'

interface iIconLoader {
  fillColor?: string
}

const IconLoader: FC<iIconLoader> = ({ fillColor = 'white' }) => {
  const { loaderContainer } = styles
  return (
    <div className={loaderContainer}>
      <svg
        style={{ margin: 0 }}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.99988 18C7.53148 17.9987 6.08562 17.6382 4.78853 16.9498C3.49144 16.2614 2.38249 15.2661 1.55841 14.0507C0.734338 12.8353 0.220168 11.4368 0.0607584 9.977C-0.0986516 8.5172 0.101548 7.0407 0.643878 5.67604C1.18622 4.31142 2.05422 3.10024 3.17217 2.14813C4.29012 1.19603 5.62405 0.53194 7.05758 0.21378C8.49118 -0.10437 9.98078 -0.06693 11.3966 0.32286C12.8123 0.71264 14.1112 1.44292 15.1799 2.45C15.3204 2.59062 15.3992 2.78125 15.3992 2.98C15.3992 3.17875 15.3204 3.36937 15.1799 3.51C15.111 3.58075 15.0285 3.63699 14.9375 3.67539C14.8465 3.71378 14.7487 3.73357 14.6499 3.73357C14.5511 3.73357 14.4533 3.71378 14.3623 3.67539C14.2713 3.63699 14.1889 3.58075 14.1199 3.51C12.859 2.33065 11.2302 1.62177 9.50788 1.5027C7.78548 1.38362 6.07466 1.86163 4.66345 2.85624C3.25225 3.85085 2.22683 5.30132 1.75983 6.96345C1.29284 8.6256 1.4128 10.3979 2.0995 11.9819C2.78621 13.5659 3.99773 14.865 5.53009 15.6604C7.06248 16.4558 8.82208 16.6989 10.5127 16.3488C12.2033 15.9987 13.7217 15.0768 14.8122 13.7383C15.9027 12.3998 16.4987 10.7265 16.4999 9C16.4999 8.8011 16.5789 8.6103 16.7196 8.4697C16.8602 8.329 17.051 8.25 17.2499 8.25C17.4488 8.25 17.6396 8.329 17.7802 8.4697C17.9209 8.6103 17.9999 8.8011 17.9999 9C17.9999 11.3869 17.0517 13.6761 15.3639 15.364C13.676 17.0518 11.3869 18 8.99988 18Z"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}

export default IconLoader
