import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal, { MODAL_SIZE } from './Modal'
import CancelUploadModalContent from './CancelUploadModalContent/CancelUploadModalContent'
import DeleteFileModalContent from './DeleteFileModalContent/DeleteFileModalContent'
import UploadImageModalContent from './UploadImageModalContent/UploadImageModalContent'
import NewFolderModalContent from './NewFolderModalContent/NewFolderModalContent'
import EditImageModalContent from './EditImageModalContent/EditImageModalContent'
import ImagePreviewModalContent from './ImagePreviewModalContent/ImagePreviewModal'
import RenameFolderModalContent from './RenameFolderModalContent/RenameFolderModalContent'
import DeleteFolderModalContent from './DeleteFolderModalContent/DeleteFolderModalContent'
import UploadFileModalContent from './UploadFileModalContent/UploadFileModalContent'
import { MODAL_TYPES, modalActions, modalSelector } from 'redux/modal/slice'
import NewFileFolderModalContent from './NewFileFolderModalContent/NewFileFolderModalContent'
import RenameFileFolderModalContent from './RenameFileFolderModalContent/RenameFileFolderModalContent'
import { ICustomFile } from 'utils/generateURL'
import { toastFuncInfo } from 'components/customToastContainer/CustomToastContainer'
import { PROGRESS_STATUS } from 'types/api'
import { messages } from 'constants/messages'

const ModalManager = () => {
  const { files: filesText } = messages

  const dispatch = useDispatch()
  const modal = useSelector(modalSelector)
  const [files, setFiles] = useState<ICustomFile[]>([])

  const handleSetIsUploadImageModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.UPLOAD_IMAGE,
      })
    )
  }

  const handleSetIsUploadFileModalOpen = () => {
    const isUploading = files.some((file) => file.progressStatus === PROGRESS_STATUS.UPLOADING)

    if (isUploading) {
      toastFuncInfo(filesText.closeModalError)
      return
    }

    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.UPLOAD_FILE,
      })
    )
  }

  const handleSetIsCancelUploadModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.CANCEL_UPLOAD,
      })
    )
  }

  const handleSetIsDeleteFileModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FILE,
      })
    )
  }

  const handleSetIsNewFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.NEW_FOLDER,
      })
    )
  }

  const handleSetIsNewFileFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.NEW_FILE_FOLDER,
      })
    )
  }

  const handleSetIsRenameFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.RENAME_FOLDER,
      })
    )
  }

  const handleSetIsRenameFileFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.RENAME_FILE_FOLDER,
      })
    )
  }

  const handleSetIsDeleteFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FOLDER,
      })
    )
  }

  const handleImagePreviewModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.IMAGE_PREVIEW,
      })
    )
  }

  const handleSetIsEditImageModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.EDIT_IMAGE,
      })
    )
  }

  return (
    <>
      <Modal open={modal.UPLOAD_IMAGE} onClose={handleSetIsUploadImageModalOpen}>
        <UploadImageModalContent />
      </Modal>

      <Modal open={modal.UPLOAD_FILE} onClose={handleSetIsUploadFileModalOpen}>
        <UploadFileModalContent setFiles={setFiles} />
      </Modal>

      <Modal open={modal.EDIT_IMAGE} onClose={handleSetIsEditImageModalOpen}>
        <EditImageModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.CANCEL_UPLOAD} onClose={handleSetIsCancelUploadModalOpen}>
        <CancelUploadModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.DELETE_FILE} onClose={handleSetIsDeleteFileModalOpen}>
        <DeleteFileModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.NEW_FOLDER} onClose={handleSetIsNewFolderModalOpen}>
        <NewFolderModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.NEW_FILE_FOLDER} onClose={handleSetIsNewFileFolderModalOpen}>
        <NewFileFolderModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.RENAME_FOLDER} onClose={handleSetIsRenameFolderModalOpen}>
        <RenameFolderModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.RENAME_FILE_FOLDER} onClose={handleSetIsRenameFileFolderModalOpen}>
        <RenameFileFolderModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.SMALL} open={modal.DELETE_FOLDER} onClose={handleSetIsDeleteFolderModalOpen}>
        <DeleteFolderModalContent />
      </Modal>

      <Modal size={MODAL_SIZE.LARGE} open={modal.IMAGE_PREVIEW} onClose={handleImagePreviewModalOpen}>
        <ImagePreviewModalContent />
      </Modal>
    </>
  )
}

export default ModalManager
