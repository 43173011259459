import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import dayjs from 'dayjs'

export const removeValuesFromArray = (valuesArray: string[], value: string): void => {
  const valueIndex = valuesArray.findIndex((entry) => entry === value)
  if (valueIndex === -1) {
    return
  }
  valuesArray.splice(valueIndex, 1)
}

export const deleteAllCookies = (): void => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}

export const generateTimestamp = (): number => {
  const timestamp = (Date.now() / 1000) | 0
  return timestamp
}

export const containsNumber = (value: string): boolean => {
  const numbersInPass = value.length - value.replace(/[0-9]/g, '').length
  return numbersInPass !== 0
}

export const containsSpecialChars = (value: string): boolean => {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
  return specialChars.test(value)
}

export const clearSession = (): void => {
  sessionStorage.clear()
  localStorage.clear()
}

export const getPlanPrice = (amount: number, isMonthly: boolean): string => {
  if (amount === 0) {
    return 'FREE'
  }

  return `$${((isMonthly ? amount : amount / 12) / 100).toFixed(0)}/m`
}

export const hexToRgba = (hex: string, alpha = '1'): string => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const hexValue = hex?.replace(shorthandRegex, (m: string, r: string, g: string, b: string) => r + r + g + g + b + b)
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexValue)
  if (result !== null) {
    const rgbValue = `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    return `rgba(${rgbValue},${alpha})`
  }
  return ''
}

export const copyToClipboard = (
  textToCopy: string,
  successMessage = 'Copied to clipboard',
  errorMessage = 'Failed to copy to clipboard'
): void => {
  navigator.clipboard
    .writeText(textToCopy)
    .then(() => {
      toastFuncSuccess(successMessage)
    })
    .catch(() => {
      toastFuncError(errorMessage)
    })
}

export const getTimeSinceLastMessage = (lastMessageTimeStamp: string): string => {
  const now = dayjs()
  const then = dayjs(lastMessageTimeStamp)

  const diffMinutes = now.diff(then, 'minutes')
  const diffHours = now.diff(then, 'hours')

  if (diffHours > 24) {
    return then.format('MM/DD/YY')
  }

  if (diffHours > 0) {
    return `${diffHours} h`
  }

  if (diffMinutes === 0) {
    return 'Now'
  }

  return `${diffMinutes} min`
}
