import { FC } from 'react'

const IconCopy: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_3704_4189"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3704_4189)">
        <path
          d="M9.05765 17.5C8.55252 17.5 8.12496 17.325 7.77498 16.975C7.42498 16.625 7.24998 16.1974 7.24998 15.6923V4.3077C7.24998 3.80257 7.42498 3.375 7.77498 3.025C8.12496 2.675 8.55252 2.5 9.05765 2.5H17.4422C17.9473 2.5 18.3749 2.675 18.7249 3.025C19.0749 3.375 19.2499 3.80257 19.2499 4.3077V15.6923C19.2499 16.1974 19.0749 16.625 18.7249 16.975C18.3749 17.325 17.9473 17.5 17.4422 17.5H9.05765ZM9.05765 16H17.4422C17.5191 16 17.5897 15.9679 17.6538 15.9038C17.7179 15.8397 17.7499 15.7692 17.7499 15.6923V4.3077C17.7499 4.23077 17.7179 4.16024 17.6538 4.09613C17.5897 4.03203 17.5191 3.99998 17.4422 3.99998H9.05765C8.98072 3.99998 8.9102 4.03203 8.8461 4.09613C8.78198 4.16024 8.74993 4.23077 8.74993 4.3077V15.6923C8.74993 15.7692 8.78198 15.8397 8.8461 15.9038C8.9102 15.9679 8.98072 16 9.05765 16ZM5.55768 20.9999C5.05256 20.9999 4.625 20.8249 4.275 20.4749C3.925 20.1249 3.75 19.6973 3.75 19.1922V7.05768C3.75 6.84486 3.82179 6.66666 3.96538 6.52308C4.10898 6.37949 4.28718 6.3077 4.49998 6.3077C4.71279 6.3077 4.891 6.37949 5.0346 6.52308C5.17818 6.66666 5.24998 6.84486 5.24998 7.05768V19.1922C5.24998 19.2692 5.28202 19.3397 5.34613 19.4038C5.41024 19.4679 5.48076 19.5 5.55768 19.5H14.6922C14.905 19.5 15.0832 19.5717 15.2268 19.7153C15.3704 19.8589 15.4422 20.0371 15.4422 20.2499C15.4422 20.4627 15.3704 20.6409 15.2268 20.7845C15.0832 20.9281 14.905 20.9999 14.6922 20.9999H5.55768Z"
          fill="#5E36FF"
        />
      </g>
    </svg>
  )
}

export default IconCopy
