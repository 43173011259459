interface IProps {
  fillColor?: string
}

const IconMore = ({ fillColor }: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_6088_7974" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_6088_7974)">
        <path
          d="M12 19.2693C11.5875 19.2693 11.2344 19.1224 10.9406 18.8287C10.6469 18.535 10.5 18.1818 10.5 17.7694C10.5 17.3569 10.6469 17.0037 10.9406 16.71C11.2344 16.4163 11.5875 16.2694 12 16.2694C12.4125 16.2694 12.7656 16.4163 13.0593 16.71C13.3531 17.0037 13.5 17.3569 13.5 17.7694C13.5 18.1818 13.3531 18.535 13.0593 18.8287C12.7656 19.1224 12.4125 19.2693 12 19.2693ZM12 13.5001C11.5875 13.5001 11.2344 13.3532 10.9406 13.0595C10.6469 12.7657 10.5 12.4126 10.5 12.0001C10.5 11.5876 10.6469 11.2345 10.9406 10.9408C11.2344 10.647 11.5875 10.5002 12 10.5002C12.4125 10.5002 12.7656 10.647 13.0593 10.9408C13.3531 11.2345 13.5 11.5876 13.5 12.0001C13.5 12.4126 13.3531 12.7657 13.0593 13.0595C12.7656 13.3532 12.4125 13.5001 12 13.5001ZM12 7.73088C11.5875 7.73088 11.2344 7.58401 10.9406 7.29026C10.6469 6.99652 10.5 6.64341 10.5 6.23091C10.5 5.81842 10.6469 5.46531 10.9406 5.17156C11.2344 4.87782 11.5875 4.73096 12 4.73096C12.4125 4.73096 12.7656 4.87782 13.0593 5.17156C13.3531 5.46531 13.5 5.81842 13.5 6.23091C13.5 6.64341 13.3531 6.99652 13.0593 7.29026C12.7656 7.58401 12.4125 7.73088 12 7.73088Z"
          fill="#777777"
        />
      </g>
    </svg>
  )
}

export default IconMore
