const SmallFolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <mask id="mask0_195_3896" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_195_3896)">
        <path
          d="M5.74365 26C5.07009 26 4.49998 25.7667 4.03331 25.3C3.56665 24.8333 3.33331 24.2632 3.33331 23.5897V8.41033C3.33331 7.73678 3.56665 7.16667 4.03331 6.7C4.49998 6.23333 5.07009 6 5.74365 6H12.0666C12.388 6 12.697 6.06244 12.9936 6.18733C13.2901 6.312 13.5478 6.48378 13.7666 6.70267L15.7306 8.66667H26.2563C26.9299 8.66667 27.5 8.9 27.9666 9.36667C28.4333 9.83333 28.6666 10.4034 28.6666 11.077V23.5897C28.6666 24.2632 28.4333 24.8333 27.9666 25.3C27.5 25.7667 26.9299 26 26.2563 26H5.74365ZM5.74365 24H26.2563C26.3761 24 26.4744 23.9616 26.5513 23.8847C26.6282 23.8078 26.6666 23.7094 26.6666 23.5897V11.077C26.6666 10.9572 26.6282 10.8589 26.5513 10.782C26.4744 10.7051 26.3761 10.6667 26.2563 10.6667H14.9126L12.3616 8.11533C12.3188 8.07267 12.2739 8.04278 12.227 8.02567C12.1799 8.00856 12.1306 8 12.0793 8H5.74365C5.62387 8 5.52554 8.03845 5.44865 8.11533C5.37176 8.19222 5.33331 8.29056 5.33331 8.41033V23.5897C5.33331 23.7094 5.37176 23.8078 5.44865 23.8847C5.52554 23.9616 5.62387 24 5.74365 24Z"
          fill="#777777"
        />
      </g>
    </svg>
  )
}

export default SmallFolderIcon
