import { useState } from 'react'
import styles from './EditContentModal.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import CustomInput from 'components/customInput/CustomInput'
import { messages } from 'constants/messages'
import { KnowledgeBody, ParsedKnowledge } from 'types/api'
import DialogModal from 'components/dialogModal/DialogModal'

const { container, contentStyles, titleStyles, messageStyles, buttonsGroup, addContentInput, addContentTextarea } =
  styles

const { editContentModal } = messages.knowledgeMessages

type EditContentModalProps = {
  knowledge: ParsedKnowledge
  isVisible: boolean
  title: string
  message: string
  onContinue: (arg1: string, arg2: KnowledgeBody) => void
  onClose: () => void
}

const EditContentModal = ({
  knowledge,
  isVisible = true,
  title,
  message,
  onContinue,
  onClose,
}: EditContentModalProps) => {
  const [newContent, setNewContent] = useState({
    title: knowledge.title,
    description: knowledge.description,
    disabled: knowledge.disabled,
  })

  const [isEditingContentModalVisible, setIsEditingContentModalVisible] = useState(false)

  const handleTitleChange = (value: string) => {
    setNewContent({ ...newContent, title: value })
  }

  const handleDescriptionChange = (value: string) => {
    setNewContent({ ...newContent, description: value })
  }

  const handleEditContent = () => {
    onContinue(knowledge.id, newContent)
    onClose()
  }

  const handleClose = () => {
    setNewContent({
      title: knowledge.title,
      description: knowledge.description,
      disabled: knowledge.disabled,
    })
    onClose()
  }

  return isVisible ? (
    <>
      <div className={container}>
        <div className={contentStyles}>
          <div className={titleStyles}>{title}</div>
          <div className={messageStyles}>{message}</div>
          <div className={addContentInput}>
            <CustomInput
              placeholder={editContentModal.addTitle}
              value={newContent.title}
              onChangeValue={handleTitleChange}
            />
          </div>
          <div className={addContentTextarea}>
            <CustomInput
              placeholder={editContentModal.typeContent}
              value={newContent.description}
              onChangeValue={handleDescriptionChange}
              multiline
            />
          </div>

          <div className={buttonsGroup}>
            <ActionButton
              label={editContentModal.cancel}
              onPress={handleClose}
              buttonType={ActionButtonType.TERTIARY}
              size={ActionButtonSize.MEDIUM}
            />
            <ActionButton
              label={editContentModal.save}
              onPress={() => setIsEditingContentModalVisible(true)}
              buttonType={ActionButtonType.PRIMARY}
              size={ActionButtonSize.MEDIUM}
              disabled={newContent.description === '' || newContent.title === ''}
            />
          </div>
        </div>
      </div>
      <DialogModal
        isVisible={isEditingContentModalVisible}
        title={editContentModal.dialogModal.title}
        message={editContentModal.dialogModal.message}
        buttons={[
          {
            text: editContentModal.dialogModal.continueButton,
            onPress: () => handleEditContent(),
          },
          {
            text: editContentModal.dialogModal.cancelButton,
            style: 'cancel',
          },
        ]}
        onClose={() => setIsEditingContentModalVisible(false)}
      />
    </>
  ) : null
}

export default EditContentModal
