import { CSSProperties, MutableRefObject } from 'react'
import IconVerticalMore from 'assets/icons/iconVerticalMore'
import { useClickOutside } from 'helpers/useClickOutside'
import { ReactNode, useRef, useState } from 'react'
import styles from './ContextMenu.module.scss'

const { buttonIcon, optionsContainer, optionGroup, optionName } = styles

type OptionProps = {
  icon: ReactNode
  description: string
  descriptionStyle?: CSSProperties
  onPress: (props?: unknown) => void
}

type ContextMenuProps = {
  options: OptionProps[]
}

const ContextMenu = ({ options }: ContextMenuProps) => {
  const [showOptions, setShowOptions] = useState(false)

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  useClickOutside(ref, () => setShowOptions(false))

  const onHandleOptionsVisibility = (): void => {
    setShowOptions((previousValue) => !previousValue)
  }

  return (
    <div className={buttonIcon} onClick={onHandleOptionsVisibility} ref={ref}>
      <IconVerticalMore />
      {showOptions && (
        <div className={optionsContainer}>
          {options.map((option: OptionProps, index: number) => (
            <div className={optionGroup} onClick={() => option.onPress(option)} key={index}>
              {option.icon}
              <p className={optionName} style={option.descriptionStyle}>
                {option.description}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ContextMenu
