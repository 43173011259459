import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { api } from 'redux/rtkQuery'
import { ReduxState } from 'redux/store'
import styles from './TechBotDetails.module.scss'
import IconEdit from 'assets/icons/iconEdit'
import IconDashboard from 'assets/icons/iconDashboard'
import { messages } from 'constants/messages'
import CustomLoader from 'components/customLoader/CustomLoader'
import DetailCard from './components/detailCard/DetailCard'
import { SXObject } from './constants'
import { Switch } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROOT_URL, DATA_SOURCES_URL, WIDGET_CUSTOMIZE_URL } from 'utils/constant'
import { getCurrentMoment, getMonthDateStart } from 'modules/dashboard/helpers/getDate'
import DetailsItem from './components/detailsItem/DetailsItem'
import IconLink from 'assets/icons/iconLink'
import IconAttachment from 'assets/icons/iconAttachment'
import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'

const {
  detailsContainer,
  detailsHeader,
  detailsHeaderInfo,
  detailsHeaderInfoName,
  detailsHeaderInfoWebsite,
  techbotDetailsCardContainer,
  detailsGrid,
} = styles

const { techBotDetails } = messages.techbots

const DATES = {
  startDate: getMonthDateStart(),
  endDate: getCurrentMoment(),
}

const TechBotDetails = () => {
  const [isWidgetActive, setIsWidgetActive] = useState(false)
  // const [isWidgetOffline, setIsWidgetOffline] = useState(false)

  const navigate = useNavigate()

  const user = useAppSelector((state: ReduxState) => state.user)

  const companyId = user?.company_id

  const { data: bot, isLoading: isGettingBots } = api.useGetCompanyBotQuery({ companyId: companyId }, { skip: !user })

  const botId = bot?.id || ''

  const { data: countResourcesData, isLoading: isGettingCount } = api.useGetCountResourcesQuery({ id: botId })

  useEffect(() => {
    setIsWidgetActive(bot?.isWidgetEnabled || false)
  }, [bot])

  const [updateBotById, { isLoading: isBotUpdating }] = api.useUpdateBotByIdMutation()

  const setWidgetActivity = async (widgetActivity: boolean) => {
    if (isBotUpdating) return
    setIsWidgetActive(!widgetActivity)
    try {
      await updateBotById({ id: botId, body: { isWidgetEnabled: !widgetActivity } }).unwrap()
      toastFuncSuccess(techBotDetails.setWidgetActivitySuccess)
    } catch {
      setIsWidgetActive(widgetActivity)
      toastFuncError(techBotDetails.setWidgetActivityError)
    }
  }

  const { data: dashboardData, isLoading } = api.useGetDashboardDataQuery(
    {
      id: companyId,
      startDate: DATES.startDate,
      endDate: DATES.endDate,
    },
    { skip: !companyId, refetchOnMountOrArgChange: true }
  )

  if (!bot || isGettingBots || isLoading || isGettingCount) return <CustomLoader />

  const navigateToEditConfiguration = () => {
    navigate(WIDGET_CUSTOMIZE_URL)
  }

  const navigateToEditDataSources = () => {
    navigate(DATA_SOURCES_URL)
  }

  return (
    <>
      <div className={detailsContainer}>
        <div className={detailsHeader}>
          <div className={detailsHeaderInfo}>
            <div className={detailsHeaderInfoName}>{bot?.name}</div>
            <div className={detailsHeaderInfoWebsite}>{bot?.company_website_domain}</div>
          </div>
          <ActionButton
            label={techBotDetails.edit}
            icon={<IconEdit />}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            onPress={navigateToEditConfiguration}
          />
        </div>
        <div className={techbotDetailsCardContainer}>
          <DetailCard
            label={techBotDetails.activity}
            description={techBotDetails.activityDescription}
            switchToggle={
              <Switch
                checked={isWidgetActive}
                sx={SXObject}
                onClick={() => setWidgetActivity(isWidgetActive)}
                disabled={isBotUpdating}
              />
            }
          />
          {/* WAIT UNTIL BACKEND IS READY */}
          {/* <DetailCard
            label={techBotDetails.offline}
            description={techBotDetails.offlineDescription}
            switchToggle={
              <Switch checked={isWidgetOffline} sx={SXObject} onClick={() => setIsWidgetOffline((prev) => !prev)} />
            }
          /> */}
          <DetailCard
            label={techBotDetails.usage}
            description={techBotDetails.usageDescription}
            usage={dashboardData?.conversations.total}
            button={
              <ActionButton
                label={techBotDetails.openDashboard}
                icon={<IconDashboard />}
                buttonType={ActionButtonType.SECONDARY}
                size={ActionButtonSize.MEDIUM}
                onPress={() => navigate(ROOT_URL)}
              />
            }
          />
        </div>
        <div className={detailsGrid}>
          <DetailsItem
            title={techBotDetails.dataSources}
            button={
              <ActionButton
                label={techBotDetails.editSources}
                icon={<IconEdit fillColor="#FFF" />}
                buttonType={ActionButtonType.PRIMARY}
                size={ActionButtonSize.MEDIUM}
                onPress={navigateToEditDataSources}
              />
            }
            detailsContent={[
              {
                icon: <IconLink fillColor="#686868" />,
                label: techBotDetails.websites,
                value: countResourcesData?.links.added ?? 0,
                description: techBotDetails.websitesLinked,
              },
              {
                icon: <IconAttachment fillColor="#686868" />,
                label: techBotDetails.files,
                value: countResourcesData?.files.added ?? 0,
                description: techBotDetails.filesAdded,
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default TechBotDetails
