import { useSelector } from 'react-redux'
import FolderShortcut from '../folderShortcut/FolderShortcut'
import styles from './ImagesFolders.module.scss'
import { imagesSelector } from 'redux/images/slice'

const { wrapper } = styles

interface IProps {
  handleSetBreadcrumbs: (folderName: string, path: string) => void
}

const ImagesFolders = ({ handleSetBreadcrumbs }: IProps) => {
  const imagesStored = useSelector(imagesSelector)
  const { categories } = imagesStored

  return (
    <div className={wrapper}>
      {categories?.length
        ? categories.map((category: string) => (
            <FolderShortcut key={category} category={category} handleSetBreadcrumbs={handleSetBreadcrumbs} />
          ))
        : null}
    </div>
  )
}

export default ImagesFolders
