import { FC } from 'react'
import styles from './BotPreview.module.scss'
import IconChevronDown from 'assets/icons/iconChevronDown'
import IconOptions from 'assets/icons/iconOptions'
import ContentForBrandInfo from './subcomponents/ContentForBrandInfo'
import ContentForDataSources from './subcomponents/ContentForDataSources'
import InputForBrandInfo from './subcomponents/InputForBrandInfo'
import WidgetMessage from './subcomponents/WidgetMessage'
import { DATA_SOURCES_URL, WIDGET_CUSTOMIZE_URL, WIDGET_INSTALLATION_URL, WIDGET_PLAYGROUND_URL } from 'utils/constant'
import { useLocation } from 'react-router-dom'
import { messages } from 'constants/messages'
import IconPicture from 'assets/icons/iconPicture'
import IconLoader from 'assets/icons/loader/iconLoader'

const {
  container,
  header,
  row,
  avatarContainer,
  nameContainer,
  nameStyles,
  infoStyles,
  dataSourceContainer,
  buildContainer,
  loaderContainer,
  infoIcon,
  largeChat,
} = styles

interface iBotPreview {
  name?: string
  primaryColor: string
  secondaryColor: string
  avatar?: string | null
  isChatLarge?: boolean
  widgetMessage?: string
  welcomeMessage?: string
  offlineMessage?: string
  isChatOffline?: boolean
}

const BotPreview: FC<iBotPreview> = ({
  name,
  primaryColor,
  secondaryColor,
  avatar,
  isChatLarge = false,
  widgetMessage,
  welcomeMessage,
  offlineMessage,
  isChatOffline = false,
}) => {
  const location = useLocation()?.pathname
  const companyName = name || messages.techbots.botPreview.companyName

  const isInitialStep = location === WIDGET_CUSTOMIZE_URL || location === WIDGET_PLAYGROUND_URL

  const isDataSourceStep = location === DATA_SOURCES_URL

  const isBuildStep = location === WIDGET_INSTALLATION_URL

  return (
    <>
      <div
        className={`${container} ${
          isDataSourceStep ? dataSourceContainer : ''
        } ${isBuildStep ? buildContainer : ''} ${isChatLarge ? largeChat : ''}`}
      >
        <div className={header} style={{ backgroundColor: isChatOffline ? '#909090' : primaryColor }}>
          <div className={row}>
            <div className={avatarContainer}>{avatar ? <img src={avatar} alt="Logo" /> : <IconPicture />}</div>
            <div className={nameContainer}>
              <p className={nameStyles} style={{ color: secondaryColor }}>
                {companyName}
              </p>
              <p className={infoStyles} style={{ color: secondaryColor }}>
                {messages.techbots.botPreview.avaliability}
              </p>
            </div>
            <div className={infoIcon}>
              <IconOptions fillColor={secondaryColor} />
            </div>
            <div className={infoIcon}>
              <IconChevronDown fillColor={secondaryColor} />
            </div>
          </div>
        </div>
        {isDataSourceStep && <ContentForDataSources primaryColor={primaryColor} companyName={companyName} />}
        {isInitialStep && (
          <ContentForBrandInfo
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            companyName={companyName}
            welcomeMessage={welcomeMessage}
            offlineMessage={offlineMessage}
            isChatOffline={isChatOffline}
          />
        )}
        {isBuildStep && (
          <div className={loaderContainer}>
            <IconLoader fillColor="#5E36FF" />
          </div>
        )}
        {(isInitialStep || isBuildStep) && (
          <InputForBrandInfo primaryColor={primaryColor} secondaryColor={secondaryColor} />
        )}
      </div>
      <WidgetMessage
        widgetMessage={widgetMessage}
        isChatLarge={isChatLarge}
        isAvatar={!!avatar}
        avatarUrl={avatar || ''}
      />
    </>
  )
}

export default BotPreview
