import { FC } from 'react'

const IconColorDropper: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1602_4190"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1602_4190)">
        <path
          d="M4.46147 20.4995C4.20377 20.4995 3.9887 20.4133 3.81627 20.2409C3.64383 20.0684 3.55762 19.8534 3.55762 19.5957V16.838C3.55762 16.7175 3.57942 16.6028 3.62302 16.4938C3.6666 16.3848 3.73518 16.2835 3.82877 16.19L12.3538 7.66496L11.2576 6.58803C11.1025 6.43932 11.0275 6.26272 11.0326 6.05823C11.0377 5.85375 11.1179 5.67394 11.273 5.51881C11.4217 5.37011 11.5983 5.29576 11.8028 5.29576C12.0072 5.29576 12.1871 5.37011 12.3422 5.51881L13.8922 7.06501L17.1307 3.82653C17.282 3.67525 17.4736 3.59961 17.7057 3.59961C17.9377 3.59961 18.1294 3.67525 18.2807 3.82653L20.1999 5.74571C20.3511 5.89699 20.4268 6.08866 20.4268 6.32071C20.4268 6.55276 20.3511 6.74443 20.1999 6.89571L16.9421 10.1534L18.5383 11.7496C18.687 11.8983 18.763 12.0723 18.7662 12.2717C18.7694 12.471 18.6934 12.6483 18.5383 12.8034C18.3832 12.9585 18.205 13.0361 18.0037 13.0361C17.8025 13.0361 17.6242 12.9585 17.4691 12.8034L16.3729 11.7226L7.86719 20.2284C7.77359 20.322 7.67231 20.3906 7.56334 20.4341C7.45438 20.4777 7.33963 20.4995 7.21912 20.4995H4.46147ZM5.05759 18.9996H7.00759L15.3076 10.6496L13.4076 8.74956L5.05759 17.0496V18.9996ZM15.8768 9.09573L18.6518 6.32071L17.7057 5.37456L14.9306 8.14958L15.8768 9.09573Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default IconColorDropper
