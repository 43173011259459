import { useEffect, useState } from 'react'
import AboutModal from './integrationAboutModal/AboutModal'
import AuthInstructionModal from './integrationAuthInstructionModal/AuthInstructionModal'
import AuthCredentialsModal from './integrationAuthCredentialsModal/AuthCredentialsModal'

type IntegrationActionFlowProps = {
  setIsInstalling: (isInstalling: boolean) => void
}

const IntegrationActionFlow = ({ setIsInstalling }: IntegrationActionFlowProps) => {
  const [integrationStep, setIntegrationStep] = useState(1)

  useEffect(() => {
    return () => {
      setIntegrationStep(1)
    }
  }, [])

  const onClose = () => {
    setIsInstalling(false)
  }

  switch (integrationStep) {
    case 1:
      return <AboutModal onClose={() => onClose()} setIntegrationStep={setIntegrationStep} />
    case 2:
      return <AuthInstructionModal onClose={() => onClose()} setIntegrationStep={setIntegrationStep} />
    case 3:
      return <AuthCredentialsModal onClose={() => onClose()} setIntegrationStep={setIntegrationStep} />
    default:
      return null
  }
}

export default IntegrationActionFlow
