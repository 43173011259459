export interface IProps {
  active?: boolean
}

const IconFolders = ({ active }: IProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2086_3008"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <rect width="16" height="16" fill={active ? '#5E36FF' : '#686868'} />
    </mask>
    <g mask="url(#mask0_2086_3008)">
      <path
        d="M3.33331 7.33331C3.06065 7.33331 2.82581 7.23481 2.62881 7.03781C2.43181 6.84081 2.33331 6.60598 2.33331 6.33331V3.33331C2.33331 3.06065 2.43181 2.82581 2.62881 2.62881C2.82581 2.43181 3.06065 2.33331 3.33331 2.33331H6.33331C6.60598 2.33331 6.84081 2.43181 7.03781 2.62881C7.23481 2.82581 7.33331 3.06065 7.33331 3.33331V6.33331C7.33331 6.60598 7.23481 6.84081 7.03781 7.03781C6.84081 7.23481 6.60598 7.33331 6.33331 7.33331H3.33331ZM3.33331 13.6666C3.06065 13.6666 2.82581 13.5681 2.62881 13.3711C2.43181 13.1741 2.33331 12.9393 2.33331 12.6666V9.66665C2.33331 9.39398 2.43181 9.15915 2.62881 8.96215C2.82581 8.76515 3.06065 8.66665 3.33331 8.66665H6.33331C6.60598 8.66665 6.84081 8.76515 7.03781 8.96215C7.23481 9.15915 7.33331 9.39398 7.33331 9.66665V12.6666C7.33331 12.9393 7.23481 13.1741 7.03781 13.3711C6.84081 13.5681 6.60598 13.6666 6.33331 13.6666H3.33331ZM9.66665 7.33331C9.39398 7.33331 9.15915 7.23481 8.96215 7.03781C8.76515 6.84081 8.66665 6.60598 8.66665 6.33331V3.33331C8.66665 3.06065 8.76515 2.82581 8.96215 2.62881C9.15915 2.43181 9.39398 2.33331 9.66665 2.33331H12.6666C12.9393 2.33331 13.1741 2.43181 13.3711 2.62881C13.5681 2.82581 13.6666 3.06065 13.6666 3.33331V6.33331C13.6666 6.60598 13.5681 6.84081 13.3711 7.03781C13.1741 7.23481 12.9393 7.33331 12.6666 7.33331H9.66665ZM9.66665 13.6666C9.39398 13.6666 9.15915 13.5681 8.96215 13.3711C8.76515 13.1741 8.66665 12.9393 8.66665 12.6666V9.66665C8.66665 9.39398 8.76515 9.15915 8.96215 8.96215C9.15915 8.76515 9.39398 8.66665 9.66665 8.66665H12.6666C12.9393 8.66665 13.1741 8.76515 13.3711 8.96215C13.5681 9.15915 13.6666 9.39398 13.6666 9.66665V12.6666C13.6666 12.9393 13.5681 13.1741 13.3711 13.3711C13.1741 13.5681 12.9393 13.6666 12.6666 13.6666H9.66665ZM3.33331 6.33331H6.33331V3.33331H3.33331V6.33331ZM9.66665 6.33331H12.6666V3.33331H9.66665V6.33331ZM9.66665 12.6666H12.6666V9.66665H9.66665V12.6666ZM3.33331 12.6666H6.33331V9.66665H3.33331V12.6666Z"
        fill={active ? '#5E36FF' : '#686868'}
      />
    </g>
  </svg>
)

export default IconFolders
