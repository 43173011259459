import { FC, DragEvent } from 'react'
import styles from './UploadLogoInput.module.scss'
import IconUploadLogo from 'assets/icons/iconUploadLogo'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { messages } from 'constants/messages'
import IconLoader from 'assets/icons/loader/iconLoader'

interface iUploadLogoInput {
  logoPreview: string
  setLogoPreview: (preview: string) => void
  onChangeLogo: (file: File) => void
  htmlfor?: string
  required?: boolean
  isUploading?: boolean
}

const supportedFileTypes = ['png', 'jpeg', 'jpg']

const { container, row, previewWrapper, preview, content, fileInput, buttonUpload, info, infoTextStyles } = styles

const { uploadLogoInput } = messages.techbots

const UploadLogoInput: FC<iUploadLogoInput> = ({
  logoPreview,
  htmlfor = 'fileUpload',
  onChangeLogo,
  setLogoPreview,
  required = false,
  isUploading = false,
}) => {
  const onChangeFile = (e: any): void => {
    if (typeof e !== 'object') return
    const file = e.target !== undefined ? e?.target?.files[0] : e

    if (file === undefined) return

    // 4000000 = 4mb
    if (file.size > 4000000) {
      toastFuncError(uploadLogoInput.fileToastError)
      if (logoPreview.length !== 0) {
        setLogoPreview('')
        onChangeLogo(new File([], ''))
      }
      return
    }

    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        const preview = reader.result as string
        setLogoPreview(preview)
        onChangeLogo(file)
      }
    }

    if (typeof file === 'object' && typeof reader === 'object') {
      reader.readAsDataURL(file)
    }

    if (typeof e?.target?.value === 'string') {
      e.target.value = null
    }
  }

  const handleDrag = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    let files = e.dataTransfer.files
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let fileType = file.name.split('.').pop()

      if (fileType) {
        fileType = fileType.toLowerCase()
        if (supportedFileTypes.includes(fileType)) {
          onChangeFile(e.dataTransfer.files[i])
        } else {
          toastFuncError('File format is not supported.')
        }
      }
    }
  }

  const allowDrop = (e: DragEvent): void => {
    e.preventDefault()
  }

  return (
    <>
      <div className={container} onDrop={(e) => handleDrag(e)} onDragOver={(e) => allowDrop(e)}>
        <div className={row}>
          <label htmlFor={htmlfor} className={buttonUpload}>
            <div className={previewWrapper}>
              <div className={preview}>
                {isUploading ? (
                  <IconLoader fillColor="#777" />
                ) : logoPreview?.length !== 0 ? (
                  <img src={logoPreview} alt="Logo" />
                ) : (
                  <IconUploadLogo />
                )}
              </div>
            </div>
          </label>
          <div className={content}>
            <p className={info}>{uploadLogoInput.allowedFormats}</p>
            <label htmlFor={htmlfor} className={buttonUpload}>
              {uploadLogoInput.uploadLabel}
            </label>
            <input
              className={fileInput}
              id={htmlfor}
              type="file"
              name="file"
              onChange={(e) => onChangeFile(e)}
              accept="image/*"
              hidden
            />
          </div>
        </div>
      </div>
      {required && <p className={infoTextStyles}>*Required</p>}
    </>
  )
}

export default UploadLogoInput
