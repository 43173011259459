import styles from './SelfLearning.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { messages } from 'constants/messages'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import CustomLoader from 'components/customLoader/CustomLoader'
import { IntegrationStatus } from 'modules/integrations/Integrations'
import { SourceType } from 'types/api'
import dayjs from 'dayjs'

const { techBotDataSources } = messages.techbots

const {
  selfLearningContainer,
  zendeskTicketContainer,
  zendeskTicketText,
  zendeskTicketTitle,
  zendeskTicketDescription,
  syncContainer,
  syncDate,
} = styles

interface iSelfLearningItem {
  title: string
  description: string
  handleSync: () => void
  lastSync: string
  isLoading: boolean
}

const SelfLearning = () => {
  const user = useAppSelector((state: ReduxState) => state.user)
  const companyId = user?.company_id
  const { data, isLoading: isGettingBots } = api.useGetCompanyBotQuery({ companyId: companyId }, { skip: !user })

  const botId = data?.id || ''

  const { data: integrationData, isLoading: isGettingIntegrationDetails } = api.useGetIntegrationDetailsQuery(
    { botId: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [getZendeskKnowledge, { isLoading: isZendeskLoading }] = api.useGetZendeskKnowledgeMutation()

  const [syncConversations, { isLoading: isSyncingConversations }] = api.useSyncConversationsMutation()
  const { data: sourceChangesData } = api.useGetSourceChangesQuery({ botId: botId }, { skip: !botId })

  const handleGetKnowledge = async () => {
    try {
      await getZendeskKnowledge({ botId: botId }).unwrap()
      toastFuncNotification(techBotDataSources.zendeskSyncSuccessToast)
    } catch (error: unknown) {
      const errorData = error as { data?: { message?: string } }
      toastFuncError(errorData?.data?.message ? errorData?.data?.message : techBotDataSources.errorToast)
    }
  }

  const handleSyncConversations = async () => {
    try {
      await syncConversations({ botId: botId }).unwrap()
      toastFuncNotification(techBotDataSources.syncingSuccess)
    } catch (error: unknown) {
      const errorData = error as { data?: { message?: string } }
      toastFuncError(errorData?.data?.message ? errorData?.data?.message : techBotDataSources.errorToast)
    }
  }

  const isConversationSyncInProgress = sourceChangesData?.some(
    (sourceChange) => sourceChange.type === SourceType.SUPPORTX_CONVERSATIONS && sourceChange.processing
  )

  return (
    <div className={selfLearningContainer}>
      {(isGettingBots || isGettingIntegrationDetails) && <CustomLoader />}
      <SelfLearningItem
        title={techBotDataSources.useSupportX}
        description={techBotDataSources.useSupportXDescription}
        lastSync={
          data?.last_conversations_sync_at
            ? dayjs(data?.last_conversations_sync_at).format('MM/DD')
            : techBotDataSources.neverSynced
        }
        isLoading={isSyncingConversations || !!isConversationSyncInProgress}
        handleSync={handleSyncConversations}
      />
      {integrationData?.zendesk?.status === IntegrationStatus.INSTALLED && (
        <SelfLearningItem
          title={techBotDataSources.useZendesk}
          description={techBotDataSources.useZendeskDescription}
          handleSync={handleGetKnowledge}
          isLoading={isZendeskLoading}
          lastSync={
            integrationData?.zendesk?.lastSync
              ? dayjs(integrationData?.zendesk?.lastSync).format('MM/DD')
              : techBotDataSources.neverSynced
          }
        />
      )}
    </div>
  )
}

export default SelfLearning

const SelfLearningItem = ({ title, description, handleSync, lastSync, isLoading }: iSelfLearningItem) => {
  return (
    <div className={zendeskTicketContainer}>
      <div className={zendeskTicketText}>
        <div className={zendeskTicketTitle}>{title}</div>
        <div className={zendeskTicketDescription}>{description}</div>
      </div>
      <div className={syncContainer}>
        <ActionButton
          label={isLoading ? techBotDataSources.syncing : techBotDataSources.syncZendesk}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          onPress={handleSync}
          disabled={isLoading}
          loading={isLoading}
        />
        <div className={syncDate}>
          {techBotDataSources.lastSync} {lastSync}
        </div>
      </div>
    </div>
  )
}
