import ActionButton, { ActionButtonType } from 'components/actionButton/ActionButton'
import styles from './SalesContactForm.module.scss'
import IconClose from 'assets/icons/iconClose'
import { useNavigate } from 'react-router-dom'
import { BUY_SUBSCRIPTION_URL, NEW_SUBSCRIPTION_STEP_SELECT_PLAN, SUBSCRIPTION_URL } from 'utils/constant'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setNewSubscriptionFlow } from 'redux/subscriptionReducer'
import { messages } from 'constants/messages'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { useState } from 'react'
import CustomLoader from 'components/customLoader/CustomLoader'

const { sendRequestScreen, successScreen } = messages.salesContactForm

const {
  salesFormSection,
  salesFormContainer,
  salesFormContent,
  salesFormBtns,
  salesFormTitle,
  salesFormSubtitle,
  salesFormDescription,
  salesFormContainerRight,
  iconClose,
} = styles

const SalesContactForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [isRequestSend, setIsRequestSend] = useState(false)

  const companyId = useAppSelector((state: ReduxState) => state.user.company_id)
  const [contactToSales, { isLoading: isContactToSales }] = api.useContactToSalesMutation()

  const sendRequest = async () => {
    try {
      await contactToSales({ companyId }).unwrap()
      setIsRequestSend(true)
    } catch (error) {
      toastFuncError(sendRequestScreen.errorMessage)
    }
  }

  const handleClose = () => {
    if (isRequestSend) {
      navigate(SUBSCRIPTION_URL)
    } else {
      navigate(BUY_SUBSCRIPTION_URL)
      dispatch(setNewSubscriptionFlow(NEW_SUBSCRIPTION_STEP_SELECT_PLAN))
    }
  }

  return (
    <>
      {isContactToSales && <CustomLoader />}
      <div className={salesFormSection}>
        <div className={salesFormContainer}>
          <div className={salesFormContent}>
            <h2 className={salesFormTitle}>{isRequestSend ? successScreen.title : sendRequestScreen.title}</h2>
            <p className={isRequestSend ? salesFormDescription : salesFormSubtitle}>
              {isRequestSend ? successScreen.description : sendRequestScreen.subtitle}
            </p>
            {!isRequestSend && (
              <p className={salesFormDescription}>
                {sendRequestScreen.description}
              </p>
            )}
            <div className={salesFormBtns}>
              <ActionButton
                label={isRequestSend ? successScreen.closeButton : sendRequestScreen.requestButton}
                onPress={isRequestSend ? handleClose : sendRequest}
              />
              {!isRequestSend && (
                <ActionButton
                  label={sendRequestScreen.cancelButton}
                  buttonType={ActionButtonType.TRANSPARENT}
                  onPress={handleClose}
                />
              )}
            </div>
          </div>
        </div>
        <div className={salesFormContainerRight}>
          <div className={iconClose} onClick={handleClose}>
            <IconClose fillColor={'#FFFFFF'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default SalesContactForm
