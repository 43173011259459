interface IconArrowProps {
  style?: string
}

const IconArrow = ({ style }: IconArrowProps) => (
  <svg className={style} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25163 0.552897L9.18432 7.47277C9.26232 7.55076 9.31734 7.63302 9.34938 7.71956C9.38143 7.80609 9.39746 7.89957 9.39746 8C9.39746 8.10043 9.38143 8.19391 9.34938 8.28044C9.31734 8.36698 9.26232 8.44924 9.18432 8.52723L2.24363 15.4679C2.08657 15.625 1.89427 15.7035 1.66671 15.7035C1.43916 15.7035 1.23991 15.618 1.06896 15.4471C0.903364 15.2815 0.820566 15.0865 0.820566 14.8622C0.820566 14.6378 0.903364 14.4428 1.06896 14.2773L7.34621 8L1.06096 1.71473C0.903907 1.55769 0.825379 1.36752 0.825379 1.14423C0.825379 0.920939 0.910851 0.723827 1.08179 0.552897C1.24738 0.3873 1.44235 0.304502 1.66671 0.304502C1.89107 0.304502 2.08605 0.3873 2.25163 0.552897Z"
      fill="#777777"
    />
  </svg>
)

export default IconArrow
