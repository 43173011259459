import { FC } from 'react'

const IconEyeShow: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1233_2307"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1233_2307)">
        <path
          d="M12.0023 15.5769C13.1354 15.5769 14.0978 15.1803 14.8895 14.3871C15.6811 13.5939 16.077 12.6308 16.077 11.4977C16.077 10.3646 15.6804 9.40224 14.8872 8.61058C14.094 7.81891 13.1309 7.42308 11.9978 7.42308C10.8647 7.42308 9.90233 7.81966 9.11066 8.61282C8.31899 9.40601 7.92316 10.3692 7.92316 11.5023C7.92316 12.6353 8.31974 13.5977 9.11291 14.3894C9.90609 15.181 10.8692 15.5769 12.0023 15.5769ZM12.0001 14.2C11.2501 14.2 10.6126 13.9375 10.0876 13.4125C9.56256 12.8875 9.30006 12.25 9.30006 11.5C9.30006 10.75 9.56256 10.1125 10.0876 9.58748C10.6126 9.06248 11.2501 8.79998 12.0001 8.79998C12.7501 8.79998 13.3876 9.06248 13.9126 9.58748C14.4376 10.1125 14.7001 10.75 14.7001 11.5C14.7001 12.25 14.4376 12.8875 13.9126 13.4125C13.3876 13.9375 12.7501 14.2 12.0001 14.2ZM12.0001 18.5C9.81161 18.5 7.80553 17.9243 5.98181 16.773C4.15811 15.6217 2.76741 14.0653 1.80971 12.1038C1.76613 12.0269 1.736 11.9353 1.71934 11.8292C1.70267 11.723 1.69434 11.6132 1.69434 11.5C1.69434 11.3867 1.70267 11.277 1.71934 11.1708C1.736 11.0646 1.76613 10.9731 1.80971 10.8961C2.76741 8.93461 4.15811 7.37821 5.98181 6.22693C7.80553 5.07564 9.81161 4.5 12.0001 4.5C14.1885 4.5 16.1946 5.07564 18.0183 6.22693C19.842 7.37821 21.2327 8.93461 22.1904 10.8961C22.234 10.9731 22.2641 11.0646 22.2808 11.1708C22.2975 11.277 22.3058 11.3867 22.3058 11.5C22.3058 11.6132 22.2975 11.723 22.2808 11.8292C22.2641 11.9353 22.234 12.0269 22.1904 12.1038C21.2327 14.0653 19.842 15.6217 18.0183 16.773C16.1946 17.9243 14.1885 18.5 12.0001 18.5ZM12.0001 17C13.8834 17 15.6126 16.5041 17.1876 15.5125C18.7626 14.5208 19.9667 13.1833 20.8001 11.5C19.9667 9.81664 18.7626 8.47914 17.1876 7.48748C15.6126 6.49581 13.8834 5.99998 12.0001 5.99998C10.1167 5.99998 8.38756 6.49581 6.81256 7.48748C5.23756 8.47914 4.03339 9.81664 3.20006 11.5C4.03339 13.1833 5.23756 14.5208 6.81256 15.5125C8.38756 16.5041 10.1167 17 12.0001 17Z"
          fill="#686868"
        />
      </g>
    </svg>
  )
}

export default IconEyeShow
