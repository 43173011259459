import { ChangeEvent } from 'react'
import styles from './RadioSelector.module.scss'

interface IRadioInput {
  value?: string
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  id?: string
}

const RadioInput = ({ value, checked, onChange, id }: IRadioInput) => {
  const { customRadio } = styles
  return (
    <input
      className={customRadio}
      type="radio"
      id={id}
      name="radio"
      value={value}
      checked={checked}
      onChange={onChange}
    />
  )
}

export default RadioInput
