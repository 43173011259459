import CustomInput from 'components/customInput/CustomInput'
import styles from './NewFolderModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useState } from 'react'
import { messages } from 'constants/messages'
import { useDispatch } from 'react-redux'
import { imagesActions } from 'redux/images/slice'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'

const { wrapper, title } = styles

interface IProps {
  onClose?: () => void
}

const NewFolderModalContent = ({ onClose }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const [folderName, setFolderName] = useState('')

  const handleSetFolderName = (value: string) => {
    setFolderName(value)
  }

  const handleCreateFolder = () => {
    dispatch(imagesActions.createFolder({ folderName }))

    if (onClose) onClose()
    toastFuncNotification(images.addFolderSuccess)
  }

  return (
    <div className={wrapper}>
      <p className={title}>{images.newFolderModalTitle}</p>
      <div>
        <CustomInput placeholder="Folder Name" value={folderName} onChangeValue={handleSetFolderName} />
      </div>
      <ActionButton
        label={images.buttons.createFolder}
        buttonType={ActionButtonType.PRIMARY}
        size={ActionButtonSize.MEDIUM}
        onPress={handleCreateFolder}
      />
      <ActionButton
        label={images.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default NewFolderModalContent
