import styles from '../BotPreview.module.scss'
import { hexToRgba } from 'utils/tools'
import { messages } from 'constants/messages'

interface iContentForDataSources {
  primaryColor: string
  companyName: string
}

const { content, message, authorContainer, authorStyles, messageForDataSourcesContent } = styles

const ContentForDataSources = ({ primaryColor, companyName }: iContentForDataSources): JSX.Element => (
  <>
    <div className={content}>
      <div className={message}>
        <div className={authorContainer} style={{ backgroundColor: hexToRgba(primaryColor, '0.05') }}>
          <div className={authorStyles} style={{ color: primaryColor }}>
            {companyName}
          </div>
        </div>
        <div className={messageForDataSourcesContent}>
          {primaryColor !== '' ? messages.techbots.contentForDataSources.messageContent : ''}
        </div>
      </div>
    </div>
  </>
)

export default ContentForDataSources
