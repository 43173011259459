import { AnyAction } from '@reduxjs/toolkit'

const initialState = {}

export const SET_LOGGED_USER = 'SET_LOGGED_USER'

export const user = (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
