export const dataForm = (file: File) => {
  if (!(file instanceof File)) {
    console.error('Error: file is not a File object')
    return new FormData()
  }

  const formdata = new FormData()
  formdata.append('file', file)

  if (formdata.has('file')) {
    return formdata
  }

  console.error('Error: formdata does not have a file')
  return new FormData()
}
