import { Divider, MenuItem } from '@mui/material'
import IconDelete from 'assets/icons/iconDelete'
import IconMoveTo from 'assets/icons/iconMoveTo'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'

import style from './DropdownFileContent.module.scss'
import { messages } from 'constants/messages'
import IconDownload from 'assets/icons/iconDownload'
import { IUploadedFile } from 'types/api'
import IconArrow from 'assets/icons/iconArrow'
// import IconEdit from 'assets/icons/iconEdit'

const { dropdownContent, iconArrow } = style

interface IProps {
  currentPath: string
  file: IUploadedFile
  handleMoveTo: (file: IUploadedFile) => void
}

const DropdownFileContent = ({ currentPath, file, handleMoveTo }: IProps) => {
  const { files } = messages
  const dispatch = useDispatch()

  const handleOpenDeleteImageModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FILE,
        fileId: file.id,
        fileName: file.name,
      })
    )
  }

  // const handleOpenRenameFolderModal = () => {
  //   dispatch(
  //     modalActions.setShowModal({
  //       modalType: MODAL_TYPES.RENAME_FILE_FOLDER,
  //       fileId: file.id,
  //       fileName: file.name,
  //       path: currentPath,
  //     })
  //   )
  // }

  return (
    <div className={dropdownContent}>
      <MenuItem>
        <a href={file.download_url} target="_blank" rel="noreferrer">
          <IconDownload /> {files.downloadFile}
        </a>
      </MenuItem>
      {/* <Divider style={{ margin: 0 }} />
      <MenuItem onClick={handleOpenRenameFolderModal}>
        <IconEdit fillColor="#777777" /> {files.rename}
      </MenuItem> */}
      <Divider style={{ margin: 0 }} />
      <MenuItem onClick={() => handleMoveTo(file)} id="subMenu">
        <IconMoveTo /> {files.moveTo} <IconArrow style={iconArrow} />
      </MenuItem>
      <Divider style={{ margin: 0 }} />
      <MenuItem onClick={handleOpenDeleteImageModal}>
        <IconDelete fillColor="red" /> {files.deleteFile}
      </MenuItem>
    </div>
  )
}

export default DropdownFileContent
