import { FC } from 'react'

interface iIconClose {
  fillColor?: string
}

const IconClose: FC<iIconClose> = ({ fillColor = '#686868' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_1401_587"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1401_587)">
        <path
          d="M12.05 13.5039L7.10191 18.452C6.88013 18.6738 6.62276 18.7863 6.32981 18.7895C6.03688 18.7927 5.77631 18.6802 5.54811 18.452C5.3199 18.2238 5.20579 17.969 5.20579 17.6876C5.20579 17.4062 5.3199 17.1514 5.54811 16.9232L10.5212 11.9501L5.52311 6.97698C5.30131 6.7552 5.18881 6.50617 5.18561 6.22988C5.1824 5.95362 5.2949 5.70138 5.52311 5.47318C5.75131 5.24497 6.00611 5.13086 6.28751 5.13086C6.56891 5.13086 6.82371 5.24497 7.05191 5.47318L12 10.3963L16.8981 5.49818C17.1199 5.27638 17.3773 5.16388 17.6702 5.16068C17.9631 5.15747 18.2237 5.26997 18.4519 5.49818C18.6801 5.72638 18.7942 5.98952 18.7942 6.28758C18.7942 6.58565 18.6801 6.84878 18.4519 7.07698L13.5288 12.0251L18.4769 16.9732C18.682 17.1783 18.7862 17.4273 18.7894 17.7203C18.7926 18.0132 18.6885 18.2655 18.4769 18.477C18.2654 18.6885 18.0189 18.7943 17.7375 18.7943C17.4561 18.7943 17.2096 18.6885 16.9981 18.477L12.05 13.5039Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default IconClose
