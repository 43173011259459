import { useState } from 'react'
import styles from './IntegrationCard.module.scss'
import IntegrationActionFlow from '../integrationActionFlow/IntegrationActionFlow'
import ContextMenu from 'components/contextMenu/ContextMenu'
import IconDelete from 'assets/icons/iconDelete'

const {
  integrationCardContainer,
  integrationLogo,
  integrationContextMenu,
  integrationName,
  integrationDescription,
  integrationButton,
  integrationInstall,
  integrationInstalled,
  integrationComingSoon,
} = styles

type IntegrationCardProps = {
  logo: string
  name: string
  description: string
  isAvaliable?: boolean
  isInstalled?: boolean
  onDelete?: () => void
}

const IntegrationCard = ({ logo, name, description, isAvaliable, isInstalled, onDelete }: IntegrationCardProps) => {
  const [isInstalling, setIsInstalling] = useState(false)

  return (
    <>
      <div className={integrationCardContainer}>
        <div className={integrationLogo}>
          <img src={logo} alt="logo" />
        </div>
        {isInstalled && (
          <div className={integrationContextMenu}>
            <ContextMenu
              options={[
                {
                  icon: <IconDelete fillColor="#E40F0F" />,
                  description: 'Disconnect Zendesk',
                  descriptionStyle: { color: '#E40F0F' },
                  onPress: () => (onDelete ? onDelete() : {}),
                },
              ]}
            />
          </div>
        )}
        <div className={integrationName}>{name}</div>
        <div className={integrationDescription}>{description}</div>
        {isAvaliable && (
          <div className={`${integrationButton} ${integrationInstall}`} onClick={() => setIsInstalling(true)}>
            Install
          </div>
        )}
        {isInstalled && <div className={`${integrationButton} ${integrationInstalled}`}>Installed</div>}
        {!isAvaliable && !isInstalled && (
          <div className={`${integrationButton} ${integrationComingSoon}`}>Coming Soon</div>
        )}
      </div>
      {isInstalling && <IntegrationActionFlow setIsInstalling={setIsInstalling} />}
    </>
  )
}

export default IntegrationCard
