import { ChangeEvent, useEffect, useState } from 'react'
import styles from './Images.module.scss'
import IconList from 'assets/icons/iconList'
import IconFolders from 'assets/icons/iconFolders'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconUploadLogo from 'assets/icons/iconUploadLogo'
import IconEditFolder from 'assets/icons/iconEditFolder'
import ImagesTable from './imagesTable/ImagesTable'

import ImagesFolders from './imagesFolders/ImagesFolders'
import { api } from 'redux/rtkQuery'
import CustomLoader from 'components/customLoader/CustomLoader'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import { imagesActions } from 'redux/images/slice'
import CustomBreadcrumbs from 'components/breadcrumbs/Breadcrumbs'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'

const {
  imagesContainer,
  controlPanel,
  controlPanelTitle,
  selectionGroup,
  option,
  groupWrapper,
  noNameButton,
  ListUploadButtonWrapper,
  imageWrapper,
} = styles

const enum TABS {
  LIST = 'List',
  FOLDERS = 'Folders',
}

const Images = () => {
  const { images } = messages
  const dispatch = useDispatch()
  const [selectedOption, setSelectedOption] = useState<TABS>(TABS.LIST)
  const { breadcrumbs, handleSetBreadcrumbs } = useBreadcrumbs()
  const bot = useSelector(botSelector)
  const { data: imagesData } = api.useGetImagesQuery(
    { botId: bot.id },
    { skip: !bot.id, refetchOnMountOrArgChange: true }
  )

  const { refetch: refetchCount } = api.useGetCountResourcesQuery({ id: bot.id }, { skip: !bot.id })

  const imagesOfCategory =
    imagesData?.filter((image) => image.category === breadcrumbs[breadcrumbs.length - 1].path) || []

  const handleSetIsUploadImageModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.UPLOAD_IMAGE,
      })
    )
  }

  const handleSetIsNewFolderModalOpen = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.NEW_FOLDER,
      })
    )
  }

  const handleSetSelectedOption = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as TABS)
  }

  useEffect(() => {
    dispatch(
      imagesActions.setImagesAndCategories({
        images: imagesData || [],
      })
    )
  }, [dispatch, imagesData])

  useEffect(() => {
    if (imagesData) {
      refetchCount()
    }
  }, [imagesData, refetchCount])

  return (
    <div className={imagesContainer}>
      <div className={controlPanel}>
        <div className={groupWrapper}>
          <h3 className={controlPanelTitle}>
            {images.allImages} ({imagesData?.length ? imagesData.length : 0})
          </h3>

          <CustomBreadcrumbs options={breadcrumbs} handleSetBreadcrumbs={handleSetBreadcrumbs} />
        </div>
        <div className={groupWrapper} style={{ gap: '8px' }}>
          <div className={selectionGroup}>
            <label className={option}>
              <input
                type="radio"
                name="selection"
                value="List"
                checked={selectedOption === TABS.LIST}
                onChange={handleSetSelectedOption}
              />
              <span>
                <IconList active={selectedOption === TABS.FOLDERS} />
                {images.list}
              </span>
            </label>
            <label className={option}>
              <input
                type="radio"
                name="selection"
                value="Folders"
                checked={selectedOption === TABS.FOLDERS}
                onChange={handleSetSelectedOption}
              />
              <span>
                <IconFolders active={selectedOption === TABS.FOLDERS} />
                {images.folders}
              </span>
            </label>
          </div>
          <button className={noNameButton} onClick={handleSetIsNewFolderModalOpen}>
            <IconEditFolder />
          </button>
          <ActionButton
            label={images.buttons.uploadImages}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            onPress={handleSetIsUploadImageModalOpen}
            icon={<IconUploadLogo fillColor="#ffffff" />}
          />
        </div>
      </div>

      {!imagesData ? (
        <CustomLoader />
      ) : selectedOption === TABS.LIST ? (
        imagesData.length === 0 ? (
          <div className={ListUploadButtonWrapper}>
            <div className={imageWrapper} onClick={handleSetIsUploadImageModalOpen}>
              <IconUploadLogo fillColor="#5E36FF" />
            </div>
            <p>{images.allUploadedImagesWillShowUpHere}</p>
          </div>
        ) : (
          <ImagesTable images={imagesOfCategory.length ? imagesOfCategory : imagesData} />
        )
      ) : null}

      {selectedOption === TABS.FOLDERS && <ImagesFolders handleSetBreadcrumbs={handleSetBreadcrumbs} />}
    </div>
  )
}

export default Images
