import styles from '../BotPreview.module.scss'
import IconClose from 'assets/icons/iconClose'
import IconChatBubblePointer from 'assets/icons/iconChatBubblePointer'
import { messages } from 'constants/messages'
import IconPicture from 'assets/icons/iconPicture'

interface iWidgetMessage {
  widgetMessage?: string
  isChatLarge: boolean
  isAvatar: boolean
  avatarUrl?: string
}

const {
  botPreviewWelcomeContainer,
  avatarContainer,
  welcomeMessageContainer,
  welcomeLogoContainer,
  iconChatBubblePointer,
  largeChat,
} = styles

const WidgetMessage = ({ widgetMessage, isChatLarge, isAvatar, avatarUrl }: iWidgetMessage): JSX.Element => (
  <div className={`${botPreviewWelcomeContainer} ${isChatLarge ? largeChat : ''}`}>
    <div className={welcomeMessageContainer}>
      <IconClose />
      <p>{widgetMessage === '' ? messages.techbots.widgetMessage.widgetMessagePlaceholder : widgetMessage}</p>
    </div>
    <div className={iconChatBubblePointer}>
      <IconChatBubblePointer />
    </div>
    <div className={welcomeLogoContainer}>
      <div className={avatarContainer}>{isAvatar ? <img src={avatarUrl} alt="Logo" /> : <IconPicture />}</div>
    </div>
  </div>
)

export default WidgetMessage
