import { FC, useEffect, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { ID_KEY, ROOT_URL, TOKEN_KEY } from 'utils/constant'
import { readFromStorage } from 'utils/storage'

export type ProtectForAuthUserProps = {
  children: ReactNode
}
export const ProtectForAuthUser: FC<ProtectForAuthUserProps> = ({ children }) => {
  const navigate = useNavigate()
  const token = readFromStorage(TOKEN_KEY)
  const id = readFromStorage(ID_KEY)

  useEffect(() => {
    if (typeof token === 'string' && typeof id === 'string') {
      navigate(ROOT_URL)
    }
  }, [id, navigate, token])

  return <>{children}</>
}
