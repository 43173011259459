import { ChangeEvent, useState } from 'react'
import { IGetFilesData, IUploadedFile, IUploadedFolder } from 'types/api'
import { Breadcrumbs } from './Files'
import { Box } from '@mui/system'
import { Loader } from 'components/Loader'
import { Typography } from '@mui/material'
import { isFolder } from 'helpers/isFolder'
import { themeColors } from 'styles/colors'
import { FolderIcon } from 'assets/icons/iconFloder'
import { getFileFormatFromName } from 'helpers/getFileFormatFromName'
import { EmptyFolderView } from './EmptyFolderView'
import { FileActionsMenu } from './FileActionsMenu'
import { MoveTo } from './MoveTo'
import { FolderActionsMenu } from './FolderActionsMenu'
import PaginationComponent from 'components/pagination/PaginationComponent'
import formatFileSize from 'helpers/formatFileSize'

interface FolderViewProps {
  data: IGetFilesData | null | undefined
  onChangePage: (event: ChangeEvent<unknown>, value: number) => void
  setBreadcrumbs: (folderName: string, path: string) => void
  breadcrumbs: Breadcrumbs
  pathOfBreadcrumbs: string
  handleSetIsUploadFileModalOpen: () => void
  isFetching: boolean
}

export const FolderView = ({
  data,
  onChangePage,
  setBreadcrumbs,
  breadcrumbs,
  pathOfBreadcrumbs,
  handleSetIsUploadFileModalOpen,
  isFetching,
}: FolderViewProps) => {
  const files = data?.files || []
  const folders = data?.folders || []

  const [selectedMoveToFile, setSelectedMoveToFile] = useState<IUploadedFile | IUploadedFolder | null>(null)
  const handleMoveTo = (entry: IUploadedFile | IUploadedFolder) => {
    setSelectedMoveToFile(entry)
  }

  const entries = [...folders, ...files]

  if (isFetching) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <Loader />
      </Box>
    )
  }

  if (!files.length && !folders?.length) {
    return <EmptyFolderView handleSetIsUploadFileModalOpen={handleSetIsUploadFileModalOpen} />
  }

  return (
    <>
      {selectedMoveToFile && (
        <MoveTo selectedEntry={selectedMoveToFile} setSelectedEntry={setSelectedMoveToFile} currentPath={breadcrumbs} />
      )}
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
        }}
      >
        {entries.map((entry) => (
          <Box
            key={entry.id}
            sx={{
              display: 'flex',
              width: '189px',
              height: '168px',
              borderRadius: '12px',
              border: '1px solid  #DCDCDC',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              padding: '16px',
            }}
          >
            <Box
              onClick={() => {
                if (isFolder(entry)) {
                  setBreadcrumbs(entry.name, entry.id)
                } else {
                  window.open(entry.download_url, '_blank')
                }
              }}
              sx={{
                cursor: 'pointer',
                height: '80px',
                width: '80px',
                borderRadius: '8px',
                backgroundColor: '#D9D9D9',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isFolder(entry) ? <FolderIcon /> : getFileFormatFromName(entry.name)}
            </Box>
            <Box
              sx={{
                flexWrap: 1,
                width: '100%',
                mt: '16px',
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: themeColors.darkGray,
                    overflow: 'hidden',
                    maxWidth: '120px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {entry.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    color: themeColors.gray,
                  }}
                >
                  {isFolder(entry) ? `Files: ${entry.files_in_folder}` : formatFileSize(entry.size)}
                </Typography>
              </Box>
              <Box>
                {isFolder(entry) ? (
                  <FolderActionsMenu folder={entry} handleMoveTo={handleMoveTo} />
                ) : (
                  <FileActionsMenu file={entry} handleMoveTo={handleMoveTo} currentPath={pathOfBreadcrumbs} />
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {data && data?.totalPages > 1 && (
        <PaginationComponent totalPages={data.totalPages} page={data.page} handlePageChange={onChangePage} />
      )}
    </>
  )
}
