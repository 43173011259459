import { api } from 'redux/rtkQuery'
import styles from './DeleteFileModalContent.module.scss'
import { toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions, modalSelector } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'

const { wrapper, title, description } = styles

interface IProps {
  onClose?: () => void
}

const DeleteFileModalContent = ({ onClose }: IProps) => {
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const text = modalContext.imageId ? messages.images : messages.files

  const [deleteImage, { isLoading }] = api.useDeleteImageMutation()
  const [deleteFile, { isLoading: loadingDeleteFile }] = api.useDeleteFileMutation()

  const handleDeleteFile = async () => {
    if (modalContext.imageId) {
      await deleteImage({
        botId: bot.id,
        imageId: modalContext.imageId,
      })
    }

    await deleteFile({
      botId: bot.id,
      fileId: modalContext.fileId,
    })

    toastFuncSuccess(text.fileDeleted)
    dispatch(modalActions.setShowModal({ modalType: MODAL_TYPES.DELETE_FILE }))
  }

  return (
    <div className={wrapper}>
      <p className={title}>{text.areYouSure}</p>
      <p className={description}>
        {text.deleteFileModalMessageFirst} {modalContext.fileName} {text.deleteFileModalMessageSecond}{' '}
      </p>
      <ActionButton
        label={text.buttons.delete}
        loading={isLoading || loadingDeleteFile}
        buttonType={ActionButtonType.WARNING}
        size={ActionButtonSize.MEDIUM}
        onPress={handleDeleteFile}
      />
      <ActionButton
        label={text.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default DeleteFileModalContent
