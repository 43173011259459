import { FC, ReactNode } from 'react'
import { useIsAllowedFor } from 'helpers/isAllowedFor'
import { Navigate } from 'react-router-dom'
import { UserRoles } from 'types/api'
import { ROOT_URL } from 'utils/constant'

export type ProtectedForProps = {
  roles: UserRoles[]
  children: ReactNode
  redirectTo?: string
}
export const ProtectedFor: FC<ProtectedForProps> = ({ children, roles, redirectTo }) => {
  const isAllowed = useIsAllowedFor(roles)

  if (!isAllowed) return <Navigate to={redirectTo || ROOT_URL} />

  return <>{children}</>
}
