import { Link, useLocation } from 'react-router-dom'
import { TECH_BOT_DATA_SOURCES_NAVIGATION } from './constants'
import styles from './TechBotDataSourcesNavigation.module.scss'

const { navigationContainer, navigationItem, active } = styles

const TechBotDataSourcesNavigation = () => {
  const location = useLocation()?.pathname

  return (
    <div className={navigationContainer}>
      {TECH_BOT_DATA_SOURCES_NAVIGATION.map(({ label, route }) => (
        <Link to={route} className={`${navigationItem} ${location === route && active}`} key={label}>
          <p>{label}</p>
        </Link>
      ))}
    </div>
  )
}

export default TechBotDataSourcesNavigation
