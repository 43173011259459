import styles from './AboutModal.module.scss'
import IconClose from 'assets/icons/iconClose'
import ZendeskIntegrationIcon from 'assets/icons/iconZendeskIntegration'
import ActionButton, { ActionButtonSize } from 'components/actionButton/ActionButton'
import { messages } from 'constants/messages'

const {
  aboutModalContainer,
  aboutModalContent,
  aboutHeaderContainer,
  aboutHeaderTitle,
  aboutCloseIcon,
  aboutStep,
  aboutImage,
  aboutTitle,
  aboutDescription,
  aboutButtonContainer,
} = styles

type AboutModalProps = {
  onClose: () => void
  setIntegrationStep: (step: number) => void
}

const { stepOne } = messages.integrations.zendesk

const AboutModal = ({ onClose, setIntegrationStep }: AboutModalProps) => {
  return (
    <div className={aboutModalContainer}>
      <div className={aboutModalContent}>
        <div className={aboutHeaderContainer}>
          <div className={aboutHeaderTitle}>{stepOne.title}</div>
          <div className={aboutCloseIcon} onClick={onClose}>
            <IconClose />
          </div>
        </div>
        <div className={aboutStep}>{stepOne.step}</div>
        <div className={aboutImage}>
          <ZendeskIntegrationIcon />
        </div>
        <div className={aboutTitle}>{stepOne.about}</div>
        <div className={aboutDescription}>{stepOne.description}</div>
        <div className={aboutButtonContainer}>
          <ActionButton label={stepOne.install} size={ActionButtonSize.MEDIUM} onPress={() => setIntegrationStep(2)} />
        </div>
      </div>
    </div>
  )
}

export default AboutModal
