export const handleURL = (url: string) => {
  // if url starts with www add https://
  if (url.startsWith('www')) {
    return `https://${url}`;
  }
  // if url starts with http:// or https:// return url
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  // if url starts with anything else add https://
  return `https://${url}`;
};
