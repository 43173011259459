const IconMoveTo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <mask id="mask0_6090_52281" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
      <rect width="20" height="20" fill="#D9D9D9" />
    </mask>
    <g>
      <path
        d="M3.58977 16.25C3.1688 16.25 2.81248 16.1042 2.52081 15.8125C2.22915 15.5208 2.08331 15.1645 2.08331 14.7435V5.25646C2.08331 4.83549 2.22915 4.47917 2.52081 4.1875C2.81248 3.89583 3.1688 3.75 3.58977 3.75H7.54165C7.74248 3.75 7.9356 3.78903 8.12102 3.86708C8.3063 3.945 8.46734 4.05236 8.60415 4.18917L9.83165 5.41667H16.4102C16.8312 5.41667 17.1875 5.5625 17.4791 5.85417C17.7708 6.14583 17.9166 6.50215 17.9166 6.92313V14.7435C17.9166 15.1645 17.7708 15.5208 17.4791 15.8125C17.1875 16.1042 16.8312 16.25 16.4102 16.25H3.58977ZM3.58977 15H16.4102C16.4851 15 16.5465 14.976 16.5946 14.9279C16.6426 14.8799 16.6666 14.8184 16.6666 14.7435V6.92313C16.6666 6.84826 16.6426 6.78681 16.5946 6.73875C16.5465 6.69069 16.4851 6.66667 16.4102 6.66667H9.3204L7.72602 5.07208C7.69922 5.04542 7.67116 5.02674 7.64185 5.01604C7.61241 5.00535 7.58165 5 7.54956 5H3.58977C3.51491 5 3.45345 5.02403 3.4054 5.07208C3.35734 5.12014 3.33331 5.1816 3.33331 5.25646V14.7435C3.33331 14.8184 3.35734 14.8799 3.4054 14.9279C3.45345 14.976 3.51491 15 3.58977 15ZM10.6635 11.4583L9.6281 12.4935C9.51283 12.609 9.4538 12.754 9.45102 12.9287C9.44838 13.1033 9.50741 13.251 9.6281 13.3719C9.74894 13.4926 9.89533 13.5529 10.0673 13.5529C10.2394 13.5529 10.3857 13.4926 10.5064 13.3719L12.5177 11.3606C12.6682 11.2099 12.7435 11.0342 12.7435 10.8333C12.7435 10.6325 12.6682 10.4567 12.5177 10.306L10.5064 8.29479C10.391 8.17951 10.246 8.12049 10.0712 8.11771C9.89665 8.11507 9.74894 8.1741 9.6281 8.29479C9.50741 8.41563 9.44706 8.56201 9.44706 8.73396C9.44706 8.90604 9.50741 9.05243 9.6281 9.17312L10.6635 10.2083H7.58019C7.40283 10.2083 7.25429 10.2682 7.13456 10.3879C7.01498 10.5075 6.95519 10.656 6.95519 10.8333C6.95519 11.0107 7.01498 11.1592 7.13456 11.2787C7.25429 11.3985 7.40283 11.4583 7.58019 11.4583H10.6635Z"
        fill="#777777"
      />
    </g>
  </svg>
)

export default IconMoveTo
