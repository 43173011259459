export const FolderIcon = () => {
  return (
    <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.48741 40C4.1403 40 3.00008 39.5333 2.06675 38.6C1.13341 37.6667 0.666748 36.5264 0.666748 35.1793V4.82067C0.666748 3.47356 1.13341 2.33333 2.06675 1.4C3.00008 0.466667 4.1403 0 5.48741 0H18.1334C18.7761 0 19.3941 0.124889 19.9874 0.374666C20.5803 0.624 21.0956 0.967557 21.5334 1.40533L25.4614 5.33333H46.5128C47.8599 5.33333 49.0001 5.8 49.9334 6.73333C50.8667 7.66667 51.3334 8.80689 51.3334 10.154V35.1793C51.3334 36.5264 50.8667 37.6667 49.9334 38.6C49.0001 39.5333 47.8599 40 46.5128 40H5.48741ZM5.48741 36H46.5128C46.7523 36 46.949 35.9231 47.1027 35.7693C47.2565 35.6156 47.3334 35.4189 47.3334 35.1793V10.154C47.3334 9.91444 47.2565 9.71778 47.1027 9.564C46.949 9.41022 46.7523 9.33333 46.5128 9.33333H23.8254L18.7234 4.23067C18.6376 4.14533 18.5479 4.08556 18.4541 4.05133C18.3599 4.01711 18.2614 4 18.1587 4H5.48741C5.24786 4 5.05119 4.07689 4.89742 4.23067C4.74364 4.38445 4.66675 4.58111 4.66675 4.82067V35.1793C4.66675 35.4189 4.74364 35.6156 4.89742 35.7693C5.05119 35.9231 5.24786 36 5.48741 36Z"
        fill="#777777"
      />
    </svg>
  )
}
