import { boolean, object, string } from 'yup'

export const schema = object({
  logo: string().required(),
  messageBadge: string().required(),
  primaryColor: string().required(),
  secondaryColor: string().required(),
  initialMessage: string().required().trim().max(115),
  subMessage: string().required().trim().max(223),
  portalAuth: boolean(),
})
