export const themeColors = {
  graphite: '#191919',
  darkGray: '#353535',
  gray: '#777',
  mediumGray: '#686868',
  disabledGray: '#909090',
  lightGray: '#dcdcdc',
  lightGrayBackground: '#f6f6f6',
  lighterGrayBackground: '#fbfbfb',
  white: '#fff',
  darkViolet: '#2a39ba',
  activeVioletHover: '#4521d4',
  accentViolet: '#5e36ff',
  lightVioletHover: '#dcd3ff',
  lightViolet: '#efebff',
  mainGreen: '#04d672',
  errorRed: '#e40f0f',
  errorRedLight: '#fce7e7',
}
