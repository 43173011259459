import { FC } from 'react'
import styles from './TechBotTest.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import CustomInput from 'components/customInput/CustomInput'
import { messages } from 'constants/messages'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'
import CustomLoader from 'components/customLoader/CustomLoader'
import BotPreview from '../botPreview/BotPreview'

const { testContainer, test, copyContainer, title, description, buttonContainer, preview } = styles

const { techBotTest } = messages.techbots

const TechBotTest: FC = () => {
  const user = useAppSelector((state: ReduxState) => state.user)
  const { data: bot, isLoading: isGettingBotData } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = bot?.id || ''

  const { data: newBot, isLoading } = api.useGetBotByIdQuery(
    { id: botId as string },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const linkToTestPage = `${window.location.origin}/demo/${bot?.id}`

  const handleOpenInNewTab = (): void => {
    window.open(linkToTestPage, '_blank')
  }

  const { data: dataSourcesData, isLoading: isGettingDataSources } = api.useGetSourceChangesQuery(
    { botId: botId! },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const anySourceIsProcessing = dataSourcesData?.some((source) => source.processing) || false

  const shouldRebuildKnowledge = dataSourcesData?.some((source) => source.type) || false

  let status = 'ready'

  if (anySourceIsProcessing) {
    status = 'updating'
  } else if (shouldRebuildKnowledge && !anySourceIsProcessing) {
    status = 'ready to update'
  } else if (!anySourceIsProcessing && !shouldRebuildKnowledge) {
    status = 'ready'
  }

  return (
    <>
      <div className={testContainer}>
        <div className={test}>
          <div className={copyContainer}>
            <h4 className={title}>{techBotTest.widgetReady.replace('{status}', status)}</h4>
            <p className={description}>{techBotTest.applyCode}</p>
            <CustomInput
              value={linkToTestPage}
              disabled
              onChangeValue={() => {}}
              placeholder={techBotTest.linkToTechbot}
            />
            <div className={buttonContainer}>
              <ActionButton
                label={techBotTest.openInNewTab}
                onPress={handleOpenInNewTab}
                buttonType={ActionButtonType.PRIMARY}
                size={ActionButtonSize.MEDIUM}
              />
            </div>
          </div>
        </div>
        <div className={preview}>
          <BotPreview
            name={newBot?.name}
            primaryColor={newBot?.color_primary || '#5E36FF'}
            secondaryColor={newBot?.color_secondary || '#FFFFFF'}
            avatar={newBot?.logo_url}
            widgetMessage={newBot?.widget_message}
            welcomeMessage={newBot?.welcome_message}
            offlineMessage={newBot?.offline_message}
          />
        </div>
      </div>
      {(isGettingBotData || isLoading || isGettingDataSources) && <CustomLoader />}
    </>
  )
}

export default TechBotTest
