import { Pagination, Stack, Box } from '@mui/material'
import { styled } from '@mui/system'
import { ChangeEvent } from 'react'

interface PaginationComponentProps {
  totalPages: number
  page: number
  handlePageChange: (event: ChangeEvent<unknown>, value: number) => void
}

const PaginationComponent = ({ totalPages, page, handlePageChange }: PaginationComponentProps) => {
  return (
    <Box sx={{ padding: '32px 0' }}>
      <CustomStack spacing={2}>
        <CustomPagination count={totalPages} color="primary" page={page} onChange={handlePageChange} />
      </CustomStack>
    </Box>
  )
}

const CustomStack = styled(Stack)(({ theme }) => ({
  '& .MuiPagination-root': {
    display: 'flex',
    justifyContent: 'end',
  },
}))

const CustomPagination = styled(Pagination)(({ theme }) => ({
  '& .Mui-selected': {
    backgroundColor: '#5e36ff !important',
  },
}))

export default PaginationComponent
