import { FC } from 'react'
import styles from './RadioSelector.module.scss'
import RadioInput from './RadioInput'

const { radioSelectorContainer, radioItem, radioItemTitle, radioItemDescription, separator, handoffInputContainer } =
  styles

interface Option {
  name: string
  description: string
  value: string
  childComponent?: JSX.Element
  radioDisabled?: boolean
}

interface RadioSelectorProps {
  options: Option[]
  currentValue: string
  setCuttentValue: (value: string) => void
}

const RadioSelector: FC<RadioSelectorProps> = ({ options, currentValue, setCuttentValue }) => {
  return (
    <div className={radioSelectorContainer}>
      {options.map((optionsItem) => {
        return (
          <div key={optionsItem.value} className={radioItem}>
            <div>
              <p className={radioItemTitle}>{optionsItem.name}</p>
              <p className={radioItemDescription}>{optionsItem.description}</p>
              {optionsItem.childComponent && (
                <>
                  <div className={separator} />
                  <div className={handoffInputContainer}>{optionsItem.childComponent}</div>
                </>
              )}
            </div>
            {optionsItem.radioDisabled !== true && (
              <RadioInput
                id={optionsItem.value}
                value={optionsItem.value}
                checked={currentValue === optionsItem.value}
                onChange={() => setCuttentValue(optionsItem.value)}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default RadioSelector
