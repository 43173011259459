import { combineReducers } from '@reduxjs/toolkit'

import botReducer from 'redux/bot/slice'
import modalReducer from 'redux/modal/slice'
import imagesReducer from 'redux/images/slice'
import { auth } from 'modules/authorization/redux/authorizationReducers'
import { user } from 'modules/user/redux/userReducers'
import { app } from 'redux/appReducer'
import { api } from './rtkQuery'
import { clearSession, deleteAllCookies } from 'utils/tools'
import { LOGOUT } from 'utils/constant'
import { userInteraction } from 'redux/userInteractionReducer'
import { subscription } from './subscriptionReducer'

const appReducer = combineReducers({
  bot: botReducer,
  modal: modalReducer,
  images: imagesReducer,
  auth,
  user,
  app,
  subscription,
  userInteraction,
  [api.reducerPath]: api.reducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    clearSession()
    deleteAllCookies()
    state = { app: state.app }
  }

  return appReducer(state, action)
}

export default rootReducer
