import { useDropzone } from 'react-dropzone'

import IconFolderUploader from 'assets/icons/iconFolderUploader'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { generateURL, ICustomFile } from 'utils/generateURL'
import { messages } from 'constants/messages'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { MAX_FILE_SIZE_512MB, supportedFileFormats, supportedImageFormats } from 'utils/constant'
import style from './Dropzone.module.scss'

const { dropzoneWrapper, dropzoneDisabled, content, dropzoneTitle } = style

export enum UPLOAD_TYPES {
  IMAGE = 'image',
  FILE = 'file',
}

interface IProps {
  uploadType: UPLOAD_TYPES
  setFiles: Dispatch<SetStateAction<ICustomFile[]>>
  isUploading?: boolean
}

const Dropzone = ({ uploadType, setFiles, isUploading }: IProps) => {
  const text = uploadType === UPLOAD_TYPES.IMAGE ? messages.images : messages.files

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filesWithPreview = generateURL(acceptedFiles)

      setFiles((prev: ICustomFile[]) => [...prev, ...filesWithPreview])
    },
    [setFiles]
  )

  const sizeValidator = (file: File) => {
    if (file.size > MAX_FILE_SIZE_512MB) {
      toastFuncError(text.fileError)
    }

    if (file.size === 0) {
      toastFuncError(text.unknownSize)
    }
    return null
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    validator: sizeValidator,
    accept: uploadType === UPLOAD_TYPES.IMAGE ? supportedImageFormats : supportedFileFormats,
    ...(uploadType === UPLOAD_TYPES.IMAGE && { maxSize: 20000000 }),
    disabled: isUploading,
  })

  return (
    <section className={`${dropzoneWrapper} ${isUploading && dropzoneDisabled}`}>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className={content}>
          <IconFolderUploader />
          <p className={dropzoneTitle}>{text.dragYourFileHere}</p>
        </div>
      </div>
    </section>
  )
}

export default Dropzone
