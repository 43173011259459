import { ReactNode, cloneElement, isValidElement } from 'react'
import { Box, Dialog, IconButton } from '@mui/material'
import IconClose from 'assets/icons/iconClose'

export enum MODAL_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface IProps {
  children: ReactNode & { props?: { onClose?: () => void } }
  size?: MODAL_SIZE
  onClose: () => void
  open: boolean
}

const Modal = ({ children, size = MODAL_SIZE.MEDIUM, onClose, open = false }: IProps) => {
  const maxWidth = size === MODAL_SIZE.SMALL ? '290px' : size === MODAL_SIZE.MEDIUM ? '524px' : '80%'
  const padding = size === MODAL_SIZE.SMALL ? '24px' : '40px'

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          maxWidth,
          width: '100%',
          padding,
          borderRadius: '24px',
          boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
          position: 'relative',
        },
        backdropFilter: 'blur(4px)',
      }}
    >
      {size !== MODAL_SIZE.SMALL && (
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: 'white',
            transition: 'background-color 0.3s',
            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          <IconClose />
        </IconButton>
      )}
      <Box> {isValidElement(children) ? cloneElement(children, { onClose }) : children}</Box>
    </Dialog>
  )
}

export default Modal
