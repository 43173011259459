import { FC } from 'react'

interface iIconSend {
  fillColor?: string
}

const IconSend: FC<iIconSend> = ({ fillColor = 'white' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2734_13514"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="29"
        height="29"
      >
        <path d="M14.2522 0.452957L0.49707 14.2285L14.2522 28.0041L28.0072 14.2285L14.2522 0.452957Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2734_13514)">
        <path
          d="M20.6532 8.99637L14.9876 22.9428C14.8603 23.2391 14.6415 23.4225 14.3302 23.4915C14.019 23.5613 13.7475 23.4801 13.5173 23.2496L5.24423 14.9642C5.01404 14.7337 4.93301 14.4618 5.00271 14.1501C5.07161 13.8384 5.25478 13.6192 5.55062 13.4918L19.4763 7.81777C19.8524 7.67165 20.179 7.73739 20.4562 8.015C20.7334 8.29261 20.799 8.61973 20.6532 8.99637ZM13.9428 21.5669L18.75 9.72368L6.92447 14.5381L9.51572 17.1332L14.2395 14.241L11.3515 18.9718L13.9428 21.5669Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default IconSend
