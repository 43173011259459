import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import IconArrowBack from 'assets/icons/iconArrowBack'
import { IconButton } from '@mui/material'

interface IOption {
  folderName: string
  path: string
}
interface IProps {
  options: { folderName: string; path: string }[]
  handleSetBreadcrumbs: (folderName: string, path: string) => void
}

const CustomBreadcrumbs = ({ options, handleSetBreadcrumbs }: IProps) => {
  const handleClick = ({ folderName, path }: IOption) => {
    handleSetBreadcrumbs(folderName, path)
  }

  const handleBackButton = () => {
    const lastOption = options[options.length - 2]
    handleSetBreadcrumbs(lastOption.folderName, lastOption.path)
  }

  const breadcrumbs = options.map((option, index) => (
    <Typography
      key={index}
      onClick={() => handleClick(option)}
      style={{
        padding: '8px',
        borderRadius: '12px',
        backgroundColor: `${index === options.length - 1 ? '#EFEBFF' : 'inherit'}`,
        fontSize: '16px',
        fontWeight: 'bold',
        color: `${index === options.length - 1 ? '#5E36FF' : 'inherit'}`,
        cursor: 'pointer',
      }}
    >
      {option.folderName}
    </Typography>
  ))

  return (
    <Stack
      spacing={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',

        '& nav': {
          marginTop: '0px !important',
        },
      }}
    >
      {options.length > 1 && (
        <IconButton
          aria-label="arrowback"
          onClick={handleBackButton}
          sx={{
            width: '38px',
            height: '38px',
            marginRight: '8px !important',
            backgroundColor: '#D9D9D9',
            borderRadius: '8px',
          }}
        >
          <IconArrowBack />
        </IconButton>
      )}

      <Breadcrumbs
        separator={
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            {'>'}
          </span>
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  )
}

export default CustomBreadcrumbs
