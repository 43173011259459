import { useEffect, useState } from 'react'
import DialogModal from 'components/dialogModal/DialogModal'
import { useAppSelector } from 'redux/hooks'
import { messages } from 'constants/messages'
import { ReduxState } from 'redux/store'
import { api } from 'redux/rtkQuery'

const { infoModal } = messages.modals

export const InfoModal = () => {
  const user = useAppSelector((state: ReduxState) => state.user)
  const companyId = user?.company_id
  const { data: bot, isLoading } = api.useGetCompanyBotQuery({
    companyId: companyId,
  })

  const [modalVisible, setModalVisible] = useState(false)
  const onHandleCloseModal = (): void => setModalVisible(false)

  useEffect(() => {
    if (!bot && !isLoading) {
      setModalVisible(true)
    }
  }, [bot, isLoading])

  return (
    <DialogModal
      isVisible={modalVisible}
      title={infoModal.title}
      message={infoModal.message}
      buttons={[
        {
          text: infoModal.continueButton,
        },
      ]}
      onClose={onHandleCloseModal}
    />
  )
}
