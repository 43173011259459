import { ReactNode, useState } from 'react'
import styles from './Tooltip.module.scss'
import IconHelp from 'assets/icons/iconHelp'

const { tooltipContainer, tooltipText } = styles

type TooltipProps = {
  content: ReactNode
}

const Tooltip = ({ content }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <div
      className={tooltipContainer}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <IconHelp />
      {showTooltip && <div className={tooltipText}>{content}</div>}
    </div>
  )
}

export default Tooltip
