import { useCallback, useEffect, useState } from 'react'
import styles from './Handoff.module.scss'
import { Note } from 'components/noteComponent/Note'
import RadioSelector from 'components/radioSelector/RadioSelector'
import CustomInput from 'components/customInput/CustomInput'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { messages } from 'constants/messages'
import { api } from 'redux/rtkQuery'
import CustomLoader from 'components/customLoader/CustomLoader'
import { HandoffType } from 'types/api'
import { isEmailValid } from 'helpers/validation'
import { IntegrationStatus } from 'modules/integrations/Integrations'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconCheckedSuccess from 'assets/icons/iconCheckedSuccess'
import { SETTINGS_INTEGRATION_URL } from 'utils/constant'

const { handoffContainer, handoffNoteContainer, separator } = styles

const { handoff } = messages

const Handoff = () => {
  const user = useAppSelector((state: ReduxState) => state.user)
  const [setShouldShowSaveButton, setReceivedFunction, setIsDisabled] = useOutletContext<any>()

  const { data: bot, isLoading } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user?.company_id, refetchOnMountOrArgChange: true }
  )

  const [handoffOption, setHandoffOption] = useState(bot?.handoffType || '')
  const [handoffEmail, setHandoffEmail] = useState(bot?.handoffEmail || '')

  useEffect(() => {
    setHandoffOption(bot?.handoffType || '')
    setHandoffEmail(bot?.handoffEmail || '')
  }, [bot?.handoffEmail, bot?.handoffType])

  const [updateHandoffMode] = api.useUpdateHandoffModeMutation()

  const botId = bot?.id || ''

  const onHandleSaveChanges = useCallback(() => {
    try {
      const body = {
        handoffType: handoffOption !== bot?.handoffType ? handoffOption : undefined,
        handoffEmail: handoffOption === 'email' && handoffEmail !== '' ? handoffEmail : undefined,
      }
      updateHandoffMode({
        id: botId,
        body: body,
      }).unwrap()
      toastFuncSuccess(handoff.handoffSaved)
    } catch {
      toastFuncSuccess(handoff.handoffError)
    }
  }, [handoffOption, bot?.handoffType, handoffEmail, updateHandoffMode, botId])

  useEffect(() => {
    setReceivedFunction(() => onHandleSaveChanges)
    return () => {
      setReceivedFunction(null)
    }
  }, [onHandleSaveChanges, setReceivedFunction])

  useEffect(() => {
    if (
      handoffOption !== bot?.handoffType ||
      (handoffEmail !== bot?.handoffEmail && handoffEmail !== '' && handoffOption === 'email')
    ) {
      setShouldShowSaveButton(true)
      setIsDisabled(false)
    }

    if (
      (handoffOption === HandoffType.EMAIL && !handoffEmail) ||
      (handoffOption === HandoffType.LIVE_CHAT && handoffEmail === bot?.handoffType) ||
      (!isEmailValid(handoffEmail) && handoffEmail !== '')
    ) {
      setShouldShowSaveButton(true)
      setIsDisabled(true)
    }

    return () => {
      setShouldShowSaveButton(false)
    }
  }, [setShouldShowSaveButton, handoffOption, handoffEmail, bot?.handoffEmail, bot?.handoffType, setIsDisabled])

  const { data: integrationData, isLoading: isGettingIntegrationDetails } = api.useGetIntegrationDetailsQuery(
    { botId: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const navigate = useNavigate()

  const HANDOFF_OBJECT = [
    {
      name: handoff.inbox,
      description: handoff.inboxDescription,
      value: HandoffType.INBOX,
    },
    {
      name: handoff.liveChat,
      description: handoff.liveChatDescription,
      value: HandoffType.LIVE_CHAT,
    },
    {
      name: handoff.toMail,
      description: handoff.toMailDescription,
      value: HandoffType.EMAIL,
      childComponent: (
        <CustomInput
          placeholder={handoff.emailPlaceholder}
          onChangeValue={setHandoffEmail}
          value={handoffEmail}
          textError={!isEmailValid(handoffEmail) && handoffEmail !== '' ? handoff.emailInvalid : handoff.emailError}
          isInvalid={
            (handoffOption === HandoffType.EMAIL && !handoffEmail) ||
            (!isEmailValid(handoffEmail) && handoffEmail !== '')
          }
        />
      ),
    },
    {
      name: handoff.toZendesk,
      description: handoff.toZendeskDescription,
      value: HandoffType.ZENDESK,
      childComponent:
        integrationData?.zendesk?.status !== IntegrationStatus.INSTALLED ? (
          <ActionButton
            label={handoff.connectZendesk}
            onPress={() => navigate(SETTINGS_INTEGRATION_URL)}
            size={ActionButtonSize.MEDIUM}
          />
        ) : (
          <ActionButton
            label={handoff.zendeskConnected}
            buttonType={ActionButtonType.SECONDARY}
            icon={<IconCheckedSuccess />}
            size={ActionButtonSize.MEDIUM}
          />
        ),
      radioDisabled: integrationData?.zendesk?.status !== IntegrationStatus.INSTALLED,
    },
  ]

  return (
    <div className={handoffContainer}>
      <div className={handoffNoteContainer}>
        <Note title={handoff.noteTitle} description={handoff.noteDescription} />
      </div>
      <div className={separator} />
      <RadioSelector currentValue={handoffOption} setCuttentValue={setHandoffOption} options={HANDOFF_OBJECT} />
      {(isLoading || isGettingIntegrationDetails) && <CustomLoader />}
    </div>
  )
}

export default Handoff
