import { Id, toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { TOAST_OFFLINE_ID, TOAST_RECONNECTING_ID, TOAST_REFRESH_BROWSER } from 'utils/constant'
import globalStyles from 'styles/colors.scss'
import IconClose from 'assets/icons/iconClose'

export const toastFuncSuccess: (text: string) => Id = (text: string) =>
  toast.success(text, {
    theme: 'colored',
    style: { backgroundColor: globalStyles.SUCCESS_TOAST_BACKGROUND },
  })

export const toastFuncError: (text: string) => Id = (text: string, props?: any) =>
  toast.error(text, {
    theme: 'colored',
    backgroundColor: globalStyles.ERROR_TOAST_BACKGROUND,
    ...props,
  })

export const toastFuncInfo: (text: string) => Id = (text: string, props?: any) =>
  toast.info(text, {
    theme: 'colored',
    style: { backgroundColor: globalStyles.INFO_TOAST_BACKGROUND },
    ...props,
  })

export const toastFuncNotification: (text: string) => Id = (text: string, props?: any) =>
  toast.info(text, {
    icon: false,
    closeButton: <IconClose fillColor={'#5E36FF'} />,
    theme: 'colored',
    style: { backgroundColor: '#EFEBFF', color: '#5E36FF', display: 'flex', alignItems: 'center' },
    ...props,
  })

export const toastOffline: (text: string) => Id = (translatedText: string) => {
  return toast.info(translatedText, {
    theme: 'colored',
    toastId: TOAST_OFFLINE_ID,
    closeOnClick: false,
    closeButton: <></>,
    autoClose: false,
    style: { backgroundColor: 'rgb(154,154,154)' },
  })
}

export const toastRefreshBrowser: (text: string) => Id = (translatedText: string) => {
  return toast.info(translatedText, {
    theme: 'colored',
    toastId: TOAST_REFRESH_BROWSER,
    closeOnClick: false,
    closeButton: <></>,
    autoClose: false,
    style: { backgroundColor: 'rgb(154,154,154)' },
  })
}

export const toastReconnecting: (text: string) => Id = (translatedText: string) => {
  return toast.loading(translatedText, {
    theme: 'colored',
    toastId: TOAST_RECONNECTING_ID,
    closeOnClick: false,
    closeButton: <></>,
    autoClose: false,
    style: {
      backgroundColor: globalStyles.INFO_TOAST_BACKGROUND,
      color: globalStyles.WHITE,
    },
  })
}

const CustomToastContainer: () => JSX.Element = () => {
  return (
    <ToastContainer
      style={{ lineHeight: '20px', marginTop: '50px' }}
      position="top-right"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}

export default CustomToastContainer
