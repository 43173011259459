import Dropzone, { UPLOAD_TYPES } from 'components/dropzone/Dropzone'
import styles from './UploadFileModalContent.module.scss'
import {
  toastFuncInfo,
  toastFuncNotification,
  toastFuncError,
} from 'components/customToastContainer/CustomToastContainer'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { api } from 'redux/rtkQuery'
import { useEffect, useState } from 'react'
import { ICustomFile } from 'utils/generateURL'

import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions, modalSelector } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import { PROGRESS_STATUS } from 'types/api'
import FileCard from 'components/fileCard/FileCard'
import IconAdd from 'assets/icons/iconAdd'

const {
  wrapper,
  modalTitle,
  description,
  uploaderProperties,
  uploadedTitle,
  modalFooter,
  divider,
  modalActionsWrapper,
  cardWrapper,
  formats,
} = styles

interface IProps {
  onClose?: () => void
  setFiles: (files: ICustomFile[]) => void
}

const UploadFileModalContent = ({ onClose, setFiles }: IProps) => {
  const { files: filesText } = messages
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const [files, setFilesState] = useState<ICustomFile[]>(modalContext?.files?.length ? modalContext.files : [])

  useEffect(() => {
    setFiles(files)
  }, [files, setFiles])

  const [uploadFile, { isLoading }] = api.useUploadFileMutation()
  const [deleteFile, { isLoading: loadingDeleteFile }] = api.useDeleteFileMutation()

  const handleUploadFiles = async () => {
    if (files.length === 0) return

    const filesToUpload = files.filter((file) => file.progressStatus !== PROGRESS_STATUS.UPLOADED)

    if (filesToUpload.length === 0) return

    const uploadPromises = filesToUpload.map(async (file, index) => {
      setFilesState((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index].progressStatus = PROGRESS_STATUS.UPLOADING
        return newFiles
      })

      try {
        const result = await uploadFile({
          botId: bot.id,
          file,
          folderPath: modalContext.path,
        })

        if ('error' in result) {
          setFilesState((prevFiles) => {
            const newFiles = [...prevFiles]
            newFiles[index].progressStatus = PROGRESS_STATUS.ERROR
            return newFiles
          })
          return { status: 'rejected', index, error: result.error }
        }

        setFilesState((prevFiles) => {
          const newFiles = [...prevFiles]
          newFiles[index].progressStatus = PROGRESS_STATUS.UPLOADED
          newFiles[index].id = result.data.fileId
          return newFiles
        })

        return { status: 'fulfilled', index, result }
      } catch (error) {
        setFilesState((prevFiles) => {
          const newFiles = [...prevFiles]
          newFiles[index].progressStatus = PROGRESS_STATUS.ERROR
          return newFiles
        })
        return { status: 'rejected', index, error }
      }
    })

    const results = await Promise.allSettled(uploadPromises)

    const existedFiles = results.filter((item) => {
      if (item.status === 'fulfilled' && 'value' in item) {
        const result = item.value as { result: { error?: { status: number } } }
        return result.result?.error?.status === 400
      }
      return false
    }).length

    const failedUploads = results.filter(
      (result) => result.status === 'fulfilled' && result.value.status === 'rejected'
    ).length

    if (failedUploads) {
      toastFuncError(
        `${failedUploads} ${failedUploads > 1 ? filesText.filesFailedToUpload : filesText.fileFailedToUpload}`
      )
    } else {
      dispatch(
        modalActions.setShowModal({
          modalType: MODAL_TYPES.UPLOAD_FILE,
        })
      )
    }

    if (existedFiles) {
      toastFuncInfo(`${existedFiles} ${existedFiles > 1 ? filesText.filesAlreadyExist : filesText.fileAlreadyExists}`)
    }

    if (files.length > existedFiles + failedUploads) {
      toastFuncNotification(
        `${files.length - (existedFiles + failedUploads)} ${files.length - (existedFiles + failedUploads) > 1 ? filesText.filesSuccessfullyAdded : filesText.fileSuccessfullyAdded}`
      )
    }
  }

  const handleCancelAction = () => {
    const isUploading = files.some((file) => file.progressStatus === PROGRESS_STATUS.UPLOADING)

    if (isUploading) {
      toastFuncInfo(filesText.closeModalError)
      return
    }
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.CANCEL_UPLOAD,
      })
    )
  }

  const handleDeleteFile = (preview: string) => {
    const newFiles = files.filter((file) => file.preview !== preview)
    setFiles(newFiles)
    setFilesState(newFiles)
  }

  const handleDeleteFileFromServer = async (id: string) => {
    await deleteFile({
      botId: bot.id,
      fileId: id,
    })

    const newFiles = files.filter((file) => file.id !== id)
    setFiles(newFiles)
    setFilesState(newFiles)
  }

  const handleReuploadFile = async (preview: string) => {
    const fileIndex = files.findIndex((file) => file.preview === preview)
    if (fileIndex === -1) return

    const file = files[fileIndex]

    setFilesState((prevFiles) => {
      const newFiles = [...prevFiles]
      newFiles[fileIndex].progressStatus = PROGRESS_STATUS.UPLOADING
      return newFiles
    })

    try {
      const result = await uploadFile({
        botId: bot.id,
        file,
      })

      if ('error' in result && 'data' in result.error) {
        setFilesState((prevFiles) => {
          const newFiles = [...prevFiles]
          newFiles[fileIndex].progressStatus = PROGRESS_STATUS.ERROR
          return newFiles
        })
        const errorData = result.error.data as { message: string }
        toastFuncError(errorData.message)
      }
      if ('data' in result) {
        setFilesState((prevFiles) => {
          const newFiles = [...prevFiles]
          newFiles[fileIndex].progressStatus = PROGRESS_STATUS.UPLOADED
          newFiles[fileIndex].id = result.data.fileId
          return newFiles
        })
      }
    } catch (error) {
      setFilesState((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[fileIndex].progressStatus = PROGRESS_STATUS.ERROR
        return newFiles
      })
    }
  }

  return (
    <div className={wrapper}>
      <p className={modalTitle}>{filesText.uploadFilesModalTitle}</p>
      <p className={description}>{filesText.uploadFilesModalDescription}</p>
      <Dropzone uploadType={UPLOAD_TYPES.FILE} setFiles={setFilesState} isUploading={isLoading} />
      <div className={uploaderProperties}>
        <p className={formats}>{filesText.supportedFormats}</p>
        <p>{filesText.maximumSize}</p>
      </div>
      {files.length > 0 && (
        <>
          <h3 className={uploadedTitle}>{filesText.uploadFilesModalTitle}</h3>
          <div className={cardWrapper}>
            {files.map((file) => (
              <FileCard
                key={file.preview.split('/').pop()}
                file={file}
                onDelete={handleDeleteFile}
                onDeleteFromServer={handleDeleteFileFromServer}
                onReupload={handleReuploadFile}
              />
            ))}
          </div>
        </>
      )}
      <div className={modalFooter}>
        <div className={divider} />
        <div className={modalActionsWrapper}>
          <ActionButton
            label={filesText.buttons.cancelUpload}
            buttonType={isLoading || loadingDeleteFile ? ActionButtonType.TRANSPARENT : ActionButtonType.SECONDARY}
            size={ActionButtonSize.MEDIUM}
            onPress={files.length > 0 ? handleCancelAction : onClose}
          />
          <ActionButton
            label={filesText.buttons.uploadFiles}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            loading={isLoading || loadingDeleteFile}
            disabled={isLoading}
            onPress={handleUploadFiles}
            icon={<IconAdd fillColor="#ffffff" />}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadFileModalContent
