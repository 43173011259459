import { FC } from 'react'

interface iIconChevronDown {
  fillColor?: string
  className?: string
}

const IconChevronDown: FC<iIconChevronDown> = ({ fillColor = '#686868', className = '' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Icon/Chevron/Left">
        <mask
          id="mask0_1043_4512"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" y="16" width="16" height="16" transform="rotate(-90 0 16)" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1043_4512)">
          <path
            id="arrow_back_ios_new"
            d="M13.9577 5.80091L8.42176 11.3471C8.35937 11.4095 8.29356 11.4535 8.22432 11.4791C8.1551 11.5048 8.08032 11.5176 7.99997 11.5176C7.91963 11.5176 7.84485 11.5048 7.77562 11.4791C7.70639 11.4535 7.64058 11.4095 7.57819 11.3471L2.02562 5.79451C1.89998 5.66887 1.83716 5.51502 1.83716 5.33298C1.83716 5.15093 1.90554 4.99153 2.04229 4.85478C2.17477 4.7223 2.33075 4.65606 2.51022 4.65606C2.68971 4.65606 2.8457 4.7223 2.97817 4.85478L7.99997 9.87658L13.0282 4.84838C13.1538 4.72273 13.306 4.65991 13.4846 4.65991C13.6632 4.65991 13.8209 4.72829 13.9577 4.86504C14.0901 4.99751 14.1564 5.15349 14.1564 5.33298C14.1564 5.51247 14.0901 5.66844 13.9577 5.80091Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconChevronDown
