import { useDropzone } from 'react-dropzone'
import { ReactNode, useCallback } from 'react'
import { generateURL } from 'utils/generateURL'
import { messages } from 'constants/messages'
import { toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'
import { MAX_FILE_SIZE_512MB, supportedFileFormats } from 'utils/constant'
import { Box } from '@mui/material'
import { themeColors } from 'styles/colors'

interface IProps {
  children: ReactNode
  currentPath: string
}

const DropzoneBox = ({ children, currentPath }: IProps) => {
  const dispatch = useDispatch()
  const text = messages.files

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filesWithPreview = generateURL(acceptedFiles)

      dispatch(
        modalActions.setShowModal({
          modalType: MODAL_TYPES.UPLOAD_FILE,
          files: filesWithPreview,
          path: currentPath,
        })
      )
    },
    [dispatch, currentPath]
  )

  const sizeValidator = (file: File) => {
    if (file.size > MAX_FILE_SIZE_512MB) {
      toastFuncError(text.fileError)
    }
    if (file.size === 0) {
      toastFuncError(text.unknownSize)
    }
    return null
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    validator: sizeValidator,
    accept: supportedFileFormats,
    noClick: true,
  })

  return (
    <Box
      component={'section'}
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: '12px',
        border: isDragActive ? `2px dashed ${themeColors.accentViolet}` : `2px dashed ${themeColors.white}`,
      }}
    >
      <Box
        sx={{
          height: '100%',
        }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        {children}
      </Box>
    </Box>
  )
}

export default DropzoneBox
