import { RefObject, useEffect } from 'react'

export function useClickOutside(
  ref: RefObject<HTMLElement> | null,
  callback: any,
  includeParent: boolean = false
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      // Do nothing if clicking ref's element or descendent elements
      // includeParent - check if clickOutside should do nothing on clicking in parent e.g. 3 dots icon
      if (ref?.current === null || ref?.current === undefined) return
      if (
        (ref?.current.contains(event.target as Node) ?? false) ||
        (includeParent &&
          ref?.current.parentElement != null &&
          ref?.current.parentElement.contains(event.target as Node))
      ) {
        return
      }
      callback(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, callback, includeParent])
}
