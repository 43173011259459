import { AppDispatch } from './store'

const initialState = {
  knowledgeGenerationNote: false,
  unpaidInvoiceNotification: false,
}

type SetKnowledgeGenerationAction = {
  type: typeof SET_KNOWLEDGE_GENERATION | typeof SET_UNPAID_INVOICE_NOTIFICATION
  payload: boolean
}

export const SET_KNOWLEDGE_GENERATION = 'SET_KNOWLEDGE_GENERATION'
export const SET_UNPAID_INVOICE_NOTIFICATION = 'SET_UNPAID_INVOICE_NOTIFICATION'

export const userInteraction = (state = initialState, action: SetKnowledgeGenerationAction) => {
  switch (action.type) {
    case SET_KNOWLEDGE_GENERATION:
      return {
        ...state,
        knowledgeGenerationNote: action.payload,
      }
    case SET_UNPAID_INVOICE_NOTIFICATION:
      return {
        ...state,
        unpaidInvoiceNotification: action.payload,
      }
    default:
      return state
  }
}

export const setKnowledgeGeneration = (payload: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_KNOWLEDGE_GENERATION,
    payload,
  })
}

export const setUnpaidInvoiceNotification = (payload: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_UNPAID_INVOICE_NOTIFICATION,
    payload,
  })
}
