import { useCallback, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import styles from './PortalCustomization.module.scss'
import CustomInput from 'components/customInput/CustomInput'
import UploadLogoInput from '../uploadLogoInput/UploadLogoInput'
import ColorPicker from '../colorPicker/ColorPicker'
import { toastFuncSuccess, toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { api } from 'redux/rtkQuery'
import { UpdateBotData } from 'types/api'
import { logger } from 'utils/logger'
import { messages } from 'constants/messages'
import { useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'
import PortalPreviewIcon from 'assets/icons/iconPortalPreview'
import { schema } from './schema'
import { yupResolver } from '@hookform/resolvers/yup'

const { portalCustomization } = messages.portal
const { container, content, formStyles, title, label, preview, separator } = styles

const PortalCustomization = () => {
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data, isLoading } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = data?.id || ''

  const { data: bot, isLoading: isGettingBot } = api.useGetBotByIdQuery(
    { id: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const {
    control,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      logo: bot?.portal_logo_url || '',
      messageBadge: bot?.portal_header_image_url || '',
      primaryColor: bot?.portal_primary_color || '#5E36FF',
      secondaryColor: bot?.portal_secondary_color || '#FFFFFF',
      initialMessage: bot?.portal_initial_message || '',
      subMessage: bot?.portal_sub_message || '',
      portalAuth: bot?.portal_auth || false,
    },
  })

  const { logo, primaryColor, secondaryColor, initialMessage, subMessage, portalAuth } = watch()

  const hasDataChanged =
    (bot?.portal_primary_color !== null && primaryColor !== bot?.portal_primary_color) ||
    (bot?.portal_secondary_color !== null && secondaryColor !== bot?.portal_secondary_color) ||
    initialMessage !== bot?.portal_initial_message ||
    subMessage !== bot?.portal_sub_message ||
    (portalAuth !== bot?.portal_auth && portalAuth !== null)

  const [setShouldShowSaveButton, setReceivedFunction, setIsDisabled] = useOutletContext<any>()

  useEffect(() => {
    if (bot) {
      setValue('logo', bot.portal_logo_url || '')
      setValue('messageBadge', bot.portal_header_image_url || '')
      setValue('primaryColor', bot.portal_primary_color || '#5E36FF')
      setValue('secondaryColor', bot.portal_secondary_color || '#FFFFFF')
      setValue('initialMessage', bot.portal_initial_message || '')
      setValue('subMessage', bot.portal_sub_message || '')
      setValue('portalAuth', bot.portal_auth || false)
    }
  }, [bot, setValue])

  const [updateBotById] = api.useUpdateBotByIdMutation()
  const [uploadPortalLogo, { isLoading: isUploadingLogo }] = api.useUploadPortalLogoMutation()
  const [uploadMessageBadgeLogo, { isLoading: isUploadingBadge }] = api.useUploadMessageBadgeLogoMutation()

  const saveBotData = useCallback(
    async (botId: string, data: UpdateBotData) => {
      try {
        const updateRes = await updateBotById({ id: botId, body: data })
        if (!('data' in updateRes)) {
          throw new Error('Save supportX data error')
        }
      } catch (error) {
        logger.error('saveBotData error', error)
        toastFuncError(
          error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Failed to save supportX data'
        )
        throw error
      }
    },
    [updateBotById]
  )

  const onHandleSaveChanges = useCallback(async () => {
    try {
      await saveBotData(botId, {
        portal_logo_url: getValues('logo'),
        portal_primary_color: getValues('primaryColor'),
        portal_secondary_color: getValues('secondaryColor'),
        portal_initial_message: getValues('initialMessage'),
        portal_sub_message: getValues('subMessage'),
        portal_auth: getValues('portalAuth'),
      })
      toastFuncSuccess(portalCustomization.saveChangesSuccess)
    } catch (error) {
      logger.error(`error`, error)
    }
  }, [botId, getValues, saveBotData])

  const handleFileUpload = async (file: File, type: 'logo' | 'messageBadge') => {
    if (!file) return
    try {
      if (type === 'logo') {
        await uploadPortalLogo({ id: botId, file }).unwrap()
        toastFuncSuccess(portalCustomization.uploadLogoSuccess)
      } else {
        await uploadMessageBadgeLogo({ id: botId, file }).unwrap()
        toastFuncSuccess(portalCustomization.uploadMessageBadgeSuccess)
      }
    } catch {
      toastFuncError(portalCustomization.uploadFailed)
    }
  }

  useEffect(() => {
    const isEmptyMessage = initialMessage.trim() === '' || subMessage.trim() === ''
    const isLimit = initialMessage.length === 115 || subMessage.length === 223

    setShouldShowSaveButton(hasDataChanged)
    setIsDisabled(isEmptyMessage || isLimit)
  }, [hasDataChanged, initialMessage, subMessage, setShouldShowSaveButton, setIsDisabled])

  useEffect(() => {
    setReceivedFunction(() => onHandleSaveChanges)
    return () => setReceivedFunction(null)
  }, [onHandleSaveChanges, setReceivedFunction])

  if (isLoading || !bot || isGettingBot) return <CustomLoader />

  return (
    <>
      <div className={container}>
        <div className={content}>
          <div className={formStyles}>
            <p className={label}>{portalCustomization.uploadWebsiteLogo}</p>
            <Controller
              name="logo"
              control={control}
              render={({ field }) => (
                <UploadLogoInput
                  htmlfor={portalCustomization.uploadWebsiteLogo}
                  logoPreview={field.value}
                  setLogoPreview={(url) => setValue('logo', url)}
                  onChangeLogo={(file) => handleFileUpload(file, 'logo')}
                  isUploading={isUploadingLogo}
                  required
                />
              )}
            />
            <p className={label}>{portalCustomization.uploadMessageBadge}</p>
            <Controller
              name="messageBadge"
              control={control}
              render={({ field }) => (
                <UploadLogoInput
                  htmlfor={portalCustomization.uploadMessageBadge}
                  logoPreview={field.value}
                  setLogoPreview={(url) => setValue('messageBadge', url)}
                  onChangeLogo={(file) => handleFileUpload(file, 'messageBadge')}
                  isUploading={isUploadingBadge}
                  required
                />
              )}
            />
            <p className={label}>{portalCustomization.addPrimaryBrandColors}</p>
            <Controller
              name="primaryColor"
              control={control}
              render={({ field }) => (
                <ColorPicker color={field.value} onChangeColor={(color) => setValue('primaryColor', color)} />
              )}
            />
            <p className={label}>{portalCustomization.addSecondaryBrandColors}</p>
            <Controller
              name="secondaryColor"
              control={control}
              render={({ field }) => (
                <ColorPicker color={field.value} onChangeColor={(color) => setValue('secondaryColor', color)} />
              )}
            />
            <h3 className={title}>{portalCustomization.customMessages}</h3>
            <Controller
              name="initialMessage"
              control={control}
              render={({ field }) => (
                <CustomInput
                  id="initial-message"
                  value={field.value}
                  onChangeValue={(val) => {
                    setValue('initialMessage', val)
                    trigger('initialMessage')
                  }}
                  labelVisible={true}
                  label={portalCustomization.initialMessage}
                  placeholder={portalCustomization.initialMessagePlaceholder}
                  isInvalid={!!errors.initialMessage}
                  textError={portalCustomization.initialMessageError}
                  onBlur={() => trigger('initialMessage')}
                  isRequired
                  multiline={true}
                  characterLimit={115}
                />
              )}
            />
            <div className={separator} />
            <Controller
              name="subMessage"
              control={control}
              render={({ field }) => (
                <CustomInput
                  id="sub-message"
                  value={field.value}
                  onChangeValue={(val) => {
                    setValue('subMessage', val)
                    trigger('subMessage')
                  }}
                  labelVisible={true}
                  label={portalCustomization.subMessage}
                  placeholder={portalCustomization.subMessagePlaceholder}
                  isInvalid={!!errors.subMessage}
                  textError={portalCustomization.subMessageError}
                  onBlur={() => trigger('subMessage')}
                  isRequired
                  multiline={true}
                  characterLimit={223}
                />
              )}
            />
          </div>
        </div>
        <div className={preview}>
          <PortalPreviewIcon
            initialMessage={
              initialMessage
                ? initialMessage.length > 60
                  ? `${initialMessage.slice(0, 60)}...`
                  : initialMessage
                : undefined
            }
            subMessage={
              subMessage ? (subMessage.length > 69 ? `${subMessage.slice(0, 69)}...` : subMessage) : undefined
            }
            primaryFillColor={primaryColor}
            secondaryFillColor={secondaryColor}
            image={logo}
          />
        </div>
      </div>
    </>
  )
}

export default PortalCustomization
