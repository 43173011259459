import styles from './EditImageModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { api } from 'redux/rtkQuery'
import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions, modalSelector } from 'redux/modal/slice'
import CustomInput from 'components/customInput/CustomInput'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import { imagesSelector } from 'redux/images/slice'
import { FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import formatFileSize from 'helpers/formatFileSize'

const {
  wrapper,
  modalTitle,
  modalFooter,
  divider,
  modalActionsWrapper,
  imageInfo,
  imageTitle,
  imageSize,
  textarea,
  foldersInfo,
  info,
  foldersSelector,
} = styles

interface IProps {
  onClose?: () => void
}

const EditImageModalContent = ({ onClose }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const imagesStored = useSelector(imagesSelector)
  const { categories } = imagesStored
  const [title, setTitle] = useState(modalContext.title)
  const [description, setDescription] = useState(modalContext.description)
  const [category, setCategory] = useState<string>(modalContext.category)

  const [updateImage, { isLoading }] = api.useUpdateImageMutation()

  const handleSetTitle = (value: string) => {
    setTitle(value)
  }

  const handleSetDescription = (value: string) => {
    setDescription(value)
  }

  const handleFolderChange = (value: string) => {
    setCategory(value)
  }

  const handleEditImages = async () => {
    await updateImage({
      botId: bot.id,
      imageId: modalContext.imageId,
      body: {
        title: title,
        description: description,
        category: category,
      },
    })
    toastFuncNotification(images.changeImageDataSuccess)

    dispatch(modalActions.setShowModal({ modalType: MODAL_TYPES.EDIT_IMAGE }))
  }

  return (
    <div className={wrapper}>
      <p className={modalTitle}>{images.editImageModalTitle}</p>
      <div className={imageInfo}>
        <img src={modalContext.s3Url} alt="preview" />
        <div>
          <p className={imageTitle}>{modalContext.fileName}</p>
          <p className={imageSize}>{formatFileSize(modalContext.fileSize)}</p>
        </div>
      </div>
      <CustomInput placeholder="Title" value={title} onChangeValue={handleSetTitle} />
      <div className={textarea}>
        <CustomInput placeholder="Description" multiline value={description} onChangeValue={handleSetDescription} />
      </div>
      {categories.length > 0 && (
        <div className={foldersInfo}>
          <div className={info}>
            <p>{images.folder}</p>
            <p>{images.addContentToSpecifiedFolder}</p>
          </div>
          <div className={foldersSelector}>
            <FormControl fullWidth>
              <InputLabel id="folder-select-label">{images.chooseFolder}</InputLabel>
              <CustomSelect
                labelId="folder-select-label"
                id="folder-select"
                value={category}
                label={images.chooseFolder}
                sx={{
                  borderRadius: '16px',
                }}
              >
                {categories.map((category: string) => (
                  <MenuItem key={category} value={category} onClick={() => handleFolderChange(category)}>
                    {category}
                  </MenuItem>
                ))}
              </CustomSelect>
            </FormControl>
          </div>
        </div>
      )}
      <div className={modalFooter}>
        <div className={divider} />
        <div className={modalActionsWrapper}>
          <ActionButton
            label={images.buttons.cancel}
            buttonType={ActionButtonType.SECONDARY}
            size={ActionButtonSize.MEDIUM}
            onPress={onClose}
          />
          <ActionButton
            label={images.buttons.saveChanges}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            loading={isLoading}
            disabled={isLoading}
            onPress={handleEditImages}
          />
        </div>
      </div>
    </div>
  )
}

export default EditImageModalContent

const CustomSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    borderRadius: '16px !important',
    padding: '12px',
  },
  '& .MuiList-root, .MuiList-padding, .MuiMenu-list': {
    borderRadius: '16px',
    paddingTop: 0,
    paddingBottom: 0,
  },
}))
