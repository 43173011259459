import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

export const getCurrentMoment = () => dayjs().toISOString();

export const getTodayDateStart = () => dayjs().startOf('day').toISOString();
export const getTodayDateEnd = () => dayjs().endOf('day').toISOString();

export const getYesterdayDateStart = () =>
  dayjs().subtract(1, 'days').startOf('day').toISOString();
export const getYesterdayDateEnd = () =>
  dayjs().subtract(1, 'days').endOf('day').toISOString();

export const getWeekDateStart = () => dayjs().startOf('isoWeek').toISOString();
export const getWeekDateEnd = () => dayjs().endOf('isoWeek').toISOString();

export const getMonthDateStart = () => dayjs().startOf('month').toISOString();
export const getMonthDateEnd = () => dayjs().endOf('month').toISOString();
