import styles from '../BotPreview.module.scss'
import IconSend from 'assets/icons/iconSend'
import IconClose from 'assets/icons/iconClose'
import { hexToRgba } from 'utils/tools'
import { messages } from 'constants/messages'

interface iInputForBrandInfo {
  primaryColor: string
  secondaryColor: string
}

const { inputContainer, sendButton, input, closeButton } = styles

const InputForBrandInfo = ({ primaryColor, secondaryColor }: iInputForBrandInfo): JSX.Element => (
  <div className={inputContainer}>
    <div className={input}>{messages.techbots.inputForBrandInfo.typeMessage}</div>
    <div className={sendButton} style={{ backgroundColor: primaryColor, color: secondaryColor }}>
      <IconSend fillColor={secondaryColor} />
    </div>
    <div className={closeButton} style={{ backgroundColor: hexToRgba(primaryColor, '0.05') }}>
      <IconClose fillColor={primaryColor} />
    </div>
  </div>
)

export default InputForBrandInfo
