export const SXObject = {
  '.MuiSwitch-thumb': {
    backgroundColor: '#fff',
    height: '20px',
    width: '20px',
    marginTop: '5px',
    opacity: '1',
  },
  '.MuiSwitch-track': {
    backgroundColor: '#DCDCDC',
    height: '24px',
    borderRadius: '100px',
    opacity: '1 !important',
  },
  '.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#5e36ff !important',
    opacity: '1 !important',
  },
  '.MuiSwitch-switchBase': {
    left: '6px',
  },
  '.Mui-checked': {
    transform: 'translateX(20px)',
  },
  width: 70,
  height: 48,
};
