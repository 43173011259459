import { useSelector } from 'react-redux'
import styles from './ImagePreviewModalContent.module.scss'
import { modalSelector } from 'redux/modal/slice'

const { wrapper } = styles

const ImagePreviewModalContent = () => {
  const { modalContext } = useSelector(modalSelector)

  return (
    <div className={wrapper}>
      <img src={modalContext.s3Url} alt="preview" />
    </div>
  )
}

export default ImagePreviewModalContent
