import { FC } from 'react'

const IconChatBubblePointer: FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L12 12L11.9488 0H0Z" fill="white" />
    </svg>
  )
}

export default IconChatBubblePointer
