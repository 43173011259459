import styles from './DetailsItem.module.scss'
import { iDetailsContent, iDetailsItemProps } from './DetailsItem.types'

const {
  detailsContentConainer,
  detailsContentHeader,
  detailsContentHeaderTitle,
  detailsContentDataConainer,
  detailsContentDataLabel,
  detailsContentDataContent,
  detailsContentDataContentLength,
  detailsContentDataContentDescription,
} = styles

const DetailsItem = ({ title, button, detailsContent }: iDetailsItemProps) => {
  return (
    <div className={detailsContentConainer}>
      <div className={detailsContentHeader}>
        <div className={detailsContentHeaderTitle}>{title}</div>
        {button}
      </div>
      {detailsContent?.map((item: iDetailsContent, index: number) => (
        <div className={detailsContentDataConainer} key={index}>
          <div className={detailsContentDataLabel}>{item.label}</div>
          <div className={detailsContentDataContent}>
            {item.icon}
            {item.value !== 0 && <div className={detailsContentDataContentLength}>{item.value}</div>}

            <div className={detailsContentDataContentDescription}>{`${
              item.value === 0 ? 'No ' : ''
            } ${item.description}`}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default DetailsItem
