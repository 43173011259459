interface IProps {
  fillColor?: string
}

const IconFolderOpen = ({ fillColor = '#686868' }: IProps) => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1879_15794"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="65"
      height="64"
    >
      <rect x="0.450195" width="64" height="64" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_1879_15794)">
      <path
        d="M11.7835 52C10.488 52 9.3862 51.5462 8.4782 50.6387C7.57064 49.7307 7.11687 48.6289 7.11687 47.3333V16.8207C7.11687 15.5251 7.59642 14.3978 8.55553 13.4387C9.51465 12.4796 10.642 12 11.9375 12H24.5835C25.2262 12 25.8442 12.1249 26.4375 12.3747C27.0304 12.624 27.5458 12.9676 27.9835 13.4053L31.9115 17.3333H55.5529C56.1204 17.3333 56.5955 17.5249 56.9782 17.908C57.3613 18.2907 57.5529 18.7658 57.5529 19.3333C57.5529 19.9009 57.3613 20.376 56.9782 20.7587C56.5955 21.1418 56.1204 21.3333 55.5529 21.3333H30.2755L24.9422 16H11.9375C11.698 16 11.5013 16.0769 11.3475 16.2307C11.1938 16.3844 11.1169 16.5811 11.1169 16.8207V48C11.1169 47.7604 11.164 47.6707 11.2582 47.7307C11.352 47.7907 11.4758 47.8633 11.6295 47.9487L16.8195 30.6207C17.1409 29.5847 17.7322 28.7522 18.5935 28.1233C19.4553 27.494 20.4126 27.1793 21.4655 27.1793H55.7629C57.3971 27.1793 58.6938 27.8289 59.6529 29.128C60.6115 30.4271 60.8635 31.8442 60.4089 33.3793L55.8142 48.6873C55.5098 49.6887 54.9398 50.4911 54.1042 51.0947C53.2682 51.6982 52.3409 52 51.3222 52H11.7835ZM15.8195 48H51.2195C51.4075 48 51.5742 47.9487 51.7195 47.846C51.8649 47.7433 51.9631 47.598 52.0142 47.41L56.5582 32.2307C56.6435 31.9573 56.6006 31.7138 56.4295 31.5C56.2589 31.2862 56.0366 31.1793 55.7629 31.1793H21.4655C21.2775 31.1793 21.1109 31.2307 20.9655 31.3333C20.8202 31.436 20.722 31.5813 20.6709 31.7693L15.8195 48ZM15.8195 48L20.6709 31.7693C20.722 31.5813 20.7646 31.436 20.7989 31.3333L20.8502 31.1793L20.7602 31.5C20.7006 31.7138 20.628 31.9573 20.5422 32.2307L15.9989 47.41C15.9478 47.598 15.9051 47.7433 15.8709 47.846L15.8195 48Z"
        fill={fillColor}
      />
    </g>
  </svg>
)

export default IconFolderOpen
