import { FC } from 'react'

interface iIconOptions {
  fillColor?: string
}

const IconOptions: FC<iIconOptions> = ({ fillColor = 'white' }) => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2607_9497"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="21"
      >
        <path d="M20.2837 0.621094H0.831055V20.1027H20.2837V0.621094Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2607_9497)">
        <path
          d="M10.5576 16.2621C10.2236 16.2621 9.93673 16.1428 9.69925 15.9042C9.46095 15.6663 9.3418 15.3798 9.3418 15.0445C9.3418 14.7101 9.46095 14.4228 9.69925 14.1849C9.93673 13.9463 10.2236 13.827 10.5576 13.827C10.8915 13.827 11.1784 13.9463 11.4159 14.1849C11.6542 14.4228 11.7734 14.7101 11.7734 15.0445C11.7734 15.3798 11.6542 15.6663 11.4159 15.9042C11.1784 16.1428 10.8915 16.2621 10.5576 16.2621ZM10.5576 11.5793C10.2236 11.5793 9.93673 11.4599 9.69925 11.2213C9.46095 10.983 9.3418 10.6964 9.3418 10.3616C9.3418 10.0267 9.46095 9.74012 9.69925 9.50164C9.93673 9.26323 10.2236 9.14399 10.5576 9.14399C10.8915 9.14399 11.1784 9.26323 11.4159 9.50164C11.6542 9.74012 11.7734 10.0267 11.7734 10.3616C11.7734 10.6964 11.6542 10.983 11.4159 11.2213C11.1784 11.4599 10.8915 11.5793 10.5576 11.5793ZM10.5576 6.89605C10.2236 6.89605 9.93673 6.77689 9.69925 6.53841C9.46095 6.3 9.3418 6.01338 9.3418 5.67854C9.3418 5.3437 9.46095 5.05708 9.69925 4.81859C9.93673 4.58019 10.2236 4.46094 10.5576 4.46094C10.8915 4.46094 11.1784 4.58019 11.4159 4.81859C11.6542 5.05708 11.7734 5.3437 11.7734 5.67854C11.7734 6.01338 11.6542 6.3 11.4159 6.53841C11.1784 6.77689 10.8915 6.89605 10.5576 6.89605Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default IconOptions
