const IconArrowBack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <mask id="mask0_1585_10775" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g>
      <path
        d="M11.0962 19.0887L4.64039 12.6329C4.54681 12.5393 4.48079 12.4406 4.44232 12.3367C4.40387 12.2329 4.38464 12.1207 4.38464 12.0002C4.38464 11.8797 4.40387 11.7675 4.44232 11.6637C4.48079 11.5598 4.54681 11.4611 4.64039 11.3675L11.1058 4.90217C11.2442 4.7637 11.4141 4.6919 11.6154 4.68677C11.8167 4.68165 11.9949 4.75665 12.15 4.91177C12.3051 5.05664 12.3852 5.22971 12.3904 5.43099C12.3955 5.63226 12.3205 5.81046 12.1654 5.96559L6.88072 11.2502H18.4884C18.7013 11.2502 18.8795 11.322 19.023 11.4656C19.1666 11.6092 19.2384 11.7874 19.2384 12.0002C19.2384 12.213 19.1666 12.3912 19.023 12.5348C18.8795 12.6784 18.7013 12.7502 18.4884 12.7502H6.88072L12.175 18.0445C12.3134 18.1829 12.3852 18.3553 12.3904 18.5617C12.3955 18.7682 12.3205 18.9438 12.1654 19.0887C12.0205 19.2438 11.8449 19.3213 11.6385 19.3213C11.4321 19.3213 11.2513 19.2438 11.0962 19.0887Z"
        fill="#777777"
      />
    </g>
  </svg>
)

export default IconArrowBack
