import styles from './Note.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconClose from 'assets/icons/iconClose'

interface iNote {
  title?: string
  description: string
  buttonLabel?: string
  onHandleClose?: () => void
}

const { noteContainer, noteTitle, noteDescription, noteCloseButton } = styles

export const Note = ({ title = 'Note:', description, buttonLabel, onHandleClose }: iNote) => (
  <div className={noteContainer}>
    {onHandleClose && (
      <div className={noteCloseButton} onClick={onHandleClose}>
        <IconClose />
      </div>
    )}
    <div className={noteTitle}>{title}</div>
    <div className={noteDescription} dangerouslySetInnerHTML={{ __html: description }} />
    {buttonLabel && (
      <ActionButton label={buttonLabel} buttonType={ActionButtonType.PRIMARY} size={ActionButtonSize.MEDIUM} />
    )}
  </div>
)
