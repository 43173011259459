import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGetImagesResponse } from 'types/api'

export interface IImagesState {
  images: IGetImagesResponse[]
  categories: string[]
  createdCategories: string[]
}

const initialState: IImagesState = {
  images: [],
  categories: [],
  createdCategories: [],
}

const imagesSlice = createSlice({
  name: 'IMAGES',
  initialState,
  reducers: {
    setImagesAndCategories: (state, action: PayloadAction<{ images: IGetImagesResponse[] }>) => {
      state.images = action.payload.images
      state.categories = Array.from(
        new Set(
          [...state.createdCategories, ...action.payload.images.map((image) => image.category)].filter(
            (category) => category
          )
        )
      )
    },
    createFolder: (state, action: PayloadAction<{ folderName: string }>) => {
      state.createdCategories.push(action.payload.folderName)
      state.categories = Array.from(
        new Set([action.payload.folderName, ...state.categories].filter((category) => category))
      )
    },
    renameFolder: (state, action: PayloadAction<{ oldFolderName: string; newFolderName: string }>) => {
      state.createdCategories = state.createdCategories.map((category) =>
        category === action.payload.oldFolderName ? action.payload.newFolderName : category
      )
      state.categories = Array.from(new Set([...state.createdCategories, ...state.categories]))
    },
    deleteFolder: (state, action: PayloadAction<{ folderName: string }>) => {
      state.createdCategories = state.createdCategories.filter((category) => category !== action.payload.folderName)
      state.categories = Array.from(new Set([...state.createdCategories, ...state.categories]))
    },
  },
})

export const imagesActions = imagesSlice.actions

// After switching to slices completely, the : any type needs to be removed
export const imagesSelector = (state: any) => state.images

export default imagesSlice.reducer
