import { messages } from 'constants/messages'
import styles from './dataTable/DataTable.module.scss'
import IconFolderOpen from 'assets/icons/iconFolderOpen'

const { files } = messages

export type EmptyFolderViewProps = {
  handleSetIsUploadFileModalOpen: () => void
}

export const EmptyFolderView = ({ handleSetIsUploadFileModalOpen }: EmptyFolderViewProps) => {
  return (
    <div className={styles.listUploadButtonWrapper}>
      <div onClick={handleSetIsUploadFileModalOpen} className={styles.imageWrapper}>
        <IconFolderOpen fillColor="#5E36FF" />
      </div>
      <p>{files.allUploadedFilesWillShowUpHere}</p>
    </div>
  )
}
