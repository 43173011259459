import CustomInput from 'components/customInput/CustomInput'
import styles from './RenameFileFolderModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useState } from 'react'
import { messages } from 'constants/messages'
import { useSelector } from 'react-redux'
import { modalSelector } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { api } from 'redux/rtkQuery'

const { wrapper, title } = styles

interface IProps {
  onClose?: () => void
}

const RenameFileFolderModalContent = ({ onClose }: IProps) => {
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const [name, setName] = useState(modalContext?.fileId ? modalContext.fileName : modalContext.folderName)
  const { files } = messages

  const [renameFolder, { isLoading }] = api.useRenameFolderMutation()
  const [renameFile, { isLoading: isRenameFileLoading }] = api.useRenameFileMutation()

  const handleSetName = (value: string) => {
    setName(value)
  }

  const handleRename = async () => {
    if (modalContext?.fileId) {
      try {
        await renameFile({
          botId: bot.id,
          newName: name,
          folderPath: `${modalContext.path}/`,
          fileId: modalContext.fileId,
        })
      } catch (error) {
        console.error(error)
      }

      if (onClose) onClose()
      return
    }

    try {
      await renameFolder({
        botId: bot.id,
        newName: name,
        folderPath: `/${modalContext.folderId}`,
      })
    } catch (error) {
      console.error(error)
    }

    if (onClose) onClose()
  }

  return (
    <div className={wrapper}>
      <p className={title}>{modalContext?.fileId ? files.renameFileModalTitle : files.renameFolderModalTitle}</p>
      <div>
        <CustomInput
          placeholder={modalContext?.fileId ? files.fileName : files.folderName}
          value={name}
          onChangeValue={handleSetName}
        />
      </div>
      <ActionButton
        label={files.buttons.renameFolder}
        loading={isLoading || isRenameFileLoading}
        buttonType={ActionButtonType.PRIMARY}
        size={ActionButtonSize.MEDIUM}
        onPress={handleRename}
      />
      <ActionButton
        label={files.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default RenameFileFolderModalContent
