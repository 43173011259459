import { FC, MouseEvent } from 'react'
import styles from './DialogModal.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'

interface iButton {
  text: string
  onPress?: () => void
  style?: string
}
interface iDialogModal {
  isVisible: boolean
  topIcon?: JSX.Element
  title: string
  message?: string
  buttons?: iButton[]
  onClose: () => unknown
}

const { buttonsGroup, buttonsSeparator, container, contentStyles, titleStyles, messageStyles, topIconStyles } = styles

const DialogModal: FC<iDialogModal> = ({ isVisible, topIcon, title, message, buttons, onClose }) => {
  const isTitle = typeof title === 'string'
  const isMessage = typeof message === 'string'
  const isButton = Array.isArray(buttons) && buttons.length > 0

  const onHandleClose = (e: MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    onClose()
  }

  const onHandleClickContent = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  const onHandlePressButton = (event: any): void => {
    if (typeof event.onPress === 'function') {
      event?.onPress()
    }
    onClose()
  }

  const renderButtons = (): JSX.Element => (
    <div className={buttonsGroup}>
      {isButton &&
        buttons.map((button: iButton, index: number) => {
          let buttonType =
            typeof button.style === 'string' && button.style === 'cancel'
              ? ActionButtonType.TERTIARY
              : ActionButtonType.PRIMARY

          button.style === 'warning' && (buttonType = ActionButtonType.WARNING)
          return (
            <div key={`dialog_button_${index}`}>
              <ActionButton
                label={button.text}
                onPress={() => onHandlePressButton(button)}
                buttonType={buttonType}
                size={ActionButtonSize.MEDIUM_WIDE}
              />
              {index !== buttons.length - 1 && <div className={buttonsSeparator} />}
            </div>
          )
        })}
    </div>
  )

  return isVisible ? (
    <div className={container} onClick={onHandleClose}>
      <div className={contentStyles} onClick={onHandleClickContent}>
        {topIcon && <div className={topIconStyles}>{topIcon}</div>}
        {isTitle ? <div className={titleStyles}>{title}</div> : null}
        {isMessage ? <div className={messageStyles}>{message}</div> : null}
        {isButton && renderButtons()}
      </div>
    </div>
  ) : null
}

export default DialogModal
