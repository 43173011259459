import { KnowledgeSource } from 'types/api';

export const suggestedByMapper = (author: KnowledgeSource) => {
  switch (author) {
    case KnowledgeSource.Agent:
      return 'Agent';
    case KnowledgeSource.GPT:
      return 'AI';
    case KnowledgeSource.Zendesk:
      return 'Zendesk';
    default:
      return '';
  }
};

export const KNOWLEDGE_TAG = 'Knowledge';
