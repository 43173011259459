import { FC } from 'react'
import styles from '../../TechBotDataSources.module.scss'
import { messages } from 'constants/messages'

const { dataSourcesDetail, dataSourcesDetailValue, dataSourcesDetailCount, dataSourcesDetailCountAdded } = styles

const { techBotDataSources } = messages.techbots

type DataSourceDetailProps = {
  totalCount: number
  name: string
  added?: number
  crawled?: number
  synced?: number
  isLinks?: boolean
}

export const DataSourceDetail: FC<DataSourceDetailProps> = ({ totalCount, name, added, crawled, synced, isLinks }) => {
  return (
    <div className={dataSourcesDetail}>
      <div className={dataSourcesDetailValue}>
        {totalCount} {name}
      </div>
      <div className={dataSourcesDetailCount}>
        {synced !== undefined ? (
          <p>
            {synced} {techBotDataSources.synced}
          </p>
        ) : isLinks !== undefined ? (
          <p>{techBotDataSources.ofAddedWwebsites.replace('{added}', String(added ?? ''))}</p>
        ) : (
          <>
            <p className={dataSourcesDetailCountAdded}>
              {added} {techBotDataSources.added}
            </p>
            <p>
              {crawled} {techBotDataSources.crawled}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
