import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UPLOADED_DATA_TYPES } from 'types/api'
import { ICustomFile } from 'utils/generateURL'

export enum MODAL_TYPES {
  CANCEL_UPLOAD = 'CANCEL_UPLOAD',
  DELETE_FILE = 'DELETE_FILE',
  NEW_FOLDER = 'NEW_FOLDER',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  EDIT_IMAGE = 'EDIT_IMAGE',
  IMAGE_PREVIEW = 'IMAGE_PREVIEW',
  RENAME_FOLDER = 'RENAME_FOLDER',
  RENAME_FILE_FOLDER = 'RENAME_FILE_FOLDER',
  DELETE_FOLDER = 'DELETE_FOLDER',
  UPLOAD_FILE = 'UPLOAD_FILE',
  NEW_FILE_FOLDER = 'NEW_FILE_FOLDER',
}

export interface IModalState {
  [MODAL_TYPES.CANCEL_UPLOAD]: boolean
  [MODAL_TYPES.DELETE_FILE]: boolean
  [MODAL_TYPES.NEW_FOLDER]: boolean
  [MODAL_TYPES.UPLOAD_IMAGE]: boolean
  [MODAL_TYPES.EDIT_IMAGE]: boolean
  [MODAL_TYPES.IMAGE_PREVIEW]: boolean
  [MODAL_TYPES.RENAME_FOLDER]: boolean
  [MODAL_TYPES.RENAME_FILE_FOLDER]: boolean
  [MODAL_TYPES.DELETE_FOLDER]: boolean
  [MODAL_TYPES.UPLOAD_FILE]: boolean
  [MODAL_TYPES.NEW_FILE_FOLDER]: boolean
  modalContext: {
    fileId: string
    imageId: string
    fileName: string
    title: string
    description: string
    s3Url: string
    fileSize: number
    status: string
    category: string
    dataType: UPLOADED_DATA_TYPES | ''
    folderId?: string
    folderName?: string
    path?: string
    files?: ICustomFile[]
  }
}

const initialState: IModalState = {
  [MODAL_TYPES.CANCEL_UPLOAD]: false,
  [MODAL_TYPES.DELETE_FILE]: false,
  [MODAL_TYPES.NEW_FOLDER]: false,
  [MODAL_TYPES.UPLOAD_IMAGE]: false,
  [MODAL_TYPES.EDIT_IMAGE]: false,
  [MODAL_TYPES.IMAGE_PREVIEW]: false,
  [MODAL_TYPES.RENAME_FOLDER]: false,
  [MODAL_TYPES.RENAME_FILE_FOLDER]: false,
  [MODAL_TYPES.DELETE_FOLDER]: false,
  [MODAL_TYPES.UPLOAD_FILE]: false,
  [MODAL_TYPES.NEW_FILE_FOLDER]: false,
  modalContext: {
    fileId: '',
    imageId: '',
    fileName: '',
    title: '',
    description: '',
    s3Url: '',
    fileSize: 0,
    status: '',
    category: '',
    dataType: '',
    folderId: '',
    folderName: '',
    path: '',
    files: [],
  },
}

const modalSlice = createSlice({
  name: 'MODAL',
  initialState,
  reducers: {
    setShowModal: (
      state,
      action: PayloadAction<{
        modalType: MODAL_TYPES
        fileId?: string
        imageId?: string
        fileName?: string
        title?: string
        description?: string
        s3Url?: string
        fileSize?: number
        status?: string
        category?: string
        dataType?: UPLOADED_DATA_TYPES
        folderId?: string
        folderName?: string
        path?: string
        files?: ICustomFile[]
      }>
    ) => {
      const isCloseModal = !state[action.payload.modalType]

      if (!isCloseModal) {
        state.modalContext.fileId = ''
        state.modalContext.imageId = ''
        state.modalContext.fileName = ''
        state.modalContext.title = ''
        state.modalContext.description = ''
        state.modalContext.s3Url = ''
        state.modalContext.fileSize = 0
        state.modalContext.status = ''
        state.modalContext.category = ''
        state.modalContext.dataType = ''
        state.modalContext.folderId = ''
        state.modalContext.folderName = ''
        state.modalContext.path = ''
        state.modalContext.files = []
      } else {
        state.modalContext.fileId = action.payload.fileId || ''
        state.modalContext.imageId = action.payload.imageId || ''
        state.modalContext.fileName = action.payload.fileName || ''
        state.modalContext.title = action.payload.title || ''
        state.modalContext.description = action.payload.description || ''
        state.modalContext.s3Url = action.payload.s3Url || ''
        state.modalContext.fileSize = action.payload.fileSize || 0
        state.modalContext.status = action.payload.status || ''
        state.modalContext.category = action.payload.category || ''
        state.modalContext.dataType = action.payload.dataType || ''
        state.modalContext.folderId = action.payload.folderId || ''
        state.modalContext.folderName = action.payload.folderName || ''
        state.modalContext.path = action.payload.path || ''
        state.modalContext.files = action.payload.files || []
      }

      state[action.payload.modalType] = !state[action.payload.modalType]
    },
    closeAllModals: (state) => {
      Object.keys(state).forEach((key) => {
        if (key !== 'modalContext') {
          state[key as MODAL_TYPES] = false
        }
      })
      state.modalContext.fileId = ''
      state.modalContext.imageId = ''
      state.modalContext.fileName = ''
      state.modalContext.title = ''
      state.modalContext.description = ''
      state.modalContext.s3Url = ''
      state.modalContext.fileSize = 0
      state.modalContext.status = ''
      state.modalContext.category = ''
      state.modalContext.dataType = ''
      state.modalContext.folderId = ''
      state.modalContext.folderName = ''
      state.modalContext.path = ''
      state.modalContext.files = []
    },
  },
})

export const modalActions = modalSlice.actions

// After switching to slices completely, the : any type needs to be removed
export const modalSelector = (state: any) => state.modal

export default modalSlice.reducer
