import { AnyAction } from '@reduxjs/toolkit'
import { SignUpStep } from 'utils/constant'

const initialState = {
  step: SignUpStep.EMAIL,
  userName: null,
  user: null,
}

export const SET_REGISTER_STEP = 'SET_REGISTER_STEP'

export const SET_EXIST_USERNAME = 'SET_EXIST_USERNAME'
export const SET_REGISTER_USER = 'SET_REGISTER_USER'

export const CLEAR_AUTH = 'CLEAR_AUTH'

export const auth = (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case SET_REGISTER_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case SET_EXIST_USERNAME:
      return {
        ...state,
        userName: action.payload,
      }
    case SET_REGISTER_USER:
      return {
        ...state,
        user: action.payload,
      }
    case CLEAR_AUTH:
      return initialState
    default:
      return state
  }
}
