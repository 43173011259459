import { useState } from 'react'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useAppSelector } from 'redux/hooks'
import { api } from 'redux/rtkQuery'
import { ReduxState } from 'redux/store'
import { KnowledgeBody, KnowledgeSource, ParsedKnowledge } from 'types/api'
import styles from './Knowledge.module.scss'
import CustomLoader from 'components/customLoader/CustomLoader'
import GeneratedKnowledgeTable from '../components/generatedKnowledgeTable/GeneratedKnowledgeTable'
import { messages } from 'constants/messages'
import AddContentModal from '../components/addContentModal/AddContentModal'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'

const { knowledgeContainer, knowledgeHeader, knowledgeHeaderContent, buttonContainer } = styles

const { knowledgeMessages } = messages

const Knowledge = () => {
  const [isAddingContentModalVisible, setIsAddingContentModalVisible] = useState(false)
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data: bot, isLoading: isGettingCompanyBot } = api.useGetCompanyBotQuery(
    {
      companyId: user?.company_id,
    },
    { refetchOnMountOrArgChange: true }
  )

  const botId = bot?.id || ''

  const { data: knowledgeData, isLoading: isLoadingKnowledge } = api.useGetKnowledgeQuery(
    {
      id: botId,
    },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const entriesLength = knowledgeData?.knowledges.filter(
    (knowledge: ParsedKnowledge) =>
      !(
        (knowledge.generated_by === KnowledgeSource.GPT && !knowledge.approved_by) ||
        knowledge.generated_by === KnowledgeSource.Zendesk
      )
  ).length

  const [postKnowledge, { isLoading: isLoadingPostKnowledge }] = api.usePostKnowledgeMutation()

  const handleAddNewContent = async (props: KnowledgeBody) => {
    try {
      await postKnowledge({ id: botId, body: props }).unwrap()
      toastFuncNotification(knowledgeMessages.addContentSuccessToast.replace('{company_name}', user.company_name))
    } catch {
      toastFuncError(knowledgeMessages.errorToast)
    }
  }

  return (
    <>
      {(isGettingCompanyBot || isLoadingKnowledge) && <CustomLoader />}
      <div className={knowledgeContainer}>
        <div className={knowledgeHeader}>
          <p className={knowledgeHeaderContent}>
            {entriesLength} {knowledgeMessages.entries}
          </p>
          <div className={buttonContainer}>
            <ActionButton
              label={knowledgeMessages.addContent}
              buttonType={ActionButtonType.SECONDARY}
              size={ActionButtonSize.MEDIUM}
              onPress={() => setIsAddingContentModalVisible(true)}
              loading={isLoadingPostKnowledge}
            />
          </div>
        </div>
        <GeneratedKnowledgeTable />
        <AddContentModal
          isVisible={isAddingContentModalVisible}
          title={knowledgeMessages.addContent}
          message={knowledgeMessages.addContentModal.message}
          onContinue={handleAddNewContent}
          onClose={() => setIsAddingContentModalVisible(false)}
        />
      </div>
    </>
  )
}

export default Knowledge
