import DropdownMenu from 'components/dropdownMenu/DropdownMenu'
import DropdownFileContent from './dropdownFileContent/DropdownFileContent'
import { IUploadedFile } from 'types/api'

export const FileActionsMenu = ({
  currentPath,
  file,
  handleMoveTo,
}: {
  currentPath: string
  file: IUploadedFile
  handleMoveTo: (file: IUploadedFile) => void
}) => {
  return (
    <DropdownMenu>
      <DropdownFileContent currentPath={currentPath} file={file} handleMoveTo={handleMoveTo} />
    </DropdownMenu>
  )
}
