import { PROGRESS_STATUS } from 'types/api'
import styles from './ProgressBar.module.scss'
import { LinearProgress } from '@mui/material'

interface IProps {
  status: PROGRESS_STATUS
}

const ProgressBar = ({ status }: IProps) => {
  return (
    <div className={styles.progressBarContainer}>
      {status === PROGRESS_STATUS.UPLOADING && (
        <LinearProgress
          sx={{
            height: 2,
            bgcolor: '#f3f3f3',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#5e36ff',
            },
          }}
        />
      )}
      {status === PROGRESS_STATUS.UPLOADED && <div className={styles.progressBarUploaded}></div>}
      {status === PROGRESS_STATUS.ERROR && <div className={styles.progressBarError}></div>}
    </div>
  )
}

export default ProgressBar
