import { Fragment } from 'react'
import IconDelete from 'assets/icons/iconDelete'
import styles from './ListOfSources.module.scss'
import { ParsedResource, ParsedResourceLink, ResourceType } from 'types/api'
import IconLink from 'assets/icons/iconLink'
import IconAttachment from 'assets/icons/iconAttachment'
import IconDownload from 'assets/icons/iconDownload'
import IconLoader from 'assets/icons/loader/iconLoader'

type ListOfSourcesProps<T> = {
  data: T[]
  label: string
  renderItem: (item: T) => JSX.Element
}

type OptimisticLink = {
  url: string
}

type OptimisticFile = {
  name: string
}

const { headers, container, itemStyles, nameContainer, buttonsGroup, buttonIcon, buttonDelete, loadingButton } = styles

export const LinkItem = ({
  item,
  onRemove,
}: {
  item: ParsedResourceLink | OptimisticLink
  onRemove: (item: ParsedResourceLink) => unknown
}) => (
  <div className={itemStyles}>
    <div className={nameContainer}>
      <IconLink />
      <p>{item.url}</p>
    </div>
    <div className={buttonsGroup}>
      {'bot_id' in item ? (
        <>
          <div className={`${buttonIcon} ${buttonDelete}`} onClick={() => onRemove(item)}>
            <IconDelete />
          </div>
        </>
      ) : (
        <>
          <div className={`${loadingButton}`}>
            <IconLoader fillColor="#686868" />
          </div>
        </>
      )}
    </div>
  </div>
)

export const FileItem = ({
  item,
  onRemove,
  onDownload,
}: {
  item: ParsedResource | OptimisticFile
  onRemove: (item: ParsedResource) => unknown
  onDownload: (id: string) => unknown
}) => (
  <div className={itemStyles}>
    <div className={nameContainer}>
      <IconAttachment />
      <p>{item.name}</p>
    </div>
    {'bot_id' in item ? (
      <div className={buttonsGroup}>
        {item.type === ResourceType.FILE && (
          <button className={`${buttonIcon} ${buttonDelete}`} onClick={() => onRemove(item)}>
            <IconDelete />
          </button>
        )}
        <button className={`${buttonIcon} ${buttonDelete}`} onClick={() => onDownload(item.id)}>
          <IconDownload />
        </button>
      </div>
    ) : (
      <>
        <div className={`${loadingButton}`}>
          <IconLoader fillColor="#686868" />
        </div>
      </>
    )}
  </div>
)

const ListOfSources = <T,>({ data, renderItem, label }: ListOfSourcesProps<T>) => {
  if (data.length === 0) {
    return null
  }

  return (
    <>
      <div className={headers}>
        <p>{label}</p>
      </div>
      <div className={container}>
        {data.map((item, index) => (
          <Fragment key={index}>{renderItem(item)}</Fragment>
        ))}
      </div>
    </>
  )
}

export default ListOfSources
