export const findLatestDate = (dates: (string | undefined)[] | undefined) => {
  const dateObjects = dates
    ?.map((dateString) => (dateString ? new Date(dateString).getTime() : undefined))
    .filter((timestamp): timestamp is number => timestamp !== undefined)

  if (dateObjects && dateObjects.length > 0) {
    const latestTimestamp = Math.max(...dateObjects)

    return new Date(latestTimestamp).toISOString()
  }

  return undefined
}
