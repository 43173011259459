import { IUploadedFile, IUploadedFolder } from 'types/api'

import styles from './DataTable.module.scss'
import { useState } from 'react'
import { Breadcrumbs } from '../Files'
import { MoveTo } from '../MoveTo'
import { FileActionsMenu } from '../FileActionsMenu'
import { messages } from 'constants/messages'
import formatFileSize from 'helpers/formatFileSize'

const { tdPreview, grayText, cellMore } = styles

interface IProps {
  file: IUploadedFile
  breadcrumbs: Breadcrumbs
  pathOfBreadcrumbs: string
}

const FileTr = ({ file, breadcrumbs, pathOfBreadcrumbs }: IProps) => {
  const text = messages.files

  const dateObj = new Date(file.created_at)
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObj.getUTCFullYear()
  const formattedDate = `${day}/${month}/${year}`

  const [selectedMoveToFile, setSelectedMoveToFile] = useState<IUploadedFile | IUploadedFolder | null>(null)
  const handleMoveTo = (file: IUploadedFile) => {
    setSelectedMoveToFile(file)
  }

  return (
    <>
      {selectedMoveToFile && (
        <MoveTo selectedEntry={selectedMoveToFile} setSelectedEntry={setSelectedMoveToFile} currentPath={breadcrumbs} />
      )}
      {file && (
        <tr key={file.id}>
          <td className={tdPreview}>
            <div>
              <a href={file.download_url} target="_blank" rel="noreferrer">
                <span>.{file.name.split('.').pop()}</span>
              </a>
            </div>
          </td>
          <td>
            <p>{file.name}</p>
            <p className={grayText}>{file.size === 0 ? text.unknownSize : formatFileSize(file.size)}</p>
          </td>
          <td>{formattedDate}</td>
          <td>{file.name.split('.').pop()}</td>
          <td>{file.created_by}</td>
          <td className={cellMore}>
            <FileActionsMenu file={file} handleMoveTo={handleMoveTo} currentPath={pathOfBreadcrumbs} />
          </td>
        </tr>
      )}
    </>
  )
}

export default FileTr
