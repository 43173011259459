import { FC } from 'react'

interface iIconPicture {
  fillColor?: string
}

const IconPicture: FC<iIconPicture> = ({ fillColor = '#686868' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Picture">
        <mask
          id="mask0_2_1436"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2_1436)">
          <path
            id="photo_library"
            d="M12.9422 12.8077L11.7346 11.3077C11.641 11.1974 11.5204 11.1439 11.373 11.1471C11.2256 11.1503 11.1083 11.2071 11.0211 11.3173L9.64418 13.1231C9.52368 13.2705 9.50445 13.4279 9.5865 13.5952C9.66855 13.7625 9.80508 13.8461 9.9961 13.8461H17.5037C17.6948 13.8461 17.8329 13.7625 17.9182 13.5952C18.0034 13.4279 17.9858 13.2705 17.8653 13.1231L15.6807 10.2365C15.5871 10.1224 15.4666 10.0638 15.3192 10.0606C15.1717 10.0574 15.0512 10.116 14.9576 10.2365L12.9422 12.8077ZM8.05765 17.5C7.55252 17.5 7.12496 17.325 6.77498 16.975C6.42498 16.625 6.24998 16.1974 6.24998 15.6923V4.3077C6.24998 3.80257 6.42498 3.375 6.77498 3.025C7.12496 2.675 7.55252 2.5 8.05765 2.5H19.4422C19.9473 2.5 20.3749 2.675 20.7249 3.025C21.0749 3.375 21.2499 3.80257 21.2499 4.3077V15.6923C21.2499 16.1974 21.0749 16.625 20.7249 16.975C20.3749 17.325 19.9473 17.5 19.4422 17.5H8.05765ZM8.05765 16H19.4422C19.5191 16 19.5897 15.9679 19.6538 15.9038C19.7179 15.8397 19.7499 15.7692 19.7499 15.6923V4.3077C19.7499 4.23077 19.7179 4.16024 19.6538 4.09613C19.5897 4.03203 19.5191 3.99998 19.4422 3.99998H8.05765C7.98072 3.99998 7.9102 4.03203 7.8461 4.09613C7.78198 4.16024 7.74993 4.23077 7.74993 4.3077V15.6923C7.74993 15.7692 7.78198 15.8397 7.8461 15.9038C7.9102 15.9679 7.98072 16 8.05765 16ZM4.55768 20.9999C4.05256 20.9999 3.625 20.8249 3.275 20.4749C2.925 20.1249 2.75 19.6973 2.75 19.1922V7.05768C2.75 6.84486 2.82179 6.66666 2.96537 6.52308C3.10897 6.37949 3.28718 6.3077 3.49998 6.3077C3.71279 6.3077 3.891 6.37949 4.0346 6.52308C4.17818 6.66666 4.24998 6.84486 4.24998 7.05768V19.1922C4.24998 19.2692 4.28202 19.3397 4.34613 19.4038C4.41024 19.4679 4.48076 19.5 4.55768 19.5H16.6922C16.905 19.5 17.0832 19.5717 17.2268 19.7153C17.3704 19.8589 17.4422 20.0371 17.4422 20.2499C17.4422 20.4627 17.3704 20.6409 17.2268 20.7845C17.0832 20.9281 16.905 20.9999 16.6922 20.9999H4.55768Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconPicture
