import { useState } from 'react'
import styles from './AuthCredentialsModal.module.scss'
import IconClose from 'assets/icons/iconClose'
import CustomInput from 'components/customInput/CustomInput'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import { GENERAL_ERROR_MESSAGE } from 'utils/constant'
import { api } from 'redux/rtkQuery'
import { ReduxState } from 'redux/store'
import { useAppSelector } from 'redux/hooks'
import { messages } from 'constants/messages'

const {
  authCredentialsModalContainer,
  authCredentialsModalContent,
  authCredentialsHeaderContainer,
  authCredentialsHeaderTitle,
  authCredentialsCloseIcon,
  authCredentialsStep,
  authCredentialsInputContainer,
  authCredentialsButtonContainer,
} = styles

const { stepThree } = messages.integrations.zendesk

type AuthCredentialsProps = {
  onClose: () => void
  setIntegrationStep: (step: number) => void
}

const AuthCredentialsModal = ({ onClose, setIntegrationStep }: AuthCredentialsProps) => {
  const [authCredentials, setAuthCredentials] = useState({
    zendesk_key: '',
    zendesk_username: '',
    zendesk_subdomain: '',
  })

  const [zendeskSubdomainError, setZendeskSubdomainError] = useState(false)

  const user = useAppSelector((state: ReduxState) => state.user)
  const { data: bot } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user?.company_id, refetchOnMountOrArgChange: true }
  )

  const botId = bot?.id || ''

  const [postZendeskCredentials, { isLoading }] = api.usePostZendeskCredentialsMutation()

  const handleAuthCredentials = async () => {
    try {
      await postZendeskCredentials({
        botId: botId,
        body: authCredentials,
      }).unwrap()
      toastFuncSuccess(stepThree.integrationSuccess)
      onClose()
    } catch {
      toastFuncError(GENERAL_ERROR_MESSAGE)
    }
  }

  return (
    <div className={authCredentialsModalContainer}>
      <div className={authCredentialsModalContent}>
        <div className={authCredentialsHeaderContainer}>
          <div className={authCredentialsHeaderTitle}>{stepThree.createAuthentication}</div>
          <div className={authCredentialsCloseIcon} onClick={onClose}>
            <IconClose />
          </div>
        </div>
        <div className={authCredentialsStep}>{stepThree.step}</div>
        <div className={authCredentialsInputContainer}>
          <CustomInput
            value={authCredentials.zendesk_subdomain}
            onChangeValue={(value: string) => {
              if (value.includes('.zendesk') || value.includes('http')) {
                setZendeskSubdomainError(true)
              } else {
                setZendeskSubdomainError(false)
              }
              setAuthCredentials({
                ...authCredentials,
                zendesk_subdomain: value,
              })
            }}
            placeholder={stepThree.subdomain}
            isInvalid={zendeskSubdomainError}
            inputSuffix={stepThree.subdomainSuffix}
            textError={stepThree.subdomainError}
            fullWidth
            disabled={isLoading}
          />
          <CustomInput
            value={authCredentials.zendesk_username}
            onChangeValue={(value: string) => {
              setAuthCredentials({
                ...authCredentials,
                zendesk_username: value,
              })
            }}
            placeholder={stepThree.username}
            fullWidth
            disabled={isLoading}
          />
          <CustomInput
            value={authCredentials.zendesk_key}
            onChangeValue={(value: string) => {
              setAuthCredentials({
                ...authCredentials,
                zendesk_key: value,
              })
            }}
            placeholder={stepThree.key}
            fullWidth
            disabled={isLoading}
          />
        </div>
        <div className={authCredentialsButtonContainer}>
          <ActionButton
            label={stepThree.back}
            buttonType={ActionButtonType.TERTIARY}
            size={ActionButtonSize.MEDIUM}
            onPress={() => setIntegrationStep(2)}
          />
          <ActionButton
            label={stepThree.newAuthentication}
            size={ActionButtonSize.MEDIUM}
            onPress={handleAuthCredentials}
            loading={isLoading}
            disabled={
              !authCredentials.zendesk_key ||
              !authCredentials.zendesk_username ||
              !authCredentials.zendesk_subdomain ||
              zendeskSubdomainError
            }
          />
        </div>
      </div>
    </div>
  )
}

export default AuthCredentialsModal
