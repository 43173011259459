import { FC, useEffect, useRef, useState, MutableRefObject, MouseEvent } from 'react'
import styles from './ColorPicker.module.scss'
import IconColorDropper from 'assets/icons/iconColorDropper'
import { ChromePicker } from 'react-color'
import { useClickOutside } from 'helpers/useClickOutside'
import { messages } from 'constants/messages'

interface iColorPicker {
  color: string
  onChangeColor: (color: string) => void
}

const { container, row, previewWrapper, preview, content, info, subinfo, pickerStyles } = styles

const ColorPicker: FC<iColorPicker> = ({ color, onChangeColor }) => {
  const [currentColor, setCurrentColor] = useState(color)

  useEffect(() => {
    setCurrentColor(color)
  }, [color])

  const [pickerVisible, setPickerVisible] = useState(false)

  const ref = useRef() as MutableRefObject<HTMLInputElement>
  useClickOutside(ref, () => setPickerVisible(false), true)

  const onTogglePicker = (e: MouseEvent<HTMLDivElement>): void => {
    setPickerVisible((prevState) => !prevState)
  }

  const onHandleChangeColor = (color: any, event: any): void => {
    setCurrentColor(color.hex)
  }

  const onHandleCompleteChangeColor = (color: any, event: any): void => {
    if (typeof onChangeColor === 'function') {
      onChangeColor(color.hex)
    }
  }

  const onClosePicker = (): void => {
    if (pickerVisible) {
      setPickerVisible(false)
    }
  }

  return (
    <div className={container}>
      <div className={row}>
        <div className={previewWrapper} ref={ref}>
          <div className={preview} style={{ backgroundColor: currentColor }} onClick={onTogglePicker}>
            <IconColorDropper />
          </div>
          {pickerVisible && (
            <div className={`${pickerStyles}`}>
              <ChromePicker
                disableAlpha={true}
                color={currentColor}
                onChangeComplete={onHandleCompleteChangeColor}
                onChange={onHandleChangeColor}
              />
            </div>
          )}
        </div>
        <div className={content} onClick={onClosePicker}>
          <p className={info}>{messages.techbots.colorPicker.selectColor}</p>
          <p className={subinfo}>{messages.techbots.colorPicker.subinfo}</p>
        </div>
      </div>
    </div>
  )
}

export default ColorPicker
