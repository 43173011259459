import { Divider, Menu, MenuItem, styled } from '@mui/material'
import IconDelete from 'assets/icons/iconDelete'
import IconEdit from 'assets/icons/iconEdit'
import IconMoveTo from 'assets/icons/iconMoveTo'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'

import style from './DropdownImageContent.module.scss'
import { messages } from 'constants/messages'
import { useState } from 'react'
import { imagesSelector } from 'redux/images/slice'
import { api } from 'redux/rtkQuery'
import { botSelector } from 'redux/bot/slice'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'

const { dropdownContent } = style

interface IProps {
  imageId: string
  fileName: string
  title: string
  description: string
  s3Url: string
  fileSize: number
  status: string
  category: string
}

const DropdownImageContent = ({ imageId, fileName, title, description, s3Url, fileSize, status, category }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const imagesStored = useSelector(imagesSelector)
  const bot = useSelector(botSelector)
  const { categories } = imagesStored
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState<null | HTMLElement>(null)

  const [updateImage] = api.useUpdateImageMutation()

  const handleOpenEditImageModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.EDIT_IMAGE,
        imageId,
        fileName,
        title,
        description,
        s3Url,
        fileSize,
        status,
        category,
      })
    )
  }

  const handleOpenDeleteImageModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FILE,
        imageId,
        fileName,
      })
    )
  }

  const handleOpenSubMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget)
  }

  const handleUpdateCategory = async (value: string) => {
    await updateImage({
      botId: bot.id,
      imageId,
      body: {
        category: value,
      },
    })
    toastFuncNotification(images.changeFolderSuccess)
  }

  return (
    <div className={dropdownContent}>
      <MenuItem onClick={handleOpenEditImageModal}>
        <IconEdit fillColor="#777777" /> {images.editImageData}
      </MenuItem>
      <Divider style={{ margin: 0 }} />
      <MenuItem onClick={handleOpenSubMenu} id="subMenu">
        <IconMoveTo /> {images.moveTo}
      </MenuItem>
      <Divider style={{ margin: 0 }} />
      <MenuItem onClick={handleOpenDeleteImageModal}>
        <IconDelete fillColor="red" /> {images.deleteFile}
      </MenuItem>
      {categories?.length > 0 && (
        <CustomMenu
          id="submenu"
          anchorEl={submenuAnchorEl}
          open={Boolean(submenuAnchorEl)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 365,
          }}
        >
          {categories.map((category: string) => (
            <MenuItem
              key={category}
              onClick={() => handleUpdateCategory(category)}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {category}
            </MenuItem>
          ))}
        </CustomMenu>
      )}
    </div>
  )
}

export default DropdownImageContent

const CustomMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '190px',
    borderRadius: '16px',
  },
  '& ul': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))
