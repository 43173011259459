export const TOKEN_KEY = 'cfb0e5f2662e0e06114d84eda091db6433dd0d0a1d55a56fd3368f0b66e8a3c0'
export const TRANSLATIONS_KEY = 'translationsKey'
export const INITIALIZE_KEY = 'initialize'
export const ID_KEY = 'idKey'
export const SCROLL_MARGIN_IN_PIXELS = 20

export const COUNTRIES = [
  {
    name: 'United States',
    callingCode: '+1',
    code: 'US',
  },
  {
    name: 'Canada',
    callingCode: '+1',
    code: 'CA',
  },
]

export const PIN_LENGTH_VERIFY_SMS_CODE = 6
export const BACKSPACE_KEY = 'Backspace'
export const KEY_ENTER = 13

/* TOAST */
export const TOAST_OFFLINE_ID = 'offlineToast'
export const TOAST_RECONNECTING_ID = 'reconnectingToast'
export const TOAST_REFRESH_BROWSER = 'refreshBrowserToast'

/* CONST ERRORS */
export const GENERAL_ERROR_MESSAGE = 'Oops, something went wrong! Try again or contact our support.'

// TODO: move to enum or object
/* URL */
export const PREFIX_URL = '/admin'

/* MENU URL */
export const ROOT_URL = `${PREFIX_URL}/dashboard`
export const BOTS_URL = `${PREFIX_URL}/bots`
export const CONVERSATIONS_URL = `${PREFIX_URL}/conversations`
export const SETUP_URL = `${PREFIX_URL}/setup`
export const PORTAL_PREVIEW_URL = `${SETUP_URL}/portal/preview`
export const PORTAL_CUSTOMIZE_URL = `${SETUP_URL}/portal/customize`
export const WIDGET_PLAYGROUND_URL = `${SETUP_URL}/widget/playground`
export const WIDGET_CUSTOMIZE_URL = `${SETUP_URL}/widget/customize`
export const WIDGET_CONFIGURATION_URL = `${SETUP_URL}/widget/configuration`
export const WIDGET_INSTALLATION_URL = `${SETUP_URL}/widget/installation`
export const DATA_SOURCES_URL = `${PREFIX_URL}/data-sources`
export const DATA_SOURCES_WEBSITES_URL = `${DATA_SOURCES_URL}/websites`
export const DATA_SOURCES_FILES_URL = `${DATA_SOURCES_URL}/files`
export const DATA_SOURCES_SNIPPETS_URL = `${DATA_SOURCES_URL}/snippets`
export const DATA_SOURCES_SELF_LEARNING_URL = `${DATA_SOURCES_URL}/self-learning`
export const DATA_SOURCES_IMAGES_URL = `${DATA_SOURCES_URL}/images`
export const SETTINGS_HANDOFF_URL = `${SETUP_URL}/settings/handoff`
export const SETTINGS_INTEGRATION_URL = `${SETUP_URL}/settings/integration`
export const SETTINGS_URL = `${PREFIX_URL}/settings`
export const PROFILE_URL = `${SETTINGS_URL}/profile`
export const BUY_SUBSCRIPTION_URL = `${PREFIX_URL}/subscription`
export const SUBSCRIPTION_URL = `${SETTINGS_URL}/subscription`
export const CONTACT_SALES_FORM_URL = `${PREFIX_URL}/contact-sales`
export const TEAM_MANAGEMENT_URL = `${SETTINGS_URL}/team-management`
export const PLAYGROUND_URL = `${PREFIX_URL}/playground`

/* SIGN UP - URL */
export const SIGN_UP_URL = `/sign-up`
export const LOGIN_URL = '/login'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const RESET_PASSWORD_URL = '/reset-password'

/* INVITE URLs */
export const INVITE_URL = '/invite'

export enum SignUpStep {
  EMAIL = 'email',
  PHONE_NUMBER = 'phone',
  VERIFICATION = 'verification',
  NEW_USER = 'new',
}

/* SUBSCRIPTION */
export enum SUBSCRIPTION_PLANS {
  FREE = 'free',
  MONTH = 'month',
  YEAR = 'year',
}

// TODO: move to enum
export const NEW_SUBSCRIPTION_STEP_SELECT_PLAN = 'select'
export const NEW_SUBSCRIPTION_STEP_CHECKOUT = 'checkout'
export const NEW_SUBSCRIPTION_STEP_SUCCESS = 'success'

export const NEW_SUBSCRIPTION_STEPS = [
  {
    id: NEW_SUBSCRIPTION_STEP_SELECT_PLAN,
    label: 'Select Plan',
    step: 0,
  },
  {
    id: NEW_SUBSCRIPTION_STEP_CHECKOUT,
    label: 'Set Payment Method',
    step: 1,
  },
]

/* CREATE NEW TECH BOT */

export enum NEW_TECHBOT_STEP {
  INTRODUCE = 'introduce',
  EDIT_CONFIGURATION = 'edit-configuration',
  DATA_SOURCE = 'data-source',
  EDIT_DATA_SOURCES = 'edit-data-sources',
  BUILD = 'build',
  TEST = 'test',
  DEPLOY = 'deploy',
}

export const NEW_TECHBOT_CREATE_STEPS = [
  {
    id: NEW_TECHBOT_STEP.INTRODUCE,
    label: 'Introduce brand',
    step: 0,
  },
  {
    id: NEW_TECHBOT_STEP.DATA_SOURCE,
    label: 'Data Source',
    step: 1,
  },
  {
    id: NEW_TECHBOT_STEP.TEST,
    label: 'Test',
    step: 2,
  },
  {
    id: NEW_TECHBOT_STEP.DEPLOY,
    label: 'Deploy',
    step: 3,
  },
]

export const EDIT_TECHBOT_CREATE_STEPS = [
  {
    id: NEW_TECHBOT_STEP.EDIT_CONFIGURATION,
    label: 'Edit Configuration',
    step: 0,
  },
]

/* LOGOUT */
export const LOGOUT = 'LOGOUT'

export const supportedImageFormats = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpeg'],
  'image/jpg': ['.jpg'],
  'image/webp': ['.webp'],
}

export const supportedFileFormats = {
  'text/x-c': ['.c'],
  'text/x-c++': ['.cpp'],
  'text/css': ['.css'],
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/html': ['.html'],
  'text/x-java': ['.java'],
  'text/javascript': ['.js'],
  'application/json': ['.json'],
  'text/markdown': ['.md'],
  'application/pdf': ['.pdf'],
  'text/x-php': ['.php'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'text/x-python': ['.py'],
  'text/x-script.python': ['.py'],
  'text/x-ruby': ['.rb'],
  'text/x-tex': ['.tex'],
  'application/typescript': ['.ts'],
  'text/plain': ['.txt'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'text/xml': ['.xml'],
}

export const MAX_FILE_SIZE_512MB = 536870912
