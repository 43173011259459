import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { TOKEN_KEY, LOGOUT } from 'utils/constant'
import { readFromStorage, saveInStorage } from 'utils/storage'
import {
  Auth,
  Company,
  DashboardData,
  Message,
  TechBot,
  UpdateBotData,
  User,
  Knowledge,
  KnowledgeBody,
  ParsedSourceChange,
  Product,
  Subscription,
  Card,
  PaymentHistoryType,
  PaymentIntentResponseType,
  SubscriptionSchedule,
  CustomerBillingInfo,
  BillingInformation,
  IGetImagesResponse,
  CountResources,
  FileResource,
  Urls,
  IGetFilesData,
  WebsiteResource,
} from 'types/api'
import { logger } from 'utils/logger'
import { dataForm } from 'utils/dataForm'

export const API_URL =
  process.env.REACT_APP_API_DOMAIN === '/' ? `https://${window.location.hostname}` : process.env.REACT_APP_API_DOMAIN

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/v1`,
  credentials: 'include',
  prepareHeaders: (headers) => {
    const token = readFromStorage(TOKEN_KEY)
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
  },
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  try {
    let result = await baseQuery(args, api, extraOptions)

    if (result.error?.status === 401) {
      const refreshResult = await baseQuery('/user/auth/renew', api, extraOptions)
      if (refreshResult.data && Object.keys(refreshResult.data).length !== 0) {
        saveInStorage(TOKEN_KEY, (refreshResult.data as { token: string }).token)
        result = await baseQuery(args, api, extraOptions)
      } else {
        api.dispatch({ type: LOGOUT })
      }
    }

    return result
  } catch (error) {
    logger.error('baseQueryWithReauth', error)
    throw error
  }
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'User',
    'Company',
    'Conversation',
    'Bot',
    'Users',
    'Conversations',
    'Resources',
    'Knowledge',
    'SourceChanges',
    'IntegrationDetails',
    'ZendeskSyncDate',
    'Subscription',
    'StripeCustomer',
    'StripeCards',
    'BillingInformation',
    'Images',
    'Files',
  ],
  endpoints: (builder) => ({
    me: builder.query<User, void>({
      query: () => '/me',
      providesTags: ['User'],
    }),
    updateUserRole: builder.mutation<unknown, { companyId: string; userId: string; role: string }>({
      query: ({ companyId, userId, role }) => ({
        url: `/companies/${companyId}/users/${userId}/role`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<
      unknown,
      {
        body: Partial<{
          login: string
          name: string
          password: string
          treat_as_offline: boolean
          avatar_url: string
          role: string
          timezone: string
          company_name: string
        }>
      }
    >({
      query: ({ body }) => ({
        url: `/user`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    // TODO: update types
    changePassword: builder.mutation<void, { newPassword: string }>({
      query: ({ newPassword }) => ({
        method: 'PUT',
        url: `/user/password`,
        body: {
          password: newPassword,
        },
      }),
    }),
    resetPassword: builder.mutation<void, { newPassword: string; id: string }>({
      query: ({ newPassword, id }) => ({
        method: 'POST',
        url: `/user/reset_password`,
        body: {
          id,
          password: newPassword,
        },
      }),
    }),
    // TODO: update types
    createUser: builder.mutation<
      any,
      {
        name: string
        password: string
        login: string
      }
    >({
      query: ({ name, password, login }) => ({
        url: '/user',
        method: 'POST',
        body: {
          name,
          password,
          login,
        },
      }),
    }),
    login: builder.mutation<
      Auth,
      {
        email: string
        password: string
      }
    >({
      query: ({ email, password }) => ({
        url: '/user/auth',
        method: 'POST',
        body: {
          login: email,
          password,
        },
      }),
    }),
    forgotPassword: builder.mutation<any, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: '/user/forgot_password',
        body: { login: email },
      }),
    }),
    // TODO: update types
    resendEmailCode: builder.query<any, { id: string }>({
      query: ({ id }) => ({
        url: `/user/${id}/email/verify/resend`,
      }),
    }),
    // TODO: update types
    confirmUser: builder.mutation<any, { id: string; code: string }>({
      query: ({ id, code }) => ({
        url: `/user/${id}/email/confirm`,
        method: 'PUT',
        body: { code },
      }),
    }),
    // TODO: update types
    verifyUser: builder.mutation<any, { id: string; phoneNumber: string }>({
      query: ({ id, phoneNumber }) => ({
        method: 'PUT',
        url: `/user/${id}/verify`,
        body: { phone_number: phoneNumber },
      }),
    }),
    // TODO: update types
    checkEmail: builder.mutation<{ name: string } | null, { email: string }>({
      query: ({ email }) => ({
        method: 'POST',
        url: '/user/check',
        body: { login: email.trim() },
      }),
    }),
    // company endpoints
    createCompany: builder.mutation<{ company_id: string }, void>({
      query: () => ({
        url: '/company',
        method: 'POST',
      }),
    }),
    getCompanyById: builder.query<Company, { id: string }>({
      query: ({ id }) => `/company/${id}`,
    }),
    updateCompany: builder.mutation<Company, { id: string; body: { name: string } }>({
      query: ({ id, body }) => ({
        method: 'PUT',
        url: `/company/${id}`,
        body,
      }),
    }),
    getCompanyUsers: builder.query<User[], { id: string }>({
      query: ({ id }) => `/company/${id}/users`,
      providesTags: ['Users'],
    }),
    getCompanyBot: builder.query<TechBot, { companyId: string }>({
      query: ({ companyId }) => `/company/${companyId}/bot`,
      providesTags: ['Bot'],
    }),
    addUserToCompany: builder.mutation<{ id: string }, { id: string; name: string; password: string; login: string }>({
      query: ({ id, name, password, login }) => ({
        url: `/company/${id}/user`,
        method: 'POST',
        body: { name, password, login },
      }),
    }),
    addUserFromInvite: builder.mutation<{ id: string }, { name: string; password: string; login: string }>({
      query: ({ name, password, login }) => ({
        url: `/user/accept`,
        method: 'POST',
        body: { name, password, login },
      }),
    }),
    // bot endpoints
    getBotById: builder.query<TechBot, { id: string }>({
      query: ({ id }) => `/bot/${id}`,
      providesTags: ['Bot'],
    }),
    removeUser: builder.mutation<unknown, void>({
      query: () => ({
        url: `/user`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    // TODO: provide type for body
    updateBotById: builder.mutation<unknown, { id: string; body: UpdateBotData }>({
      query: ({ id, body }) => ({
        url: `/bot/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Bot'],
    }),
    uploadUserAvatar: builder.mutation<{ url: string }, { file: File }>({
      query: ({ file }) => ({
        url: `/user/avatar`,
        method: 'POST',
        body: dataForm(file),
      }),
      invalidatesTags: ['User'],
    }),
    removeUserAvatar: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: `/user/avatar`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    uploadBotLogo: builder.mutation<{ url: string }, { id: string; file: File }>({
      query: ({ id, file }) => ({
        url: `/upload/bot/${id}/logo`,
        method: 'POST',
        body: dataForm(file),
      }),
    }),
    uploadPortalLogo: builder.mutation<{ url: string }, { id: string; file: File }>({
      query: ({ id, file }) => ({
        url: `/upload/bot/${id}/portal-logo`,
        method: 'POST',
        body: dataForm(file),
      }),
    }),
    uploadMessageBadgeLogo: builder.mutation<{ url: string }, { id: string; file: File }>({
      query: ({ id, file }) => ({
        url: `/upload/bot/${id}/portal-header-image`,
        method: 'POST',
        body: dataForm(file),
      }),
    }),
    getBotCode: builder.query<{ code: string; companyName: string }, { id: string }>({
      query: ({ id }) => `/bot/${id}/code`,
    }),

    // dashboard endpoints
    getDashboardData: builder.query<DashboardData, { id: string; startDate: string; endDate: string }>({
      query: ({ id, startDate, endDate }) => `/company/${id}/dashboard?startDate=${startDate}&endDate=${endDate}`,
    }),

    // subscription endpoints
    // TODO: update types
    getSubscriptionConfig: builder.query<{ stripe_publishable_key: string }, void>({
      query: () => `/payment/config`,
    }),
    // TODO: update types
    getPricingPlans: builder.query<
      {
        mount: {
          id: string
          name: string
          prices: { id: string; unit_amount: string; currency: string }[]
        }[]
      },
      void
    >({
      query: () => `/pricing/plans`,
    }),
    // TODO: update types
    getConversation: builder.query<
      any,
      {
        id?: string | null
      }
    >({
      query: ({ id }) => `/conversation/${id}`,
      providesTags: ['Conversation'],
    }),
    getLastMessages: builder.query<
      Message[],
      {
        id: string
        isoDate: string
      }
    >({
      query: ({ id, isoDate }) => {
        return `/conversation/${id}/last-messages/${isoDate}`
      },
    }),
    sendMessageAsAgent: builder.mutation<
      { id: string },
      { conversation_id: string; author_type: string; text: string }
    >({
      query: ({ conversation_id, author_type, text }) => ({
        url: `/conversation/${conversation_id}/message/${author_type}`,
        method: 'POST',
        body: { text },
      }),
    }),
    setMessageAsRead: builder.mutation<
      { id: string },
      {
        messageId: string
        userId: string
        conversationId: string
        is_read: boolean
      }
    >({
      query: ({ messageId, userId, conversationId, is_read }) => ({
        url: `/user/${userId}/${conversationId}/${messageId}/read`,
        method: 'POST',
        body: { is_read },
      }),
    }),
    updateUserActivity: builder.mutation<unknown, unknown>({
      query: () => ({
        url: `/user/activity`,
        method: 'POST',
      }),
    }),
    inviteUsersToCompany: builder.mutation<User, { emails: string[]; id: string }>({
      query: ({ emails, id }) => ({
        method: 'POST',
        url: `/company/${id}/invite/users`,
        body: { emails: emails },
      }),
      invalidatesTags: ['Users'],
    }),
    deleteUserFromCompany: builder.mutation<User, { companyId: string; userId: string }>({
      query: ({ companyId, userId }) => ({
        url: `/company/${companyId}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    endConversation: builder.mutation<{ id: string }, unknown>({
      query: ({ id }) => ({
        url: `/conversation/${id}/end`,
        method: 'POST',
      }),
    }),
    // bot management endpoints
    rebuildKnowledge: builder.mutation<{ message: string }, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/${botId}/sync-knowledge`,
        method: 'POST',
      }),
      invalidatesTags: ['Bot', 'SourceChanges'],
    }),
    syncUrls: builder.mutation<{ message: string }, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/${botId}/sync-urls`,
        method: 'POST',
      }),
      invalidatesTags: ['Bot', 'SourceChanges'],
    }),
    syncImages: builder.mutation<{ message: string }, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/${botId}/sync-images`,
        method: 'POST',
      }),
      invalidatesTags: ['Bot', 'SourceChanges'],
    }),
    syncConversations: builder.mutation<{ message: string }, { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/${botId}/sync-conversations`,
        method: 'POST',
      }),
      invalidatesTags: ['Bot', 'SourceChanges'],
    }),
    // resources endpoints
    getBotWebsites: builder.query<WebsiteResource, { id: string; page?: number; limit?: number }>({
      query: ({ id, page = 1, limit = 10 }) => `/bot/${id}/websites?page=${page}&limit=${limit}`,
      providesTags: ['Resources', 'SourceChanges'],
    }),
    getBotFiles: builder.query<FileResource, { id: string; page?: number; limit?: number }>({
      query: ({ id, page = 1, limit = 10 }) => `/bot/${id}/files?page=${page}&limit=${limit}`,
      providesTags: ['Resources', 'SourceChanges'],
    }),
    getCountResources: builder.query<CountResources, { id: string }>({
      query: ({ id }) => `/resource/bot/${id}/count`,
      providesTags: ['Resources', 'SourceChanges'],
    }),
    addResourceLink: builder.mutation<{ id: string }, { botId: string; url: string }>({
      query: ({ botId, url }) => ({
        url: `/resource/bot/${botId}/url`,
        method: 'POST',
        body: { url },
      }),
      invalidatesTags: ['Resources', 'SourceChanges'],
    }),
    deleteResourceLink: builder.mutation<unknown, { id: string }>({
      query: ({ id }) => ({
        url: `/resource/link/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Resources', 'SourceChanges'],
    }),
    addResourceFile: builder.mutation<{ id: string }, { botId: string; file: File }>({
      query: ({ botId, file }) => ({
        url: `/resource/bot/${botId}/file`,
        method: 'POST',
        body: dataForm(file),
      }),
      invalidatesTags: ['Resources', 'SourceChanges'],
    }),
    deleteResourceFile: builder.mutation<unknown, { botId: string; resourceId: string }>({
      query: ({ botId, resourceId }) => ({
        url: `/resource/${botId}/file/${resourceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Resources', 'SourceChanges'],
    }),
    getDownloadResourceFileLink: builder.query<{ url: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/resource/download/${id}`,
        method: 'GET',
      }),
    }),
    getKnowledge: builder.query<Knowledge, { id: string }>({
      query: ({ id }) => ({
        url: `/bot/${id}/knowledges`,
        method: 'GET',
      }),
      providesTags: ['Knowledge'],
    }),
    postKnowledge: builder.mutation<{ id: string }, { id: string; body: KnowledgeBody }>({
      query: ({ id, body }) => ({
        url: `/bot/${id}/knowledge/agent`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Knowledge', 'SourceChanges'],
    }),
    deleteKnowledge: builder.mutation<{ message: string }, { botId: string; knowledgeId: string }>({
      query: ({ botId, knowledgeId }) => ({
        url: `/bot/${botId}/knowledge/${knowledgeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SourceChanges'],
    }),
    patchKnowledge: builder.mutation<{ id: string }, { botId: string; knowledgeId: string; body: KnowledgeBody }>({
      query: ({ botId, knowledgeId, body }) => ({
        url: `/bot/${botId}/knowledge/${knowledgeId}`,
        body: body,
        method: 'PATCH',
      }),
      invalidatesTags: ['SourceChanges'],
    }),
    approveKnowledge: builder.mutation<{ id: string }, { botId: string; knowledgeId: string }>({
      query: ({ botId, knowledgeId }) => ({
        url: `/bot/${botId}/knowledge/${knowledgeId}/approve`,
        method: 'POST',
      }),
      invalidatesTags: ['SourceChanges'],
    }),
    getSourceChanges: builder.query<ParsedSourceChange[], { botId: string }>({
      query: ({ botId }) => ({
        url: `/bot/${botId}/source-changes`,
        method: 'GET',
      }),
      providesTags: ['SourceChanges'],
    }),
    playgound: builder.mutation<
      any,
      {
        companyLink: string
        firstPrompt: string
        secondPrompt: string
        userInput: string
        conversationHistory: string
      }
    >({
      query: (body) => ({
        url: `/playground`,
        method: 'POST',
        body: body,
      }),
    }),
    getIntegrationDetails: builder.query<
      any,
      {
        botId: string
      }
    >({
      query: ({ botId }) => ({
        url: `/integration/${botId}/details`,
        method: 'GET',
      }),
      providesTags: ['IntegrationDetails', 'ZendeskSyncDate'],
    }),
    postZendeskCredentials: builder.mutation<
      any,
      {
        botId: string
        body: {
          zendesk_key: string
          zendesk_username: string
          zendesk_subdomain: string
        }
      }
    >({
      query: ({ botId, body }) => ({
        url: `/integration/${botId}/zendesk`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['IntegrationDetails'],
    }),
    deleteZendeskCredentials: builder.mutation<
      any,
      {
        botId: string
      }
    >({
      query: ({ botId }) => ({
        url: `/integration/${botId}/zendesk`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IntegrationDetails'],
    }),
    getZendeskKnowledge: builder.mutation<
      any,
      {
        botId: string
      }
    >({
      query: ({ botId }) => ({
        url: `/bot/${botId}/knowledge/zendesk/download`,
        method: 'POST',
      }),
      invalidatesTags: ['ZendeskSyncDate'],
    }),
    disableZendeskKnowledge: builder.mutation<
      any,
      {
        botId: string
        isDisabled: boolean
      }
    >({
      query: ({ botId, isDisabled }) => ({
        url: `/bot/${botId}/knowledge/zendesk/use`,
        method: 'POST',
        body: { disable: isDisabled },
      }),
      invalidatesTags: ['SourceChanges'],
    }),
    rebuildZendesk: builder.mutation<
      any,
      {
        botId: string
      }
    >({
      query: ({ botId }) => ({
        url: `/bot/${botId}/zendesk/rebuild`,
        method: 'POST',
      }),
    }),
    updateHandoffMode: builder.mutation<
      unknown,
      {
        id: string
        body: {
          handoffType: string | undefined
          handoffEmail: string | undefined
        }
      }
    >({
      query: ({ id, body }) => ({
        url: `/bot/${id}/handoff-mode`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Bot'],
    }),
    //GET WIDGET AND PORTAL URLS
    getUrls: builder.query<Urls, { id: string }>({
      query: ({ id }) => ({
        url: `/bot/${id}/config`,
        method: 'GET',
      }),
      providesTags: ['Bot'],
    }),
    // SUBSRCIPTIONS
    getProducts: builder.query<Product[], void>({
      query: () => `/stripe/products`,
    }),
    getCustomer: builder.query<unknown, { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/customer`,
      providesTags: ['Subscription', 'StripeCustomer'],
    }),
    getCurrentSubscription: builder.query<Subscription, { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/subscription/current`,
      providesTags: ['Subscription'],
    }),
    getCards: builder.query<Card[], { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/cards`,
      providesTags: ['Subscription', 'StripeCards'],
    }),
    getPaymentHistory: builder.query<PaymentHistoryType[], { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/payment/history`,
      providesTags: ['Subscription'],
    }),
    getSubscriptionSchedule: builder.query<SubscriptionSchedule, { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/subscriptions/schedule`,
      providesTags: ['Subscription'],
    }),
    getBillingInformation: builder.query<BillingInformation, { companyId: string }>({
      query: ({ companyId }) => `/stripe/${companyId}/customer/billing`,
      providesTags: ['BillingInformation'],
    }),
    createSubscription: builder.mutation<
      PaymentIntentResponseType,
      { companyId: string; priceId: string; paymentMethodId?: string }
    >({
      query: ({ priceId, companyId, paymentMethodId }) => ({
        method: 'POST',
        url: `/stripe/${companyId}/subscription`,
        body: { price_id: priceId, payment_method_id: paymentMethodId },
      }),
      invalidatesTags: ['Subscription', 'StripeCustomer'],
    }),
    addPaymentMethod: builder.mutation<unknown, { companyId: string; paymentMethodId: string }>({
      query: ({ paymentMethodId, companyId }) => ({
        url: `/stripe/${companyId}/payment`,
        method: 'POST',
        body: { payment_method_id: paymentMethodId },
      }),
      invalidatesTags: ['StripeCustomer'],
    }),
    deletePaymentMethod: builder.mutation<unknown, { companyId: string; paymentMethodId: string }>({
      query: ({ paymentMethodId, companyId }) => ({
        url: `/stripe/${companyId}/payment`,
        method: 'DELETE',
        body: { payment_method_id: paymentMethodId },
      }),
      invalidatesTags: ['StripeCustomer', 'StripeCards'],
    }),
    setDefaultPaymentMethod: builder.mutation<unknown, { companyId: string; paymentMethodId: string }>({
      query: ({ paymentMethodId, companyId }) => ({
        url: `/stripe/${companyId}/payment/default`,
        method: 'POST',
        body: { payment_method_id: paymentMethodId },
      }),
      invalidatesTags: ['StripeCustomer', 'StripeCards'],
    }),
    setCustomerBillingInfo: builder.mutation<unknown, { companyId: string; body: CustomerBillingInfo }>({
      query: ({ body, companyId }) => ({
        url: `/stripe/${companyId}/customer/billing`,
        method: 'POST',
        body: { billing_address: body },
      }),
      invalidatesTags: ['BillingInformation'],
    }),
    getDownloadInvoice: builder.query<{ url: string }, { invoiceId: string }>({
      query: ({ invoiceId }) => ({
        method: 'GET',
        url: `/stripe/download/invoice/${invoiceId}`,
      }),
      providesTags: ['Subscription', 'StripeCustomer', 'StripeCards'],
    }),
    contactToSales: builder.mutation<unknown, { companyId: string }>({
      query: ({ companyId }) => ({
        method: 'POST',
        url: `/stripe/${companyId}/subscription/contact_to_sales`,
      }),
      invalidatesTags: ['Subscription', 'StripeCustomer'],
    }),
    // images
    getImages: builder.query<IGetImagesResponse[], { botId: string }>({
      query: ({ botId }) => `/bot/${botId}/images`,
      providesTags: [{ type: 'Images', id: 'LIST' }],
    }),
    uploadImage: builder.mutation<{ imageId: string }, { botId: string; file: File }>({
      query: ({ botId, file }) => ({
        url: `/bot/${botId}/upload/image`,
        method: 'POST',
        body: dataForm(file),
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),
    deleteImage: builder.mutation<{ message: string }, { botId: string; imageId: string }>({
      query: ({ botId, imageId }) => ({
        url: `/bot/${botId}/image/${imageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),
    updateImage: builder.mutation<
      unknown,
      {
        botId: string
        imageId: string
        body: Partial<{
          title: string
          description: string
          category: string
          status: string
        }>
      }
    >({
      query: ({ botId, imageId, body }) => ({
        url: `/bot/${botId}/image/${imageId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
    }),
    // files
    getFiles: builder.query<IGetFilesData, { botId: string; folderPath?: string; limit?: number; page?: number }>({
      query: ({ botId, folderPath = '', limit = 10, page = 1 }) => ({
        url: `/bot/${botId}/list?limit=${limit}&page=${page}&folderPath=${folderPath}`,
      }),
      providesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    getFoldersForMoveTo: builder.query<
      {
        id: string
        name: string
        parent_id: string
        is_locked: boolean
        created_at: string
        updated_at: string
      }[],
      { botId: string; folderPath?: string }
    >({
      query: ({ botId, folderPath = 'root' }) => ({
        url: `/bot/${botId}/folders?folderPath=${folderPath}`,
      }),
      providesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    uploadFile: builder.mutation<{ fileId: string }, { botId: string; file: File; folderPath?: string }>({
      query: ({ botId, file, folderPath = 'root' }) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folderData', JSON.stringify({ path: folderPath }))

        return {
          url: `/bot/${botId}/upload/file`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    deleteFile: builder.mutation<{ message: string }, { botId: string; fileId: string; folderPath?: string }>({
      query: ({ botId, fileId, folderPath = 'root' }) => ({
        url: `/bot/${botId}/file/${fileId}`,
        method: 'DELETE',
        body: {
          folderData: {
            path: folderPath,
          },
        },
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    renameFolder: builder.mutation<{ fileId: string }, { botId: string; newName: string; folderPath: string }>({
      query: ({ botId, newName, folderPath }) => ({
        url: `/bot/${botId}/rename/folder`,
        method: 'POST',
        body: {
          folderData: {
            newName,
            path: `/root${folderPath}`,
          },
        },
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    renameFile: builder.mutation<
      { fileId: string },
      { botId: string; newName: string; folderPath: string; fileId: string }
    >({
      query: ({ botId, newName, folderPath, fileId }) => ({
        url: `/bot/${botId}/rename/file/${fileId}`,
        method: 'POST',
        body: {
          folderData: {
            path: `${folderPath}`,
          },
          fileData: {
            newName,
          },
        },
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    createFolder: builder.mutation<{ folderId: string }, { botId: string; name: string; folderPath?: string }>({
      query: ({ botId, name, folderPath = 'root' }) => ({
        url: `/bot/${botId}/create/folder`,
        method: 'POST',
        body: {
          folderData: {
            name,
            path: folderPath,
          },
        },
      }),
      invalidatesTags: (result, error) => {
        if (error) {
          return []
        }
        return [{ type: 'Files', id: 'LIST' }]
      },
    }),
    deleteFolder: builder.mutation<{ message: string }, { botId: string; folderId: string }>({
      query: ({ botId, folderId }) => ({
        url: `/bot/${botId}/folder`,
        method: 'DELETE',
        body: {
          folderData: {
            path: `/root${folderId}`,
          },
        },
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    moveFile: builder.mutation<
      unknown,
      { botId: string; fileId: string; currentFolderPath: string; targetFolderPath: string }
    >({
      query: ({ botId, fileId, currentFolderPath, targetFolderPath }) => ({
        url: `/bot/${botId}/move/file/${fileId}`,
        body: {
          data: {
            currentFolderPath,
            targetFolderPath,
          },
        },
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
    moveFolder: builder.mutation<unknown, { botId: string; currentFolderPath: string; targetFolderPath: string }>({
      query: ({ botId, currentFolderPath, targetFolderPath }) => ({
        url: `/bot/${botId}/move/folder`,
        body: {
          data: {
            currentFolderPath,
            targetFolderPath,
          },
        },
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'Files', id: 'LIST' }],
    }),
  }),
})
