import { FC } from 'react'

export interface iIconLink {
  fillColor?: string
}

const IconLink: FC<iIconLink> = ({ fillColor = '#686868' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Link">
        <mask
          id="mask0_1611_14076"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1611_14076)">
          <path
            id="link"
            d="M7.03845 16.5388C5.78282 16.5388 4.7125 16.0963 3.8275 15.2114C2.9425 14.3266 2.5 13.2564 2.5 12.0009C2.5 10.7454 2.9425 9.67504 3.8275 8.78979C4.7125 7.90454 5.78282 7.46191 7.03845 7.46191H10.0577C10.2702 7.46191 10.4483 7.53382 10.592 7.67764C10.7358 7.82144 10.8077 7.99964 10.8077 8.21224C10.8077 8.42482 10.7358 8.60291 10.592 8.74649C10.4483 8.89007 10.2702 8.96186 10.0577 8.96186H7.0373C6.19832 8.96186 5.48235 9.25834 4.8894 9.85129C4.29645 10.4442 3.99998 11.1606 3.99998 12.0003C3.99998 12.8401 4.29645 13.5564 4.8894 14.1494C5.48235 14.7423 6.19832 15.0388 7.0373 15.0388H10.0577C10.2702 15.0388 10.4483 15.1107 10.592 15.2545C10.7358 15.3983 10.8077 15.5765 10.8077 15.7891C10.8077 16.0017 10.7358 16.1798 10.592 16.3234C10.4483 16.467 10.2702 16.5388 10.0577 16.5388H7.03845ZM8.99998 12.7503C8.78748 12.7503 8.60936 12.6784 8.46563 12.5346C8.32188 12.3908 8.25 12.2126 8.25 12C8.25 11.7874 8.32188 11.6093 8.46563 11.4657C8.60936 11.3222 8.78748 11.2504 8.99998 11.2504H15C15.2125 11.2504 15.3906 11.3223 15.5344 11.4661C15.6781 11.6099 15.75 11.7881 15.75 12.0007C15.75 12.2133 15.6781 12.3914 15.5344 12.5349C15.3906 12.6785 15.2125 12.7503 15 12.7503H8.99998ZM13.9423 16.5388C13.7298 16.5388 13.5517 16.4669 13.4079 16.323C13.2642 16.1792 13.1923 16.001 13.1923 15.7884C13.1923 15.5759 13.2642 15.3978 13.4079 15.2542C13.5517 15.1106 13.7298 15.0388 13.9423 15.0388H16.9627C17.8016 15.0388 18.5176 14.7423 19.1106 14.1494C19.7035 13.5564 20 12.8401 20 12.0003C20 11.1606 19.7035 10.4442 19.1106 9.85129C18.5176 9.25834 17.8016 8.96186 16.9627 8.96186H13.9423C13.7298 8.96186 13.5517 8.88996 13.4079 8.74616C13.2642 8.60235 13.1923 8.42415 13.1923 8.21156C13.1923 7.99896 13.2642 7.82087 13.4079 7.67729C13.5517 7.53371 13.7298 7.46191 13.9423 7.46191H16.9615C18.2171 7.46191 19.2875 7.90436 20.1725 8.78924C21.0575 9.67412 21.5 10.7443 21.5 11.9998C21.5 13.2553 21.0575 14.3256 20.1725 15.2109C19.2875 16.0961 18.2171 16.5388 16.9615 16.5388H13.9423Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconLink
