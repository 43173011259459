import { Divider, MenuItem } from '@mui/material'
import IconDelete from 'assets/icons/iconDelete'
import IconEdit from 'assets/icons/iconEdit'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'

import style from './DropdownFolderContent.module.scss'
import { messages } from 'constants/messages'
import { IUploadedFolder } from 'types/api'
import IconMoveTo from 'assets/icons/iconMoveTo'

const { dropdownContent } = style

interface IProps {
  folder: IUploadedFolder
  handleMoveTo?: (folder: IUploadedFolder) => void
}

const DropdownFolderContent = ({ folder, handleMoveTo }: IProps) => {
  const { files } = messages
  const dispatch = useDispatch()

  const handleOpenRenameFolderModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.RENAME_FILE_FOLDER,
        folderId: folder.id,
        folderName: folder.name,
      })
    )
  }

  const handleOpenDeleteFolderModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FOLDER,
        folderId: folder.id,
      })
    )
  }

  return (
    <div className={dropdownContent}>
      <MenuItem onClick={handleOpenRenameFolderModal}>
        <IconEdit fillColor="#777777" /> {files.rename}
      </MenuItem>
      <Divider style={{ margin: 0 }} />
      {handleMoveTo && (
        <>
          <MenuItem onClick={() => handleMoveTo(folder)}>
            <IconMoveTo />
            {files.moveTo}
          </MenuItem>
          <Divider style={{ margin: 0 }} />
        </>
      )}
      <MenuItem onClick={handleOpenDeleteFolderModal}>
        <IconDelete fillColor="red" /> {files.deleteFolder}
      </MenuItem>
    </div>
  )
}

export default DropdownFolderContent
