import { FC, useState } from 'react'
import styles from './TechBotDeploy.module.scss'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'
import CustomInput from 'components/customInput/CustomInput'
import IconCopy from 'assets/icons/iconCopy'
import { Note } from 'components/noteComponent/Note'
import IconSend from 'assets/icons/iconSend'
import { copyToClipboard } from 'utils/tools'
import { messages } from 'constants/messages'
import { api } from 'redux/rtkQuery'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'
import BotPreview from '../botPreview/BotPreview'
import { isEmailValid } from 'helpers/validation'

const {
  techbotDeployContainer,
  container,
  title,
  description,
  buttonContainer,
  emailSection,
  codeContainer,
  codeTitle,
  code,
  preview,
} = styles

const { techBotDeploy } = messages.techbots

const TechBotDeploy: FC = () => {
  const user = useAppSelector((state: ReduxState) => state.user)

  const companyId = user?.company_id

  const { data: bot, isLoading: isGettingBots } = api.useGetCompanyBotQuery({ companyId: companyId }, { skip: !user })

  const botId = bot?.id as string

  const { data } = api.useGetBotByIdQuery({ id: botId as string }, { skip: !botId, refetchOnMountOrArgChange: true })

  const { data: urls, isLoading: isGettingUrls } = api.useGetUrlsQuery(
    { id: botId },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [isSendCodeExpanded, setIsSendCodeExpanded] = useState(false)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)

  const onHandleEmail = (newValue: string): void => {
    setEmailError(false)
    setEmail(newValue.trim())
  }

  const onBlurEmail = (): void => {
    const isError = !isEmailValid(email)
    setEmailError(isError)
  }

  const linkToTestPage = `<script src="${urls?.widget_url}?code=${bot?.code}" id="supportx-techbot" defer="true"></script>`

  const handleCopyToClipboard = (): void => {
    copyToClipboard(linkToTestPage, techBotDeploy.copiedToast, techBotDeploy.copyFailedToast)
  }

  const sendCodeToEmail = (): void => {
    if (emailError || email === '') {
      return setEmailError(true)
    }
    toastFuncSuccess(techBotDeploy.emailSentToast)
  }

  if (isGettingBots || isGettingUrls) return <CustomLoader />

  return (
    <>
      <div className={techbotDeployContainer}>
        <div className={container}>
          <h4 className={title}>{techBotDeploy.howToInstall}</h4>
          <p className={description}>{techBotDeploy.applyCode}</p>
          <div className={codeContainer}>
            <div className={codeTitle}>{techBotDeploy.codeTitle}</div>
            <div className={code}>{linkToTestPage}</div>
          </div>
          <div className={buttonContainer}>
            <ActionButton
              label={techBotDeploy.copyToClipboard}
              onPress={handleCopyToClipboard}
              buttonType={ActionButtonType.SECONDARY}
              size={ActionButtonSize.MEDIUM_WIDE}
              icon={<IconCopy />}
            />
            <ActionButton
              label={techBotDeploy.sendCodeToEmail}
              onPress={() => {
                setIsSendCodeExpanded((prev) => !prev)
              }}
              buttonType={ActionButtonType.TERTIARY}
              size={ActionButtonSize.MEDIUM_WIDE}
              icon={<IconSend fillColor="#5E36FF" />}
            />
          </div>
          {isSendCodeExpanded && (
            <div className={emailSection}>
              <CustomInput
                id="email"
                value={email}
                onChangeValue={onHandleEmail}
                textError={techBotDeploy.emailErrorText}
                onBlur={onBlurEmail}
                isInvalid={emailError}
                placeholder={techBotDeploy.emailPlaceholder}
              />
              <ActionButton
                label={techBotDeploy.sendCodeToEmail}
                disabled={email === ''}
                onPress={() => {
                  sendCodeToEmail()
                }}
                buttonType={ActionButtonType.PRIMARY}
                size={ActionButtonSize.MEDIUM}
                icon={<IconSend />}
              />
            </div>
          )}
          <Note description={techBotDeploy.noteDescription} />
        </div>
        <div className={preview}>
          {data && (
            <BotPreview
              name={data.name}
              primaryColor={data.color_primary}
              secondaryColor={data.color_secondary}
              avatar={data.logo_url}
            />
          )}
        </div>
      </div>
      {isGettingBots && <CustomLoader />}
    </>
  )
}

export default TechBotDeploy
