import Dropzone, { UPLOAD_TYPES } from 'components/dropzone/Dropzone'
import styles from './UploadImageModalContent.module.scss'
import { toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import IconUploadLogo from 'assets/icons/iconUploadLogo'
import { api } from 'redux/rtkQuery'
import { useState } from 'react'
import { ICustomFile } from 'utils/generateURL'
import { useDispatch, useSelector } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'
import { botSelector } from 'redux/bot/slice'
import { messages } from 'constants/messages'
import { PROGRESS_STATUS } from 'types/api'
import FileCard from 'components/fileCard/FileCard'

const {
  wrapper,
  modalTitle,
  description,
  uploaderProperties,
  uploadedImagesTitle,
  modalFooter,
  divider,
  modalActionsWrapper,
  imageCardWrapper,
} = styles

interface IProps {
  onClose?: () => void
}

const UploadImageModalContent = ({ onClose }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()
  const bot = useSelector(botSelector)
  const [files, setFiles] = useState<ICustomFile[]>([])

  const [uploadImage, { isLoading }] = api.useUploadImageMutation()
  const [deleteImage, { isLoading: loadingDeleteImage }] = api.useDeleteImageMutation()

  const handleUploadImages = async () => {
    if (files.length === 0) return

    const filesToUpload = files.filter((file) => file.progressStatus !== PROGRESS_STATUS.UPLOADED)

    if (filesToUpload.length === 0) return

    const uploadPromises = filesToUpload.map(async (file, index) => {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        newFiles[index].progressStatus = PROGRESS_STATUS.UPLOADING
        return newFiles
      })

      try {
        const result = await uploadImage({
          botId: bot.id,
          file,
        })
        return { status: 'fulfilled', index, result }
      } catch (error) {
        return { status: 'rejected', index, error }
      }
    })

    const results = await Promise.allSettled(uploadPromises)

    results.forEach((result) => {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]

        if (result.status === 'fulfilled') {
          const response = result.value.result as unknown as { data: { imageId: string } }

          newFiles[result.value.index].progressStatus = PROGRESS_STATUS.UPLOADED
          newFiles[result.value.index].id = response?.data?.imageId
        } else if (result.status === 'rejected' && result.reason && 'index' in result.reason) {
          newFiles[result.reason.index].progressStatus = PROGRESS_STATUS.ERROR
        }
        return newFiles
      })
    })

    toastFuncNotification(`${files.length} ${images.imagesSuccessfullyAdded}`)

    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.UPLOAD_IMAGE,
      })
    )
  }

  const handleCancelAction = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.CANCEL_UPLOAD,
      })
    )
  }

  const handleDeleteImage = (preview: string) => {
    const newFiles = files.filter((file) => file.preview !== preview)
    setFiles(newFiles)
  }

  const handleDeleteImageFromServer = async (id: string) => {
    await deleteImage({
      botId: bot.id,
      imageId: id,
    })

    const newFiles = files.filter((file) => file.id !== id)
    setFiles(newFiles)
  }

  const handleReuploadImage = (id: string) => {
    const file = files.find((file) => file.id === id)

    if (file) {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles]
        const index = newFiles.findIndex((file) => file.id === id)
        newFiles[index].progressStatus = PROGRESS_STATUS.UPLOADING
        return newFiles
      })

      uploadImage({
        botId: bot.id,
        file,
      })
    }
  }

  return (
    <div className={wrapper}>
      <p className={modalTitle}>{images.uploadImagesModalTitle}</p>
      <p className={description}>{images.uploadImagesModalDescription}</p>
      <Dropzone uploadType={UPLOAD_TYPES.IMAGE} setFiles={setFiles} />
      <div className={uploaderProperties}>
        <p>{images.supportedFormats}</p>
        <p>{images.maximumSize}</p>
      </div>
      {files.length > 0 && (
        <>
          <h3 className={uploadedImagesTitle}>{images.uploadImagesModalTitle}</h3>
          <div className={imageCardWrapper}>
            {files.map((file) => (
              <FileCard
                key={file.preview.split('/').pop()}
                file={file}
                onDelete={handleDeleteImage}
                onDeleteFromServer={handleDeleteImageFromServer}
                onReupload={handleReuploadImage}
              />
            ))}
          </div>
        </>
      )}
      <div className={modalFooter}>
        <div className={divider} />
        <div className={modalActionsWrapper}>
          <ActionButton
            label={images.buttons.cancelUpload}
            buttonType={ActionButtonType.SECONDARY}
            size={ActionButtonSize.MEDIUM}
            onPress={files.length > 0 ? handleCancelAction : onClose}
          />
          <ActionButton
            label={images.buttons.uploadImages}
            buttonType={ActionButtonType.PRIMARY}
            size={ActionButtonSize.MEDIUM}
            loading={isLoading || loadingDeleteImage}
            disabled={isLoading}
            onPress={handleUploadImages}
            icon={<IconUploadLogo fillColor="#ffffff" />}
          />
        </div>
      </div>
    </div>
  )
}

export default UploadImageModalContent
