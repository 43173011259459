import styles from './AuthInstructionModal.module.scss'
import IconClose from 'assets/icons/iconClose'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { messages } from 'constants/messages'

const {
  authInstructionContainer,
  authInstructionContent,
  authInstructionHeaderContainer,
  authInstructionHeaderTitle,
  authInstructionCloseIcon,
  authInstructionStep,
  authBenefits,
  authBenefit,
  authInstructionTitle,
  authInstructionList,
  authInstructionButtonContainer,
} = styles

type AuthInstructionModalProps = {
  onClose: () => void
  setIntegrationStep: (step: number) => void
}

const { stepTwo } = messages.integrations.zendesk

const AuthInstructionModal = ({ onClose, setIntegrationStep }: AuthInstructionModalProps) => {
  return (
    <div className={authInstructionContainer}>
      <div className={authInstructionContent}>
        <div className={authInstructionHeaderContainer}>
          <div className={authInstructionHeaderTitle}>{stepTwo.connect}</div>
          <div className={authInstructionCloseIcon} onClick={() => onClose()}>
            <IconClose />
          </div>
        </div>
        <div className={authInstructionStep}>{stepTwo.step}</div>
        <div className={authBenefits}>{stepTwo.benefitDescription}</div>
        <div className={authBenefit}>{stepTwo.benefit}</div>
        <div className={authInstructionTitle}>{stepTwo.authInstructionTitle}</div>
        <div
          className={authInstructionList}
          dangerouslySetInnerHTML={{
            __html: stepTwo.authInstructionList,
          }}
        />
        <div className={authInstructionButtonContainer}>
          <ActionButton
            label={stepTwo.back}
            buttonType={ActionButtonType.TERTIARY}
            size={ActionButtonSize.MEDIUM}
            onPress={() => setIntegrationStep(1)}
          />
          <ActionButton
            label={stepTwo.newAuthentication}
            size={ActionButtonSize.MEDIUM}
            onPress={() => setIntegrationStep(3)}
          />
        </div>
      </div>
    </div>
  )
}

export default AuthInstructionModal
