import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { api } from './rtkQuery';

const configureAppStore = (initialState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) => gDM().concat(api.middleware),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });

  return store;
};

export const store = configureAppStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ReduxState = ReturnType<typeof rootReducer>;
