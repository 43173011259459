import { FC } from 'react'
import styles from './CustomLoader.module.scss'
import { Loader } from 'components/Loader'

const CustomLoader: FC = () => {
  const { container } = styles
  return (
    <div className={container} data-testid="loaderContainer">
      <Loader />
    </div>
  )
}

export default CustomLoader
