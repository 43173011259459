import { FC, useCallback, useEffect, useState } from 'react'
import styles from './TechBotBrandForm.module.scss'
import CustomInput from 'components/customInput/CustomInput'
import UploadLogoInput from '../uploadLogoInput/UploadLogoInput'
import ColorPicker from '../colorPicker/ColorPicker'
import BotPreview from '../botPreview/BotPreview'
import { toastFuncSuccess, toastFuncError } from 'components/customToastContainer/CustomToastContainer'
import { UpdateBotData } from 'types/api'
import { api } from 'redux/rtkQuery'
import { logger } from 'utils/logger'
import { messages } from 'constants/messages'
import { useOutletContext } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import CustomLoader from 'components/customLoader/CustomLoader'

const { techBotBrandForm } = messages.techbots

const { container, content, formStyles, title, label, preview, separator } = styles

const TechBotBrandForm: FC<any> = () => {
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data, isLoading } = api.useGetCompanyBotQuery(
    { companyId: user?.company_id },
    { skip: !user, refetchOnMountOrArgChange: true }
  )

  const botId = data?.id || ''

  const { data: bot, isLoading: isGettingBot } = api.useGetBotByIdQuery(
    { id: botId as string },
    { skip: !botId, refetchOnMountOrArgChange: true }
  )

  const [companyData, setCompanyData] = useState({
    companyName: bot?.name || '',
    companyWebsite: bot?.company_website_domain || '',
    widgetMessage: bot?.widget_message || '',
    welcomeMessage: bot?.welcome_message || '',
    offlineMessage: bot?.offline_message || '',
    logoPreview: bot?.logo_url || '',
    primaryColor: bot?.color_primary || '#5E36FF',
    secondaryColor: bot?.color_secondary || '#FFFFFF',
  })

  useEffect(() => {
    setCompanyData({
      companyName: bot?.name || '',
      companyWebsite: bot?.company_website_domain || '',
      widgetMessage: bot?.widget_message || '',
      welcomeMessage: bot?.welcome_message || '',
      offlineMessage: bot?.offline_message || '',
      logoPreview: bot?.logo_url || '',
      primaryColor: bot?.color_primary || '#5E36FF',
      secondaryColor: bot?.color_secondary || '#FFFFFF',
    })
  }, [bot])

  const {
    companyName,
    companyWebsite,
    widgetMessage,
    welcomeMessage,
    offlineMessage,
    logoPreview,
    primaryColor,
    secondaryColor,
  } = companyData

  const hasDataChanged =
    companyName !== bot?.name ||
    widgetMessage !== bot?.widget_message ||
    welcomeMessage !== bot?.welcome_message ||
    offlineMessage !== bot?.offline_message ||
    primaryColor !== bot?.color_primary ||
    secondaryColor !== bot?.color_secondary

  const [setShouldShowSaveButton, setReceivedFunction, setIsDisabled] = useOutletContext<any>()

  const [companyNameError, setCompanyNameError] = useState(false)
  const [widgetMessageError, setWidgetMessageError] = useState(false)
  const [welcomeMessageError, setWelcomeMessageError] = useState(false)
  const [offlineMessageError, setOfflineMessageError] = useState(false)

  const [isChatLarge, setIsChatLarge] = useState(false)
  const [isChatOffline, setIsChatOffline] = useState(false)

  const onHandleCompanyName = (newValue: string): void => {
    setCompanyNameError(false)
    setCompanyData((prevState) => ({ ...prevState, companyName: newValue }))
  }

  const onBlurCompanyField = (): void => {
    const trimmedValue = companyName.trim()
    if (trimmedValue === '') {
      setCompanyNameError(true)
    }
  }

  const onHandleWidgetMessage = (newValue: string): void => {
    setWidgetMessageError(false)
    setCompanyData((prevState) => ({ ...prevState, widgetMessage: newValue }))
  }

  const onHandleWelcomeMessage = (newValue: string): void => {
    setWelcomeMessageError(false)
    setCompanyData((prevState) => ({ ...prevState, welcomeMessage: newValue }))
  }

  const onBlurWidetMessageField = (): void => {
    const trimmedValue = widgetMessage.trim()
    if (trimmedValue === '') {
      setWidgetMessageError(true)
    }
  }

  const onBlurWelcomeMessageField = (): void => {
    const trimmedValue = welcomeMessage.trim()
    if (trimmedValue === '') {
      setWelcomeMessageError(true)
    }
  }

  const onHandleOfflineMessage = (newValue: string): void => {
    setOfflineMessageError(false)
    setCompanyData((prevState) => ({ ...prevState, offlineMessage: newValue }))
  }

  const onBlurOfflineMessageField = (): void => {
    const trimmedValue = offlineMessage.trim()
    if (trimmedValue === '') {
      setOfflineMessageError(true)
    }
  }

  const [updateBotById] = api.useUpdateBotByIdMutation()
  const [uploadBotLogo] = api.useUploadBotLogoMutation()

  const onHandleSetLogoPreview = (url: string): void =>
    setCompanyData((prevState) => ({ ...prevState, logoPreview: url }))

  const onHandleChangeLogo = async (file: File): Promise<void> => {
    if (file) {
      try {
        await uploadBotLogo({ id: botId, file }).unwrap()
        toastFuncSuccess('Logo uploaded successfully')
      } catch {
        toastFuncError('Failed to upload logo')
      }
    }
  }

  const saveBotData = useCallback(
    async (botId: string, data: UpdateBotData) => {
      try {
        const updateRes = await updateBotById({ id: botId, body: data })
        if (!('data' in updateRes)) {
          throw new Error('Save supportX data error')
        }
      } catch (error) {
        logger.error('saveBotData error', error)
        toastFuncError(
          error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
            ? error.message
            : 'Failed to save supportX data'
        )
        throw error
      }
    },
    [updateBotById]
  )

  const getUpdatedValue = (newValue: string, oldValue?: string) => (newValue !== oldValue ? newValue : undefined)

  const onHandleSaveChanges = useCallback(async () => {
    try {
      await saveBotData(botId, {
        name: getUpdatedValue(companyName, bot?.name),
        widget_message: getUpdatedValue(widgetMessage, bot?.widget_message),
        welcome_message: getUpdatedValue(welcomeMessage, bot?.welcome_message),
        offline_message: getUpdatedValue(offlineMessage, bot?.offline_message),
        color_primary: getUpdatedValue(primaryColor, bot?.color_primary),
        color_secondary: getUpdatedValue(secondaryColor, bot?.color_secondary),
      })
      toastFuncSuccess('Changes saved successfully')
    } catch (error) {
      logger.error(`error`, error)
    }
  }, [
    botId,
    companyName,
    bot,
    saveBotData,
    widgetMessage,
    welcomeMessage,
    offlineMessage,
    primaryColor,
    secondaryColor,
  ])

  const onHandleBlur = (func: () => void): void => {
    setIsChatLarge(false)
    return func()
  }

  const onHandleOfflineFocus = (): void => {
    setIsChatLarge(true)
    setIsChatOffline(true)
  }

  const onHandleOfflineBlur = (func: () => void): void => {
    setIsChatLarge(false)
    setIsChatOffline(false)
    return func()
  }

  useEffect(() => {
    const isEmptyMessage =
      companyName.trim() === '' ||
      offlineMessage.trim() === '' ||
      welcomeMessage.trim() === '' ||
      widgetMessage.trim() === ''

    setShouldShowSaveButton(true)
    setIsDisabled(!hasDataChanged || isEmptyMessage)
  }, [
    hasDataChanged,
    setShouldShowSaveButton,
    setIsDisabled,
    companyName,
    offlineMessage,
    welcomeMessage,
    widgetMessage,
  ])

  useEffect(() => {
    setReceivedFunction(() => onHandleSaveChanges)
    return () => {
      setReceivedFunction(null)
    }
  }, [onHandleSaveChanges, setReceivedFunction])

  if (isLoading || !bot || isGettingBot) return <CustomLoader />

  return (
    <>
      <div className={container}>
        <div className={content}>
          <div className={formStyles}>
            <h3 className={title}>{techBotBrandForm.nameYourBot}</h3>
            <CustomInput
              id="company-name"
              value={companyName}
              onChangeValue={onHandleCompanyName}
              labelVisible={true}
              label={techBotBrandForm.widgetName}
              placeholder={techBotBrandForm.widgetNamePlaceholder}
              isInvalid={companyNameError}
              textError={techBotBrandForm.widgetNameError}
              onBlur={onBlurCompanyField}
            />
            <CustomInput
              id="company-website"
              value={companyWebsite}
              labelVisible={true}
              label={techBotBrandForm.companyWebsite}
              placeholder={techBotBrandForm.companyWebsitePlaceholder}
              isRequired
              requiredCustomMessage={techBotBrandForm.companyWebsiteCustomMessage}
              disabled
            />
            <div className={separator} />
            <p className={label}>{techBotBrandForm.uploadLogo}</p>
            <UploadLogoInput
              logoPreview={logoPreview}
              setLogoPreview={onHandleSetLogoPreview}
              onChangeLogo={onHandleChangeLogo}
              required
            />
            <p className={label}>{techBotBrandForm.addPrimaryBrandColors}</p>
            <ColorPicker
              color={primaryColor}
              onChangeColor={(color) => {
                setCompanyData((prevState) => ({
                  ...prevState,
                  primaryColor: color,
                }))
              }}
            />
            <p className={label}>{techBotBrandForm.addSecondaryBrandColors}</p>
            <ColorPicker
              color={secondaryColor}
              onChangeColor={(color) => {
                setCompanyData((prevState) => ({
                  ...prevState,
                  secondaryColor: color,
                }))
              }}
            />
            <h3 className={title}>{techBotBrandForm.chatMessages}</h3>
            <CustomInput
              id="widget-message"
              value={widgetMessage}
              onChangeValue={onHandleWidgetMessage}
              labelVisible={true}
              label={techBotBrandForm.widgetMessage}
              placeholder={techBotBrandForm.widgetMessagePlaceholder}
              isInvalid={widgetMessageError}
              textError={techBotBrandForm.widgetMessageError}
              onBlur={() => onHandleBlur(onBlurWidetMessageField)}
              onFocus={() => setIsChatLarge(true)}
              isRequired
              multiline={true}
            />
            <div className={separator} />
            <CustomInput
              id="welcome-message"
              value={welcomeMessage}
              onChangeValue={onHandleWelcomeMessage}
              labelVisible={true}
              label={techBotBrandForm.welcomeMessage}
              placeholder={techBotBrandForm.welcomeMessagePlaceholder}
              isInvalid={welcomeMessageError}
              textError={techBotBrandForm.welcomeMessageError}
              onBlur={() => onHandleBlur(onBlurWelcomeMessageField)}
              onFocus={() => setIsChatLarge(true)}
              isRequired
              multiline={true}
            />
            <div className={separator} />
            <CustomInput
              id="offline-message"
              value={offlineMessage}
              onChangeValue={onHandleOfflineMessage}
              labelVisible={true}
              label={techBotBrandForm.offlineMessage}
              placeholder={techBotBrandForm.offlineMessagePlaceholder}
              isInvalid={offlineMessageError}
              textError={techBotBrandForm.offlineMessageError}
              onBlur={() => onHandleOfflineBlur(onBlurOfflineMessageField)}
              onFocus={onHandleOfflineFocus}
              isRequired
              multiline={true}
            />
          </div>
        </div>
        <div className={preview}>
          <BotPreview
            name={companyName}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            avatar={logoPreview}
            isChatLarge={isChatLarge}
            widgetMessage={widgetMessage}
            welcomeMessage={welcomeMessage}
            offlineMessage={offlineMessage}
            isChatOffline={isChatOffline}
          />
        </div>
      </div>
    </>
  )
}

export default TechBotBrandForm
