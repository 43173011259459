import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Breadcrumbs } from 'modules/techbots/components/techBotDataSources/components/files/Files'

export const useBreadcrumbs = (initialBreadcrumbs: Breadcrumbs = [{ folderName: 'Files', path: 'root' }]) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>(initialBreadcrumbs)
  const pathOfBreadcrumbs = breadcrumbs.map((crumb) => crumb.path).join('/')

  const handleSetBreadcrumbs = async (folderName: string, path: string) => {
    const isPathExist = breadcrumbs.some((breadcrumb) => breadcrumb.path === path)

    if (isPathExist && folderName !== 'Files') {
      const index = breadcrumbs.findIndex((breadcrumb) => breadcrumb.path === path)
      setBreadcrumbs(breadcrumbs.slice(0, index + 1))
      return
    }

    if (folderName === 'Files') {
      setBreadcrumbs(initialBreadcrumbs)
      return
    }

    setBreadcrumbs((prevBreadcrumbs) => [...prevBreadcrumbs, { folderName, path }])
  }

  useEffect(() => {
    const folderNames = searchParams.getAll('folderName')
    const paths = searchParams.getAll('path')

    if (!folderNames.length && !paths.length) {
      searchParams.set('folderName', breadcrumbs[0].folderName)
      searchParams.set('path', breadcrumbs[0].path)
      setSearchParams(searchParams)
    }

    if (folderNames.length > 1 && paths.length > 1 && breadcrumbs.length === 1) {
      const newBreadcrumbs = folderNames.map((folderName, index) => ({ folderName, path: paths[index] }))
      setBreadcrumbs(newBreadcrumbs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (breadcrumbs.length > 1) {
      searchParams.delete('folderName')
      searchParams.delete('path')

      breadcrumbs.forEach((breadcrumb) => {
        searchParams.append('folderName', breadcrumb.folderName)
        searchParams.append('path', breadcrumb.path)
      })

      setSearchParams(searchParams)
    } else {
      searchParams.delete('folderName')
      searchParams.delete('path')
      searchParams.append('folderName', breadcrumbs[0].folderName)
      searchParams.append('path', breadcrumbs[0].path)
      setSearchParams(searchParams)
    }
  }, [breadcrumbs, searchParams, setSearchParams])

  return { breadcrumbs, pathOfBreadcrumbs, handleSetBreadcrumbs, setBreadcrumbs }
}
