import { useAppSelector } from 'redux/hooks'
import { api } from 'redux/rtkQuery'
import { ReduxState } from 'redux/store'
import { KnowledgeBody, KnowledgeSource, ParsedKnowledge } from 'types/api'
import styles from './GeneratedKnowledgeTable.module.scss'
import { messages } from 'constants/messages'
import KnowledgeItem from './KnowledgeItem'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import { useDispatch } from 'react-redux'
import { KNOWLEDGE_TAG } from './helpers'

const { knowledgeListHeader, knowledgeListHeaderItem, knowledgeEmpty, knowledgeEmptyContent } = styles

const { knowledgeMessages } = messages

const GeneratedKnowledgeTable = () => {
  const dispatch = useDispatch()
  const user = useAppSelector((state: ReduxState) => state.user)

  const { data: bot } = api.useGetCompanyBotQuery({
    companyId: user?.company_id,
  })

  const botId = bot?.id || ''
  const { data: knowledgeData } = api.useGetKnowledgeQuery(
    {
      id: botId,
    },
    { skip: !botId }
  )

  const entries = knowledgeData?.knowledges.filter(
    (knowledge: ParsedKnowledge) =>
      !(
        (knowledge.generated_by === KnowledgeSource.GPT && !knowledge.approved_by) ||
        knowledge.generated_by === KnowledgeSource.Zendesk
      )
  ).length

  const [deleteKnowledge] = api.useDeleteKnowledgeMutation()

  const handleDeleteContent = async (id: string) => {
    try {
      await deleteKnowledge({ botId: botId, knowledgeId: id }).unwrap()
      dispatch(api.util.invalidateTags([KNOWLEDGE_TAG]))
      toastFuncNotification(knowledgeMessages.deleteSuccessToast)
    } catch {
      toastFuncError(knowledgeMessages.errorToast)
    }
  }

  const [patchKnowledge] = api.usePatchKnowledgeMutation()

  const handleEditContent = async (id: string, content: KnowledgeBody) => {
    try {
      await patchKnowledge({
        botId: botId,
        knowledgeId: id,
        body: content,
      }).unwrap()
      dispatch(api.util.invalidateTags([KNOWLEDGE_TAG]))
      toastFuncNotification(knowledgeMessages.editContentSuccessToast)
    } catch {
      toastFuncError(knowledgeMessages.errorToast)
    }
  }

  return (
    <>
      <div className={knowledgeListHeader}>
        <div className={knowledgeListHeaderItem}>{knowledgeMessages.preview}</div>
        <div className={knowledgeListHeaderItem}>{knowledgeMessages.suggested}</div>
        <div className={knowledgeListHeaderItem}>
          {knowledgeMessages.status}
        </div>
        <div className={knowledgeListHeaderItem}>{knowledgeMessages.editedTime}</div>
      </div>
      {knowledgeData?.knowledges.map(
        (knowledge: ParsedKnowledge) =>
          !(
            (knowledge.generated_by === KnowledgeSource.GPT && !knowledge.approved_by) ||
            knowledge.generated_by === KnowledgeSource.Zendesk
          ) && (
            <KnowledgeItem
              knowledge={knowledge}
              key={knowledge.id}
              deleteItem={handleDeleteContent}
              editItem={handleEditContent}
            />
          )
      )}
      {entries === 0 && (
        <div className={knowledgeEmpty}>
          <div className={knowledgeEmptyContent}>{knowledgeMessages.emptyKnowledge}</div>
        </div>
      )}
    </>
  )
}

export default GeneratedKnowledgeTable
