import CustomInput from 'components/customInput/CustomInput'
import styles from './NewFileFolderModalContent.module.scss'
import { toastFuncError, toastFuncSuccess } from 'components/customToastContainer/CustomToastContainer'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useState } from 'react'
import { messages } from 'constants/messages'
import { useSelector } from 'react-redux'
import { api } from 'redux/rtkQuery'
import { botSelector } from 'redux/bot/slice'
import { modalSelector } from 'redux/modal/slice'

const { wrapper, title } = styles

interface IProps {
  onClose?: () => void
}

const NewFileFolderModalContent = ({ onClose }: IProps) => {
  const { files } = messages
  const bot = useSelector(botSelector)
  const { modalContext } = useSelector(modalSelector)
  const [folderName, setFolderName] = useState('')

  const [createFolder, { isLoading }] = api.useCreateFolderMutation()

  const handleSetFolderName = (value: string) => {
    setFolderName(value)
  }

  const handleCreateFolder = async () => {
    try {
      const response = await createFolder({ botId: bot.id, name: folderName, folderPath: modalContext.path })
      if ('error' in response && 'data' in response.error) {
        const errorData = response.error.data as { message: string }
        let errorMessage = errorData.message

        if (response.error.status === 409) {
          toastFuncError(errorMessage)
          return
        }
      }

      if (onClose) onClose()
      toastFuncSuccess(files.folderSuccessfullyAdded)
    } catch (error: unknown) {
      toastFuncError(files.folderNotAdded)
    }
  }

  return (
    <div className={wrapper}>
      <p className={title}>{files.newFolderModalTitle}</p>
      <div>
        <CustomInput placeholder="Folder Name" value={folderName} onChangeValue={handleSetFolderName} />
      </div>
      <ActionButton
        label={files.buttons.createFolder}
        buttonType={ActionButtonType.PRIMARY}
        size={ActionButtonSize.MEDIUM}
        onPress={handleCreateFolder}
        loading={isLoading}
        disabled={!folderName || isLoading}
      />
      <ActionButton
        label={files.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
        disabled={isLoading}
      />
    </div>
  )
}

export default NewFileFolderModalContent
