import { useAppSelector } from 'redux/hooks';
import { User, UserRoles } from '../types/api';

export const isAllowedFor = (userRole: UserRoles, roles: UserRoles[]) => {
  return roles.includes(userRole);
};

export const useIsAllowedFor = (roles: UserRoles[]) => {
  const user: User | null = useAppSelector((state) => state.user);

  const isAllowed = user?.role && isAllowedFor(user?.role, roles);

  return isAllowed;
};
