export const readFromStorage = (keyName: string, isSessionStore: boolean = false): string | undefined => {
  const storeType = isSessionStore ? window.sessionStorage : window.localStorage

  try {
    const value = storeType.getItem(keyName)
    if (typeof value === 'string') {
      const parseValue = JSON.parse(value)
      return parseValue
    }
    return undefined
  } catch (e) {
    return
  }
}
export const saveInStorage = (keyName: string, value: any, isSessionStore: boolean = false): void => {
  const storeType = isSessionStore ? window.sessionStorage : window.localStorage

  try {
    const encryptedString = JSON.stringify(value)
    if (typeof encryptedString === 'string') {
      storeType.setItem(keyName, encryptedString)
    }
  } catch (e) {
    return
  }
}
