import { modalActions } from 'redux/modal/slice'
import styles from './CancelUploadModalContent.module.scss'

import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import { useDispatch } from 'react-redux'
import { messages } from 'constants/messages'

const { wrapper, title, description } = styles

interface IProps {
  onClose?: () => void
}

const CancelUploadModalContent = ({ onClose }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()

  const handleCloseAndLeave = () => {
    dispatch(modalActions.closeAllModals())
  }

  return (
    <div className={wrapper}>
      <p className={title}>{images.areYouSure}</p>
      <p className={description}>{images.cancelUploadModalMessage} </p>
      <ActionButton
        label={images.buttons.cancelUploadAndLeave}
        buttonType={ActionButtonType.WARNING}
        size={ActionButtonSize.MEDIUM}
        onPress={handleCloseAndLeave}
      />
      <ActionButton
        label={images.buttons.cancel}
        buttonType={ActionButtonType.SECONDARY}
        size={ActionButtonSize.MEDIUM}
        onPress={onClose}
      />
    </div>
  )
}

export default CancelUploadModalContent
