import styles from './DetailCard.module.scss'
import { iDetailCardProps } from './DetailCard.types'

const {
  techbotDetailsCard,
  techbotDetailsCardInfo,
  techbotDetailsCardLabel,
  techbotDetailsCardDescription,
  switchContainer,
  usageContainer,
} = styles

const DetailCard = ({ usage, button, label, description, switchToggle }: iDetailCardProps) => (
  <div className={techbotDetailsCard}>
    <div className={techbotDetailsCardInfo}>
      <div className={techbotDetailsCardLabel}>{label}</div>
      <div className={techbotDetailsCardDescription}>{description}</div>
    </div>
    {button}
    {switchToggle && <div className={switchContainer}>{switchToggle}</div>}
    {usage !== undefined && <div className={usageContainer}>{usage}</div>}
  </div>
)

export default DetailCard
