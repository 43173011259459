import { IUploadedFile, IUploadedFolder } from 'types/api'
import styles from './DataTable.module.scss'
import { Breadcrumbs } from '../Files'
import SmallFolderIcon from 'assets/icons/smallFolderIcon'
import { useState } from 'react'
import { MoveTo } from '../MoveTo'
import { FolderActionsMenu } from '../FolderActionsMenu'

const { tdPreview, grayText, folderPreview, cellMore } = styles

interface IProps {
  folder: IUploadedFolder
  setBreadcrumbs: (folderName: string, path: string) => void
  breadcrumbs: Breadcrumbs
}

const FolderTr = ({ folder, setBreadcrumbs, breadcrumbs }: IProps) => {
  const dateObj = new Date(folder.created_at)
  const day = String(dateObj.getUTCDate()).padStart(2, '0')
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
  const year = dateObj.getUTCFullYear()
  const formattedDate = `${day}/${month}/${year}`

  const handleOnClick = () => {
    setBreadcrumbs(folder.name, folder.id)
  }

  const [selectedMoveToFolder, setSelectedMoveToFolder] = useState<IUploadedFolder | IUploadedFile | null>(null)
  const handleMoveTo = (folder: IUploadedFolder) => {
    setSelectedMoveToFolder(folder)
  }

  return (
    <>
      {selectedMoveToFolder && (
        <MoveTo
          selectedEntry={selectedMoveToFolder}
          setSelectedEntry={setSelectedMoveToFolder}
          currentPath={breadcrumbs}
        />
      )}
      {folder && (
        <tr key={folder.id}>
          <td className={tdPreview} onClick={handleOnClick}>
            <div className={folderPreview}>
              <SmallFolderIcon />
            </div>
          </td>
          <td>
            <p>{folder.name}</p>
            <p className={grayText}>File count: {folder.files_in_folder}</p>
          </td>
          <td>{formattedDate}</td>
          <td>{`Folder`}</td>
          <td>{folder.created_by}</td>
          <td className={cellMore}>
            <FolderActionsMenu folder={folder} handleMoveTo={handleMoveTo} />
          </td>
        </tr>
      )}
    </>
  )
}

export default FolderTr
