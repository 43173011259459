import { AnyAction } from '@reduxjs/toolkit'
import { NEW_SUBSCRIPTION_STEP_SELECT_PLAN } from 'utils/constant'
import { AppDispatch, RootState } from './store'

const initialState = {
  buyingSteps: NEW_SUBSCRIPTION_STEP_SELECT_PLAN,
  plan: null,
  acceptedAgreement: false,
}

export const SET_NEW_SUBSCRIPTION = 'SET_NEW_SUBSCRIPTION'
export const SET_NEW_SUBSCRIPTION_FLOW = 'SET_NEW_SUBSCRIPTION_FLOW'

export const SET_SUB_ACCEPTED_AGGREMENT = 'SET_SUB_ACCEPTED_AGGREMENT'

export const subscription = (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case SET_NEW_SUBSCRIPTION:
      return {
        ...state,
        plan: action.payload,
      }
    case SET_NEW_SUBSCRIPTION_FLOW:
      return {
        ...state,
        buyingSteps: action.payload,
      }
    case SET_SUB_ACCEPTED_AGGREMENT:
      return {
        ...state,
        acceptedAgreement: action.payload,
      }
    default:
      return state
  }
}

export const setNewSubscription = (plan: any) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_NEW_SUBSCRIPTION,
    payload: plan,
  })
}

export const setNewSubscriptionFlow = (step: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_NEW_SUBSCRIPTION_FLOW,
    payload: step,
  })
}

export const setReduxSubAcceptedAgreement = () => (dispatch: AppDispatch, getState: () => RootState) => {
  const currentValue: boolean = getState().subscription.acceptedAgreement

  dispatch({
    type: SET_SUB_ACCEPTED_AGGREMENT,
    payload: !currentValue,
  })
}
