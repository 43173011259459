import { MouseEventHandler } from 'react'
import styles from './FileCard.module.scss'
import IconClose from 'assets/icons/iconClose'
import { ICustomFile } from 'utils/generateURL'
import IconDelete from 'assets/icons/iconDelete'
import ProgressBar from 'components/progressBar/ProgressBar'
import IconReload from 'assets/icons/iconReload'
import { PROGRESS_STATUS } from 'types/api'
import formatFileSize from 'helpers/formatFileSize'

interface IProps {
  file: ICustomFile
  onDelete: (preview: string) => void
  onDeleteFromServer: (id: string) => void
  onReupload: (id: string) => void
}

const { cardWrapper, closeButton, cardInfo, previewFormat, info, title, size, buttonsWrapper } = styles

const FileCard = ({ file, onDelete, onDeleteFromServer, onReupload }: IProps) => {
  const handleDelete: MouseEventHandler<HTMLButtonElement> = () => {
    onDelete(file.preview)
  }

  const handleDeleteFromServer = () => {
    if (file?.id) {
      onDeleteFromServer(file.id)
    }
  }

  const handleReupload = () => {
    onReupload(file.preview)
  }

  return (
    <div className={cardWrapper}>
      <div className={cardInfo}>
        <div className={previewFormat}>
          <span>.{file.name.split('.').pop()}</span>
        </div>
        <div className={info}>
          <p className={title}>{file.name}</p>
          <p className={size}>{formatFileSize(file.size)}</p>
        </div>
      </div>

      {file.progressStatus === PROGRESS_STATUS.ERROR && (
        <div className={buttonsWrapper}>
          <button className={closeButton} onClick={handleReupload}>
            <IconReload />
          </button>
          <button className={closeButton} onClick={handleDelete}>
            <IconClose />
          </button>
        </div>
      )}

      {!file.progressStatus && (
        <button className={closeButton} onClick={handleDelete}>
          <IconClose />
        </button>
      )}

      {file.progressStatus === PROGRESS_STATUS.UPLOADED && (
        <button className={closeButton} onClick={handleDeleteFromServer}>
          <IconDelete />
        </button>
      )}

      <ProgressBar status={file.progressStatus!} />
    </div>
  )
}

export default FileCard
