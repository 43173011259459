import { useForm, Controller } from 'react-hook-form'
import ActionButton, { ActionButtonSize, ActionButtonType } from 'components/actionButton/ActionButton'
import styles from '../../TechBotDataSources.module.scss'
import CustomLoader from 'components/customLoader/CustomLoader'
import { messages } from 'constants/messages'
import CustomInput from 'components/customInput/CustomInput'
import { handleURL } from 'helpers/handleURL'
import IconAdd from 'assets/icons/iconAdd'
import ListOfSources from 'modules/techbots/components/listOfSources/ListOfSources'
import { LinkItem } from 'modules/techbots/components/listOfSources/ListOfSources'
import { useSelector } from 'react-redux'
import { botSelector } from 'redux/bot/slice'
import { useLinks } from 'helpers/useLinks'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { websitesSchema } from './schema'
import PaginationComponent from 'components/pagination/PaginationComponent'

const { urlRow } = styles
const { techBotDataSources } = messages.techbots

type FormValues = InferType<typeof websitesSchema>

const Websites = () => {
  const bot = useSelector(botSelector)
  const botId = bot?.id || ''

  const {
    onHandleAddLink,
    onHandleRemoveLink,
    isLinksLoading,
    isSavingNewLink,
    addedLinks,
    totalPages,
    page,
    handlePageChange,
    websites,
  } = useLinks(botId)

  const { control, handleSubmit, reset, watch } = useForm<FormValues>({
    resolver: yupResolver(websitesSchema),
  })

  const urlValue = watch('url')

  const onSubmit = (data: FormValues) => {
    const url = handleURL(data.url)
    onHandleAddLink(url)
    reset({ url: '' })
  }

  return (
    <>
      {isLinksLoading && <CustomLoader />}
      <form className={urlRow} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <CustomInput
              id="url"
              value={field.value}
              onChangeValue={field.onChange}
              labelVisible={true}
              label={techBotDataSources.enterUrl}
              placeholder={techBotDataSources.urlPlaceholder}
              fullWidth={true}
              onPressEnter={handleSubmit(onSubmit)}
            />
          )}
        />
        <ActionButton
          label={techBotDataSources.addLink}
          buttonType={ActionButtonType.PRIMARY}
          size={ActionButtonSize.MEDIUM}
          icon={<IconAdd />}
          disabled={isSavingNewLink || !urlValue}
          onPress={handleSubmit(onSubmit)}
        />
      </form>

      <ListOfSources
        label={techBotDataSources.urls}
        data={addedLinks || []}
        renderItem={(item) => <LinkItem item={item} onRemove={onHandleRemoveLink} />}
      />
      {(websites?.total ?? 0) >= 1 && totalPages > 1 && (
        <PaginationComponent totalPages={totalPages} page={page} handlePageChange={handlePageChange} />
      )}
    </>
  )
}

export default Websites
