import IconDelete from 'assets/icons/iconDelete'
import IconEdit from 'assets/icons/iconEdit'
import ContextMenu from 'components/contextMenu/ContextMenu'
import { messages } from 'constants/messages'
import styles from './GeneratedKnowledgeTable.module.scss'
import { ChangeEvent, useState } from 'react'
import { KNOWLEDGE_TAG, suggestedByMapper } from './helpers'
import { getTimeSinceLastMessage } from 'utils/tools'
import DialogModal from 'components/dialogModal/DialogModal'
import { KnowledgeBody, ParsedKnowledge } from 'types/api'
import EditContentModal from '../editContentModal/EditContentModal'
import { api } from 'redux/rtkQuery'
import { toastFuncError, toastFuncNotification } from 'components/customToastContainer/CustomToastContainer'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'redux/hooks'
import { ReduxState } from 'redux/store'
import { Switch } from '@mui/material'
import { SXObject } from 'modules/techbots/components/techBotDetails/constants'

const { knowledgeList, knowledgeListItem, knowledgeListItemTitle, knowledgeListItemDescription } = styles

const { knowledgeMessages } = messages

type KnowledgeItemProps = {
  knowledge: ParsedKnowledge
  deleteItem: (id: string) => void
  editItem: (id: string, content: KnowledgeBody) => void
}

const KnowledgeItem = ({ knowledge, deleteItem, editItem }: KnowledgeItemProps) => {
  const dispatch = useDispatch()

  const [isEditingContentModalVisible, setIsEditingContentModalVisible] = useState(false)

  const [isDeletingContentModalVisible, setIsDeletingContentModalVisible] = useState(false)

  const [isChecked, setIsChecked] = useState(knowledge.disabled)

  const user = useAppSelector((state: ReduxState) => state.user)

  const { data: bot } = api.useGetCompanyBotQuery({
    companyId: user?.company_id,
  })

  const botId = bot?.id || ''

  const [patchKnowledge] = api.usePatchKnowledgeMutation()

  const onHandleChangeStatus = async (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked

    setIsChecked(value)

    try {
      await patchKnowledge({
        botId: botId,
        knowledgeId: knowledge.id,
        body: { disabled: value },
      }).unwrap()
      dispatch(api.util.invalidateTags([KNOWLEDGE_TAG]))
      toastFuncNotification(knowledgeMessages.editContentSuccessToast)
    } catch {
      setIsChecked(!value)
      toastFuncError(knowledgeMessages.errorToast)
    }
  }

  return (
    <>
      <div className={knowledgeList}>
        <div className={knowledgeListItem}>
          <div className={knowledgeListItemTitle}>
            {knowledgeMessages.item.title} {knowledge.title}
          </div>

          <p className={knowledgeListItemDescription}>
            {knowledgeMessages.item.content} {knowledge.description}
          </p>
        </div>
        <div className={knowledgeListItem}>{suggestedByMapper(knowledge.generated_by)}</div>
        <div className={knowledgeListItem}>
          <Switch checked={isChecked} sx={SXObject} onChange={onHandleChangeStatus} />
        </div>
        <div className={knowledgeListItem}>{getTimeSinceLastMessage(knowledge.updated_at)}</div>

        <ContextMenu
          options={[
            {
              icon: <IconEdit fillColor="#686868" />,
              description: knowledgeMessages.context.edit,
              onPress: () => setIsEditingContentModalVisible(true),
            },
            {
              icon: <IconDelete fillColor="#E40F0F" />,
              description: knowledgeMessages.context.delete,
              descriptionStyle: { color: '#E40F0F' },
              onPress: () => setIsDeletingContentModalVisible(true),
            },
          ]}
        />
        <DialogModal
          isVisible={isDeletingContentModalVisible}
          title={knowledgeMessages.deleteModal.title}
          message={knowledgeMessages.deleteModal.message}
          buttons={[
            {
              text: knowledgeMessages.deleteModal.delete,
              style: 'warning',
              onPress: () => deleteItem(knowledge.id),
            },
            {
              text: knowledgeMessages.deleteModal.cancel,
              style: 'cancel',
            },
          ]}
          onClose={() => setIsDeletingContentModalVisible(false)}
        />
      </div>
      <EditContentModal
        knowledge={knowledge}
        isVisible={isEditingContentModalVisible}
        title={knowledgeMessages.addContent}
        message={knowledgeMessages.addContentModal.message}
        onContinue={editItem}
        onClose={() => setIsEditingContentModalVisible(false)}
      />
    </>
  )
}

export default KnowledgeItem
