import { Divider, MenuItem } from '@mui/material'
import IconDelete from 'assets/icons/iconDelete'
import IconEdit from 'assets/icons/iconEdit'
import { useDispatch } from 'react-redux'
import { MODAL_TYPES, modalActions } from 'redux/modal/slice'

import style from './DropdownFolderContent.module.scss'
import { messages } from 'constants/messages'

const { dropdownContent } = style

interface IProps {
  category: string
}

const DropdownFolderContent = ({ category }: IProps) => {
  const { images } = messages
  const dispatch = useDispatch()

  const handleOpenRenameFolderModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.RENAME_FOLDER,
        category,
      })
    )
  }

  const handleOpenDeleteFolderModal = () => {
    dispatch(
      modalActions.setShowModal({
        modalType: MODAL_TYPES.DELETE_FOLDER,
        category,
      })
    )
  }

  return (
    <div className={dropdownContent}>
      <MenuItem onClick={handleOpenRenameFolderModal}>
        <IconEdit fillColor="#777777" /> {images.rename}
      </MenuItem>
      <Divider style={{ margin: 0 }} />
      <MenuItem onClick={handleOpenDeleteFolderModal}>
        <IconDelete fillColor="red" /> {images.deleteFolder}
      </MenuItem>
    </div>
  )
}

export default DropdownFolderContent
