import styles from '../BotPreview.module.scss'
import { hexToRgba } from 'utils/tools'
import { messages } from 'constants/messages'
import CustomInput from 'components/customInput/CustomInput'

interface iContentForBrandInfo {
  primaryColor: string
  secondaryColor: string
  companyName?: string
  welcomeMessage?: string
  offlineMessage?: string
  isChatOffline?: boolean
}

const {
  content,
  message,
  outgoing,
  authorContainer,
  authorStyles,
  messageTime,
  messageContent,
  mockButton,
  inputWrapper,
} = styles

const ContentForBrandInfo = ({
  primaryColor,
  secondaryColor,
  companyName,
  welcomeMessage,
  offlineMessage,
  isChatOffline = false,
}: iContentForBrandInfo): JSX.Element => {
  const { contentForBrandInfo } = messages.techbots

  return (
    <>
      <div className={content}>
        <div className={message}>
          <div className={authorContainer} style={{ backgroundColor: hexToRgba(primaryColor, '0.05') }}>
            <div className={authorStyles} style={{ color: primaryColor }}>
              {companyName}
            </div>
          </div>
          {isChatOffline ? (
            <div className={messageContent}>
              {offlineMessage === '' ? contentForBrandInfo.offlineMessagePlaceholder : offlineMessage}
            </div>
          ) : (
            <div className={messageContent}>
              {welcomeMessage === '' ? contentForBrandInfo.welcomeMessagePlaceholder : welcomeMessage}
            </div>
          )}
        </div>

        {!isChatOffline && (
          <div className={`${message} ${outgoing}`} style={{ backgroundColor: primaryColor, color: secondaryColor }}>
            {contentForBrandInfo.questionMessage}
          </div>
        )}

        {!isChatOffline && <p className={messageTime}>{contentForBrandInfo.time}</p>}

        {isChatOffline && (
          <div className={message}>
            <div className={inputWrapper}>
              <CustomInput value={contentForBrandInfo.email} disabled labelVisible label="Email" placeholder="Email" />
            </div>
          </div>
        )}

        {isChatOffline && <div className={mockButton}>{contentForBrandInfo.sendRequest}</div>}
      </div>
    </>
  )
}

export default ContentForBrandInfo
