import { UnknownAction } from '@reduxjs/toolkit'
import { AppDispatch } from './store'

const initialState = {
  isSubscriptionTour: false,
  config: null,
}

export const SET_SUBSCRIPTION_TOUR = 'SET_SUBSCRIPTION_TOUR'

export const app = (state = initialState, action: UnknownAction) => {
  switch (action.type) {
    case SET_SUBSCRIPTION_TOUR:
      return {
        ...state,
        isSubscriptionTour: action.payload,
      }
    default:
      return state
  }
}

export const setSubscriptionTour = (payload: boolean) => (dispatch: AppDispatch) => {
  dispatch({
    type: SET_SUBSCRIPTION_TOUR,
    payload,
  })
}
