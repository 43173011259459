import { FC } from 'react'

interface iIconEdit {
  fillColor?: string
}

const IconEdit: FC<iIconEdit> = ({ fillColor = 'white' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Edit">
        <mask
          id="mask0_4906_11180"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_4906_11180)">
          <path
            id="edit"
            d="M5.15425 18.999H6.39077L15.6504 9.73938L14.4139 8.50283L5.15425 17.7625V18.999ZM18.858 8.65478L15.4831 5.31061L16.7966 3.99716C17.0838 3.70999 17.4376 3.56641 17.8581 3.56641C18.2786 3.56641 18.6324 3.70999 18.9196 3.99716L20.1561 5.23368C20.4433 5.52085 20.592 5.86956 20.6023 6.27981C20.6125 6.69006 20.4741 7.03876 20.1869 7.32593L18.858 8.65478ZM4.55812 20.499C4.30044 20.499 4.08538 20.4128 3.91295 20.2403C3.74051 20.0679 3.6543 19.8528 3.6543 19.5952V17.501C3.6543 17.3805 3.67609 17.2657 3.71967 17.1568C3.76325 17.0478 3.83185 16.9465 3.92545 16.8529L14.3985 6.37981L17.7735 9.75476L7.30037 20.2278C7.20679 20.3214 7.10551 20.39 6.99655 20.4336C6.88756 20.4772 6.77281 20.499 6.6523 20.499H4.55812Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconEdit
