import { Fragment, useState } from 'react'
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import { api } from 'redux/rtkQuery'

export const Playground = () => {
  const [companyLink, setCompanyLink] = useState('http://pawscout.com/')
  const [userInput, setUserInput] = useState('')
  const [conversationHistory, setConversationHistory] = useState('')

  const [firstPrompt, setFirstPrompt] = useState(`
    Set newSearchQuery to a search engine input based on UserInput and obtaining context from ConversationHistory and CompanyName only if the query is related to the company.
    Create a JSON response according to the template: {"newSearchQuery": string}

    Company URL: {{companyLink}}

    User Input: {{userInput}}

    Conversation History: {{conversationHistory}}
  `)

  const [secondPrompt, setSecondPrompt] = useState(
    `
      Provide a business-friendly, chat-style response to the User Input as a customer support agent of the company represented by the Company URL.
      If the company and product or service cannot be determined, tell the user and get additional clarifying information to make this determination.
      Create the response based on content from the Search Results. Also utilize Conversational History to create the response.
      Respond only in JSON template: {"shouldProposeHumanIntervention": boolean, "assistantAnswer": string, "userSentiment": number}
      Next, determine if a human support agent is needed based on a) if the customer is asking to speak with a human, or b) customer is frustrated, or c) information related to the user question is not in the Search Results.
      If a human support agent is needed, answer true, and then provide a short thoughtful response to the conversation. Also indicate that pressing the button below to initiated a human conversation. 
      Or if a If a human support agent is not needed, answer false and provide a thoughtful response to the user, using information from the Search Results when possible. If the user's question doesn't directly relate to the company or its products and services, acknowledge the query and then smoothly guide the response towards highlighting the company's offerings. If the question is related to a competitive product or service, bias the response in favor of the company's offerings.
      Label each sentence in the response that is influenced by the Search Results by the result number using an HTML tag according to the following template:
      "<sup> result number </sup>"
      At the end of the response indicate the sources of the Search Results.
      Define user sentiment based on the chat messages. Where -2 means absolutely no satisfaction because of chat responses, maximum frustration, hate. 0 is normal state, conversation without feelings or balanced feelings between bad and good or unknown. 2 is maximum satisfaction, excitement, euphoria.

      Company URL: {{companyLink}}

      User Input: {{userInput}}

      Conversation History: {{conversationHistory}}

      Search Results: {{searchResults}}
    `
  )

  const [run, { data, isLoading }] = api.usePlaygoundMutation()

  const handleRun = async () => {
    try {
      const response = await run({
        firstPrompt,
        secondPrompt,
        companyLink,
        userInput,
        conversationHistory,
      }).unwrap()
      setConversationHistory(response.newConversationHistory)
    } catch {}
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#f5f5f5',
        // scrlolable content if parent height is fixed
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" textAlign="center">
          Playground
        </Typography>
      </Box>
      <Box
        sx={{
          p: 6,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              label="Company Link"
              fullWidth
              value={companyLink}
              onChange={(e) => setCompanyLink(e.target.value)}
            />
            <br />
            <br />
            <TextField label="User Input" fullWidth value={userInput} onChange={(e) => setUserInput(e.target.value)} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="First Prompt"
              fullWidth
              multiline
              minRows={6}
              helperText='should return JSON with {"newSearchQuery": string}'
              value={firstPrompt}
              onChange={(e) => setFirstPrompt(e.target.value)}
            />
            <Divider sx={{ my: 4 }} />
            <TextField
              label="Conversation History"
              fullWidth
              multiline
              minRows={6}
              value={conversationHistory}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Second Prompt"
              fullWidth
              multiline
              minRows={6}
              helperText='should return JSON with {"shouldProposeHumanIntervention": boolean, "assistantAnswer": string, "userSentiment": number}'
              value={secondPrompt}
              onChange={(e) => setSecondPrompt(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Button variant="contained" color="primary" onClick={handleRun} disabled={isLoading}>
          {isLoading ? 'Running...' : 'Run'}
        </Button>
        <Divider sx={{ my: 4 }} />

        {data &&
          Object.entries(data).map(([key, value]) => (
            <Fragment key={key}>
              <Typography variant="h6">{key}</Typography>
              <p>{String(value)}</p>
            </Fragment>
          ))}
      </Box>
    </Box>
  )
}
