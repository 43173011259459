const IconFolderUploader = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" fill="none">
    <mask id="mask0_6091_54726" maskUnits="userSpaceOnUse" x="0" y="0" width="66" height="66">
      <rect width="66" height="66" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_6091_54726)">
      <path
        d="M11.8463 53.625C10.4571 53.625 9.28125 53.1437 8.31875 52.1813C7.35625 51.2188 6.875 50.0429 6.875 48.6537V17.3463C6.875 15.9571 7.35625 14.7812 8.31875 13.8188C9.28125 12.8563 10.4571 12.375 11.8463 12.375H24.8875C25.5503 12.375 26.1876 12.5038 26.7994 12.7614C27.4109 13.0185 27.9423 13.3728 28.3938 13.8243L32.4445 17.875H54.1537C55.5429 17.875 56.7188 18.3563 57.6813 19.3188C58.6437 20.2813 59.125 21.4571 59.125 22.8463V48.6537C59.125 50.0429 58.6437 51.2188 57.6813 52.1813C56.7188 53.1437 55.5429 53.625 54.1537 53.625H11.8463ZM11.8463 49.5H54.1537C54.4007 49.5 54.6035 49.4207 54.7621 49.2621C54.9207 49.1035 55 48.9007 55 48.6537V22.8463C55 22.5993 54.9207 22.3965 54.7621 22.2379C54.6035 22.0793 54.4007 22 54.1537 22H30.7574L25.4959 16.7379C25.4075 16.6499 25.3149 16.5882 25.2182 16.5529C25.121 16.5176 25.0195 16.5 24.9136 16.5H11.8463C11.5993 16.5 11.3965 16.5793 11.2379 16.7379C11.0793 16.8965 11 17.0993 11 17.3463V48.6537C11 48.9007 11.0793 49.1035 11.2379 49.2621C11.3965 49.4207 11.5993 49.5 11.8463 49.5Z"
        fill="#5E36FF"
      />
    </g>
  </svg>
)

export default IconFolderUploader
