import { IUploadedFolder } from 'types/api'
import DropdownFolderContent from './dropdownFolderContent/DropdownFolderContent'
import DropdownMenu from 'components/dropdownMenu/DropdownMenu'

export const FolderActionsMenu = ({
  folder,
  handleMoveTo,
}: {
  folder: IUploadedFolder
  handleMoveTo: (folder: IUploadedFolder) => void
}) => {
  return (
    <DropdownMenu>
      <DropdownFolderContent folder={folder} handleMoveTo={handleMoveTo} />
    </DropdownMenu>
  )
}
