import { FC } from 'react'

interface iIconAttachment {
  fillColor?: string
}

const IconAttachment: FC<iIconAttachment> = ({ fillColor = '#686868' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon/Attachment">
        <mask
          id="mask0_1613_14521"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1613_14521)">
          <path
            id="attach_file"
            d="M11.4997 21.8843C10.0369 21.8843 8.79297 21.3718 7.76797 20.3468C6.74297 19.3218 6.23047 18.0779 6.23047 16.6151V5.80737C6.23047 4.75497 6.60519 3.85405 7.35464 3.10462C8.10408 2.35519 9.00499 1.98047 10.0574 1.98047C11.1098 1.98047 12.0107 2.35519 12.7601 3.10462C13.5096 3.85405 13.8843 4.75497 13.8843 5.80737V15.5574C13.8843 16.2251 13.6538 16.7894 13.1927 17.2505C12.7316 17.7115 12.1672 17.942 11.4994 17.942C10.8317 17.942 10.2673 17.7115 9.80642 17.2505C9.34554 16.7894 9.11509 16.2251 9.11509 15.5574V6.40352C9.11509 6.2313 9.17135 6.08888 9.28387 5.97627C9.39639 5.86367 9.53869 5.80737 9.71079 5.80737C9.88288 5.80737 10.0253 5.86367 10.1381 5.97627C10.251 6.08888 10.3074 6.2313 10.3074 6.40352V15.5574C10.3074 15.8952 10.4216 16.1784 10.6502 16.4069C10.8787 16.6355 11.1619 16.7497 11.4997 16.7497C11.8375 16.7497 12.1207 16.6355 12.3492 16.4069C12.5778 16.1784 12.692 15.8952 12.692 15.5574V5.80737C12.692 5.06967 12.4373 4.44614 11.9277 3.93679C11.4182 3.42743 10.7945 3.17274 10.0566 3.17274C9.31865 3.17274 8.6952 3.42743 8.18622 3.93679C7.67725 4.44614 7.42277 5.06967 7.42277 5.80737V16.6151C7.42277 17.7407 7.82084 18.7016 8.61699 19.4978C9.41314 20.2939 10.374 20.692 11.4997 20.692C12.6253 20.692 13.5862 20.2939 14.3824 19.4978C15.1785 18.7016 15.5766 17.7407 15.5766 16.6151V6.40352C15.5766 6.2313 15.6329 6.08888 15.7454 5.97627C15.8579 5.86367 16.0002 5.80737 16.1723 5.80737C16.3444 5.80737 16.4869 5.86367 16.5997 5.97627C16.7125 6.08888 16.7689 6.2313 16.7689 6.40352V16.6151C16.7689 18.0779 16.2564 19.3218 15.2314 20.3468C14.2064 21.3718 12.9625 21.8843 11.4997 21.8843Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconAttachment
