import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import IconMore from 'assets/icons/iconMore'
import { useState, MouseEvent, ReactNode } from 'react'
import { styled } from '@mui/system'

interface IProps {
  children: ReactNode
}

const DropdownMenu = ({ children }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement
    if (target.closest('#subMenu')) {
      return
    }
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconMore />
      </IconButton>
      <CustomMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {children}
      </CustomMenu>
    </>
  )
}

export default DropdownMenu

const CustomMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '220px',
    borderRadius: '16px',
  },
  '& ul': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))
