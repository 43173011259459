import { MouseEvent } from 'react'
import folderShortcut from 'assets/images/folderShortcut.png'
import styles from './FolderShortcut.module.scss'
import { useSelector } from 'react-redux'
import { imagesSelector } from 'redux/images/slice'
import { IGetImagesResponse } from 'types/api'
import DropdownMenu from 'components/dropdownMenu/DropdownMenu'
import DropdownFolderContent from '../dropdownFolderContent/DropdownFolderContent'

const { wrapper, cardInfo, title, size } = styles

interface IProps {
  category: string
  handleSetBreadcrumbs: (folderName: string, path: string) => void
}

const FolderShortcut = ({ category, handleSetBreadcrumbs }: IProps) => {
  const imagesStored = useSelector(imagesSelector)

  const imagesOfCategory = imagesStored.images.filter((image: IGetImagesResponse) => image.category === category) || []

  const handleFolderClick = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement

    if (target.closest('button')) return

    if (target.closest('[class*="FolderShortcut"]')) {
      handleSetBreadcrumbs(category, category)
    }
  }

  return (
    <div className={wrapper} onClick={handleFolderClick}>
      <div>
        <img src={folderShortcut} alt="logo" />
      </div>
      <div className={cardInfo}>
        <div>
          <p className={title}>{category}</p>
          <p className={size}>
            {imagesOfCategory.length} {imagesOfCategory.length === 1 ? 'image' : 'images'}
          </p>
        </div>
        <DropdownMenu>
          <DropdownFolderContent category={category} />
        </DropdownMenu>
      </div>
    </div>
  )
}

export default FolderShortcut
