import { FC, SVGProps } from 'react'

interface iIconCheckedSuccess extends SVGProps<SVGSVGElement> {
  fillColor?: string
}

const IconCheckedSuccess: FC<iIconCheckedSuccess> = ({ fillColor = '#5E36FF', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Icon/Check">
        <mask
          id="mask0_199_1050"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="16"
          height="16"
        >
          <rect id="Bounding box" width="16" height="16" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_199_1050)">
          <path
            id="check"
            d="M6.3667 11.7683L2.8103 8.21189L3.52312 7.49909L6.3667 10.3427L12.477 4.23242L13.1898 4.94522L6.3667 11.7683Z"
            fill={fillColor}
          />
        </g>
      </g>
    </svg>
  )
}

export default IconCheckedSuccess
