import { IGetImagesResponse } from 'types/api'
import Tooltip from 'components/tooltip/Tooltip'
import styles from './ImagesTable.module.scss'
import TableTr from './TableTr'
import { messages } from 'constants/messages'
import { Box } from '@mui/system'
import { themeColors } from 'styles/colors'

const { table, thWithIcon } = styles

interface IProps {
  images: IGetImagesResponse[]
}

const ImagesTable = ({ images }: IProps) => {
  const { images: imagesText, knowledgeMessages } = messages

  return (
    <>
      {images.length > 0 ? (
        <Box
          sx={{
            border: '1px solid',
            borderColor: themeColors.lightGray,
            borderRadius: '8px 8px 0 0',
          }}
        >
          <table className={table}>
            <thead>
              <tr>
                <th>{imagesText.preview}</th>
                <th>{imagesText.name}</th>
                <th className={thWithIcon}>
                  {imagesText.status} <Tooltip content={knowledgeMessages.tooltipContent} />
                </th>
                <th>{imagesText.added}</th>
                <th>{imagesText.type}</th>
                <th>{imagesText.folder}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {images.map((image) => (
                <TableTr key={image.id + image.status} image={image} />
              ))}
            </tbody>
          </table>
        </Box>
      ) : null}
    </>
  )
}

export default ImagesTable
