import { toastFuncError } from 'components/customToastContainer/CustomToastContainer';
import { GENERAL_ERROR_MESSAGE } from 'utils/constant';

export const handelShowError = (error: unknown, defaultError?: string) => {
  // error is string
  if (typeof error === 'string') {
    toastFuncError(error);
    return;
  }

  // error is Error or ErrorLike
  if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    toastFuncError(error.message);
    return;
  }

  // error is form RTK Query
  if (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'message' in error.data &&
    typeof error.data.message === 'string'
  ) {
    toastFuncError(error.data.message);
    return;
  }

  toastFuncError(defaultError || GENERAL_ERROR_MESSAGE);
};
